export default {
    /*
     * Columnas
     */
    behavior_driver: 'Conductor',
    behavior_vehicle: 'Vehiculo',
    behavior_riskFactors: 'Factores de Riesgo',
    behavior_riskFactorsPoints: 'Factores de Riesgo (puntos)',
    behavior_category: 'Categoría',
    behavior_metric: 'Métrica',
    behavior_warningPoints: 'Advertencia (puntos)',
    behavior_violationPoints: 'Violación (puntos)',
    behavior_totalSafetyEvents: 'Eventos de seguridad total',
    behavior_total: 'Total',
    /*
     * Tipos de Métricas
     */

    behavior_aggression: 'Agresión',
    behavior_HardBrake: 'Frenada Fuerte',
    behavior_HardTurn: 'Giro Fuerte',
    behavior_RapidAcceleration: 'Aceleración Rápida',
    behavior_PotentialIncident: 'Incidente Potencial',
    behavior_driverMonitoringSystem: 'Sistema de Monitoreo del Conductor (DMS)',
    behavior_dms: '(DMS)',
    behavior_UsingPhone: 'Uso del Teléfono',
    behavior_Smoking: 'Fumando',
    behavior_Drowsiness: 'Somnolencia',
    behavior_NoSeatbelt: 'Sin Cinturón de Seguridad',
    behavior_DayDreaming: 'Soñando Despierto',
    behavior_Yawn: 'Bostezo',
    behavior_Asleep: 'Dormido',
    behavior_Distracted: 'Distracted',
    behavior_CameraObstructed: 'Cámara Obstruida',
    behavior_IdleNoDriver: 'Inactivo Sin Conductor',
    behavior_PassengersDetection: 'Detección de Pasajeros',
    behavior_advancedDriverAssistanceSystem: 'Sistema Avanzado de Asistencia al Conductor (ADAS)',
    behavior_adas: '(ADAS)',
    behavior_ForwardCollisionWarning: 'Advertencia de Colisión Frontal',
    behavior_CloseFollowing: 'Seguimiento Cercano',
    behavior_Tailgating: 'Conducción Pegada',
    behavior_LaneDeparture: 'Salida de Carril',
    behavior_StopSignViolation: 'Violación de Señal de Alto',
    behavior_vehicleSpeed: 'Velocidad del Vehículo',
    behavior_speedLabel: 'Velocidad > ',
    behavior_speedingOverPosted: 'Exceso de Velocidad Sobre el Límite',
    behavior_speedOverLow: '1-5 (Bajo)',
    behavior_speedOverMedium: '6-10 (Medio)',
    behavior_speedOverHigh: '11+ (Alto)',

    behavior_today: 'Hoy',
    behavior_yesterday: 'Ayer',
    behavior_week: 'Esta semana',
    behavior_lastWeek: 'La semana pasada',
    behavior_thisMonth: 'Este Mes',
    behavior_lastMonth: 'El mes pasado',
    behavior_quarter: 'Este trimestre',
    behavior_lastQuarter: 'Ultimo trimestre',
    behavior_custom: 'Personalizado',
    behavior_last7days: 'Últimos 7 Dias',
    behavior_last15days: 'Últimos 15 Dias',
    behavior_last30Days: 'Últimos 30 Dias',

    behavior_dateToday: 'Hoy',
    behavior_dateYesterday: 'Ayer',
    behavior_dateThisWeek: 'Esta semana',
    behavior_dateLastToday: 'La semana pasada',
    behavior_dateThisMonth: 'Este Mes',
    behavior_dateLastMonth: 'El mes pasado',
    behavior_dateThisQuarter: 'Este trimestre',
    behavior_dateLastQuarter: 'Ultimo trimestre',
    behavior_dateCustom: 'Personalizado',
    behavior_dateLast7days: 'Últimos 7 Dias',
    behavior_dateLast15days: 'Últimos 15 Dias',
    behavior_dateLast30Days: 'Últimos 30 Dias',

    behavior_filterBy: 'Filtrar por:',
    behavior_timeFrame: 'Período de tiempo',
    behavior_singleUnits: 'Unidades',
    behavior_drivers: 'Conductores',
}
