// dependencies
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
// controls
import { Tooltip } from 'Components';
import { createPortal } from 'react-dom';

// helpers
import {
  formatDateFromFilter, formatDatetime, getGroupId, getNameUnitDriverForSubtables, getNameUnitDriverTag, handleSignalIcon, postedSpeedColor, useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';

// hooks
import { useReportsSelector, useSidePanelMap } from 'Core/hooks';

// actions
import { reportsMainGenerateReportUpdate } from 'Redux/actions'

// styles
import './speedCoachingReport.scss'
import { GetFormatForMoment } from "Core/data/Helpers";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { getAbbvTimezone } from "Components/uiControls/Timezone/tools";
import InfiniteScrollTable from "Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable";
import { isKPHAcronymSpeed } from '../../../helper';
import { TagsGroup } from "Components/Tag";

const ROW_PER_PAGE = 25;

const SpeedCoachingReport = (props) => {

  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const { durationFormat } = user;
  const dispatch = useDispatch()
  const { loading, asyncLoading, data, filters, timeZone } = report
  const { filterEntity, filterArray, filterType } = filters;
  const exportHandler = useHandleExportReport(report)
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [lastEntity, setLastEntity] = useState({});
  const [subHeaderData, setSubHeaderData] = useState({})
  const momentFormat = GetFormatForMoment(user.dateformat)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)
  const refHeader = useRef(null);


  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (isActive) {
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const buildTableHeader = () => {
    return (<Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>
      <Grid item sm={filterEntity == "Unit" ?  2 : 3} className={'reports-cut-text'}>
        {getNameUnitDriverForSubtables(filterEntity, messages)}
      </Grid>
      {
        filterEntity == "Unit" &&
        <Grid item sm={2} className={'reports-cut-text'}>
          {messages['generalReport_Driver_ID']}
        </Grid>
      }
      <Grid item sm={2} className={'reports-cut-text'}>
        {messages['generatedReport_time']}
      </Grid>
      <Grid item sm={3} className={'reports-cut-text'}>
        {messages['generatedReport_address']}
      </Grid>
      <Grid item sm={2} className={'reports-cut-text'}>
        {messages["generatedReport_speed"]}
      </Grid>
      <Grid item sm={filterEntity == "Unit" ?  1 : 2} className={'reports-cut-text'}>
        {messages['generatedReport_signal']}
      </Grid>
    </Grid>)
  }

  const TableViewGroup = ({ index, style, content, id, previousItem }) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;

    return (<div
      className="listItem groupItem"
      id={id}
      key={id}
      style={style}
    >

      <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group' style={{ marginTop: "20px" }}>
        <Grid container item className='table-scroll-reports-header'>
          <Grid item sm={3}>

            {element?.tagId > 0 ? (<LocalOfferIcon
              style={{
                fontSize: "16.8px", color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`, position: 'absolute'
              }} />) : ""}
            <span
              className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
            {report?.filters?.filterArray.length !== 1 && (<span
              className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)}
          </Grid>
          <Grid item sm={9}>
            <span
              className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
          </Grid>
        </Grid>
        <div className='table-scroll-reports-separator'></div>
      </Grid>
      <div ref={refHeader}></div>
    </div>)
  }

  const TableViewSummary = ({ index, style, content, id, previousItem }) => {
    const element = content?.recordDetail || {};
    const {recordInfo} = content;
    return (<div
      className="listItem detailItem speedCoachingReport-summary"
      style={style}
      id={id}
      key={id}
    >
      <Grid container direction={"column"}>
        {element?.noData ? <></> : <>
          <div className='header-summary' >
            <p className='label-summary'>
              {filterEntity === "Unit" ? element?.deviceLabel ? element.deviceLabel : messages['reportsMainRT_unassigned'] : element?.driverName ? element?.driverName : messages['reportsMainRT_unassigned']}
            </p>
            {
              filterEntity !== "Unit" && element.driverExternalId &&
              <p className='driver-summary'> <span>{messages['generalReport_Driver_ID']} : </span> {element?.driverExternalId} </p>
            }
            { !(recordInfo?.tagId > 0) && element?.tags && element.tags.length > 0 && (
            <div className='tags-summary'>
              <span>
                {filterEntity === "Unit" ? messages['generatedReport_unit_group'] : messages['generatedReport_driver_group']} :
              </span>
                <div>
                  <TagsGroup
                    options={ element?.tags }
                    nameField="unitgroupname"
                    colorField="unitgroupcolor"
                  />
                </div>
            </div>
            )}
          </div>
        </>}
      </Grid>
      {buildTableHeader()}
    </div>)
  }

  const TableViewDetail = ({ index, style, content, id, previousItem }) => {
    const element = content?.recordDetail || {};
    let speedLimit = Number(element.speedLimit) ? Math.round(+element.speedLimit) : '0'
    let speed = Number(element.speed) ? Math.round(+element.speed) : '0'

    return (<div
      className="listItem detailItem "
      style={style}
      id={id}
      key={id}
    >
      <Grid container direction={"column"} className='detailItem-speedCoachingReport'>
        <Grid container item direction={"row"} style={{ paddingTop: "1px", paddingBottom: "1px" }}>
          {element.noData ? <Grid item className={"report-ctn-text"} sm={12}>
            {messages["reportsMainRT_noDataEvent"]}
          </Grid> : <>

            <Grid item className={"report-ctn-text"} sm={filterEntity == "Unit" ?  2 : 3}>
              {filterEntity !== "Unit" ? element?.deviceLabel ? element.deviceLabel : messages['reportsMainRT_unassigned'] : element?.driverName ? element?.driverName : messages['reportsMainRT_unassigned']}
            </Grid>
            
            {
              filterEntity == "Unit" &&
              <Grid item className={ "report-ctn-text" } sm={ 2 }>
                { element?.driverExternalId }
              </Grid>
            }
            
            <Grid item className={"report-ctn-text"} sm={2}>
              {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
            </Grid>

            <Grid item className={"report-ctn-text"} sm={3} style={{ textAlign: "center" }}>
              <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{ ...element, event: 'Drive' }} iconStyle={{ fontSize: 18 }}
                classNameButtonAddress={'report-address-button'} />
            </Grid>
            <Grid item className={"report-ctn-text"} sm={2}>
              <div style={{ display: 'inline-block' }}>
                <b>
                  <span
                    className={`detailed-report-speed detailed-report-current-speed-${postedSpeedColor(speed, speedLimit)}`}>
                    {speed} {isKPHAcronymSpeed(element.isKPH)}
                  </span>
                  <div>
                    {speedLimit === '0' ? <span></span> : <span className="detailed-report-speed-limit-delta">{speedLimit}</span>}
                  </div>
                </b>
              </div>
            </Grid>

            <Grid item className={"report-ctn-text"} sm={filterEntity == "Unit" ?  1 : 2}>
              <Tooltip
                title={element.signal || ''}
                placement="top"
              >
                {handleSignalIcon(element.signal || '')}
              </Tooltip>
            </Grid>
          </>}
        </Grid>
      </Grid>
    </div>)
  }

  const renderItems = (parameters) => {
    const { content } = parameters;
    const type = content.recordInfo?.recordType;
    if (type === "GROUP") {
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if (!entitiesArray[groupId]) {
        const newEntityArray = { ...entitiesArray };
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if (parameters.index === 0) {
        return;
      }

      return (<TableViewGroup {...parameters}></TableViewGroup>)
    } else if (type === "UNIT_SUMMARY") {
      // skip the first summary (subHeader) beacuse the first group is replaced with the sticky header
      if (parameters.index === 1) {
        return;
      }
      return <TableViewSummary {...parameters}></TableViewSummary>;
    } else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }
    ;

    if (parameters.index === 0) {
      return;
    }

    return (<div>
      unknown type
    </div>)
  }

  const onStartIndex = (index) => {
    const item = data?.items[index];
    if (item) {
      if (item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId) {
        setLastEntity(item)
        setSubHeaderData(data.items?.filter((detail) => detail.recordInfo.recordType === "UNIT_SUMMARY" && item.recordInfo.entityId === detail.recordInfo.entityId)[0])
      }
      lastEntityRef.current = item
    }

  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];


    if (itemHeader) {
      return (<TableViewGroup
        style={{
          backgroundColor: '#fff', position: 'sticky', top: 0, zIndex: 2

        }}
        content={itemHeader}
        id={itemHeader?.recordInfo?.entityId}
      ></TableViewGroup>);
    }
    return;

  };

  const renderSubHeader = () => {


    return (<TableViewSummary
      style={{
        backgroundColor: '#fff', position: 'sticky', top: 0, zIndex: 2
      }}
      content={subHeaderData}
      id={subHeaderData?.recordInfo?.entityId}
    ></TableViewSummary>)
  }

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] : (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
  return (<>
    <InfiniteScrollTable
      refContainer={detailReportTableRef}
      renderItems={renderItems}
      loading={asyncLoading || loading}
      data={data.items || []}
      total={data.total}
      reportTitle={messages['reportsMainRT_speedCoachingReport']}
      editReport={() => {
        copyReportToSetup({ ...report, data: [] })
      }}
      onSchedule={() => {
        scheduleReport(report)
      }}
      onCreate={openReportSetup}
      tableContainerClass={"detailed-report-reports-container"}
      type={type}
      handleNewPageLoad={(page) => {
        let currentReport = { ...report };
        currentReport.exportType = "json";
        currentReport.filters = {
          ...currentReport.filters,
          unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected : null,
          accumulateData: true,
          dateSelected: dateSelected?.id != -99 ? unitDriverSelected : null,
        };
        dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
      }}
      renderHeader={renderHeader}
      renderSubHeader={renderSubHeader}
      onStartIndex={onStartIndex}
      isActive={isActive}
      {...reportTableSelect}
      {...exportHandler}
    />
    <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"} />
  </>);
};

export default SpeedCoachingReport;
