// Action Types
import {
  SUB_USER_SEARCH,
  SUB_USER_SEARCH_RESPONSE,
  SUB_USER_GET,
  SUB_USER_GET_RESPONSE,
  SUB_USER_CURRENT_GET,
  SUB_USER_CURRENT_GET_RESPONSE,
  SUB_USER_CREATE,
  SUB_USER_CREATE_RESPONSE,
  SUB_USER_UPDATE,
  SUB_USER_UPDATE_RESPONSE,
  SUB_USER_DELETE,
  SUB_USER_DELETE_RESPONSE,
  SUB_USER_RESET_COGNITO,
  SUB_USER_RESET_COGNITO_RESPONSE,
  SUB_USER_DELETE_BULK,
  SUB_USER_RESET_PASSWORD,
  SUB_USER_RESET_PASSWORD_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State SUB_USER
 */
const INITIAL_STATE = {
  loading: false,
  allfilters: {
    openSearch: "",
    limit: 10,
    offset: 0
  },
  registers: [],
  subUserSelected: null,
  loadedRecord: false,
  loadedResetCognito: false,
  loadingSubUser: false,
  resultPasswordReset: false,
  total: 0,
  error: null,
  loadingCurrentSubUser: false,
  currentSubUser: null,
  errorCurrentSubUser: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUB_USER_SEARCH:
      return {
        ...state,
        subUserSelected: null,
        loadedRecord: false,
        error: null,
        loading: true,
        allfilters: action.payload
      };
    case SUB_USER_SEARCH_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        total: action.payload?.total,
        loading: false,
        error: action.payload?.error
      };
    case SUB_USER_GET:
      return {
        ...state,
        loadingSubUser: true,
        subUserSelected: null,
        error: null
      };
    case SUB_USER_GET_RESPONSE:
      return {
        ...state,
        loadingSubUser: false,
        subUserSelected: action.payload?.getSubUser,
        error: action.payload?.error
      };
    case SUB_USER_CURRENT_GET:
      return {
        ...state,
        loadingCurrentSubUser: true,
        currentSubUser: null,
        errorCurrentSubUser: null
      };
    case SUB_USER_CURRENT_GET_RESPONSE:
      return {
          ...state,
          loadingCurrentSubUser: false,
          currentSubUser: action.payload?.getSubUser,
          errorCurrentSubUser: action.payload?.error
      };
    case SUB_USER_CREATE:
      return {
        ...state,
        loadedRecord: false,
        error: null,
        loadingSubUser: true,
        subUserSelected: null
      };
    case SUB_USER_CREATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.newSubUser,
        error: action.payload?.error,
        loadingSubUser: false,
        subUserSelected: null
      };
    case SUB_USER_UPDATE:
      return {
        ...state,
        loadedRecord: false,
        error: null,
        loadingSubUser: true,
        subUserSelected: null
      };
    case SUB_USER_UPDATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.updateSubUser,
        error: action.payload?.error,
        loadingSubUser: false,
        subUserSelected: null
      };
    case SUB_USER_DELETE:
      return {
        ...state,
        subUserSelected: null,
        loadedRecord: false,
        error: null,
        loading: true,
      };
    case SUB_USER_DELETE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.deleteSubUser,
        error: action.payload?.error,
        loading: false
      };
    case SUB_USER_RESET_COGNITO:
      return {
        ...state,
        subUserSelected: null,
        loadedResetCognito: false,
        error: null,
        loading: true,
      };
    case SUB_USER_RESET_COGNITO_RESPONSE:
      return {
        ...state,
        loadedResetCognito: action.payload?.cognitoReset,
        error: action.payload?.error,
        loading: false
      };
    case SUB_USER_DELETE_BULK:
      return {
        ...state,
        subUserSelected: null,
        loadedRecord: false,
        error: null,
        loading: true,
      };
    case SUB_USER_RESET_PASSWORD:
      return {
        ...state,
        resultPasswordReset: false,
        error: null,
        loading: true
      };
    case SUB_USER_RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        resultPasswordReset: action.payload?.result,
        error: action.payload?.error,
        loading: false,
      };
    case CLEAR_REDUX:
      return ["", "SUB_USER"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };
    default:
      return state;
  }
};
