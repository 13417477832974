//View Keys
export default {
    //UnitFor
    "unit_edit_unit":"EDITAR UNIDAD",
    "unit_label": "ETIQUETA DE LA UNIDAD",
    "unit_label_placeholder": "UNIDAD",

    "unit_odometer": "ODOMETRO",
    "unit_odometer_placeholder": "odometro",
    "unit_odometer_dash_reading_label": "LECTURA DE TABLERO",
    "unit_odometer_dash_reading_placeholder": "lectura de tablero",
    "unit_odometer_offset_label": "DIFERENCIA",
    "unit_odometer_offset_placeholder": "273.4",

    "unit_engine_hours": "HORAS DE MOTOR",
    "unit_engine_hours_placeholder": "horas de motor",
    "unit_engine_hours_dash_reading_label": "LECTURA DE TABLERO",
    "unit_engine_hours_dash_reading_placeholder": "lectura de tablero",
    "unit_engine_hours_offset_label": "DIFERENCIA",
    "unit_engine_hours_offset_placeholder": "horas de motor",

    "unit_speed": "VELOCIDAD",
    "unit_speed_placeholder": "velocidudad",

    "unit_consistent_speed": "Límite de Velocidad",

    "unit_driver": "CONDUCTOR",
    "unit_select_placeholder": "Seleccionar",

    "unit_group": "GRUPO",
    "unit_general": "GENERAL",
    "unit_description": "DESCRIPCION",
    "unit_vin": "VIN",

    "unit_location": "UBICACIÓN",
    "unit_time_zone": "TIMEZONE",

    "unit_year": "AÑO",
    "unit_engine_liters": "LITROS MOTOR",
    "unit_engine_liters_placeholder": "Listros de Motor",

    "unit_mpg": "MPG",

    "unit_highway": "AUTOPISTA",
    "unit_highway_placeholder": "Autopista",
    "unit_city": "CIUDAD",
    "unit_city_placeholder": "Ciudad",


    "unit_notes": "NOTAS",
    "unit_input_labels": "ETIQUETAS DE ENTRADAS",
    "unit_input_label": "ETIQUETA DE ENTRADAS",

    "unit_output_labels": "ETIQUETAS DE SALIDA",
    "unit_output_label": "ETIQUETA DE SALIDA",

    "unit_on_label": "Etiqueta Encendido",
    "unit_off_label": "Etiqueta Apagado",

    "unit_on_label_placeholder": "Encendido",
    "unit_off_label_placeholder": "Apagado",

    "unit_make": "Marca",
    "unit_make_new": "Adicionar Nueva Marca",
    "unit_model": "MODELO",
    "unit_model_new": "Adicionar Nuevo Modelo",

    "unit_inputs_alert_message": " Ese input ya fue asignado ",
    "unit_inputs_alert_title": " Espere",

    "unit_validation_alert_message": " Hay errores de validación que debes verificar antes de continuar ",
    "unit_validation_alert_title": " Error de validación ",

    "unit_validation_succes_message": " Bien! data actualizada ",
    "unit_validation_success_title": " Exitoso ",

    "unit_vehicle_Status_icon": "ICONO DEL VEHICULO",


    "unit_data": "DATA",
    "unit_disable_all": "Deshabilitar Todo",
    "unit_enable_all": "Habilitar Todo",

    "unit_calculated_by_ecu": "Calculado por ECU",
    "unit_calculated_by_gps": "Calculado por GPS",

    "unit_actual": "ACTUAL",
    "unit_output_alerts": "Alertas",

    "unit_output_low_battery_alert": "Alerta de Batería Baja",
    "unit_output_motion": "Movimiento",
    "unit_output_no_gps": "Sin GPS",
    "unit_output_rapid_acceleration": "Aceleración Rápida",
    "unit_output_hard_brake": "Freno Duro",
    "unit_output_sudden_stop": "Parada Repentina",
    "unit_output_hard_turn": "Giro Brusco",
    "unit_output_tow": "Remolcar",
    "unit_output_consistent_speed": "Velocidad Constante",
    "unit_output_consistent_speed_duration": "Activar alerta cuando la velocidad es 4 minutos",
    "unit_output_trigger_alert_when": "Activar Alerta Cuando...",
    "unit_output_within": "dentro de",

    "unit_time": "vez",
    "unit_times": "veces",
    "unit_day": "día",
    "unit_days": "días",

    "unit_vbusAlerts": "Alertas OBDII",
    "unit_absActiveLamp": "Lámpara Activa ABS",
    "unit_absIndicator": "Indicador ABS",
    "unit_airbagIndicator": "Indicador de bolsa de aire",
    "unit_brakeIndicator": "Indicador de frenos",
    "unit_oilPressure": "Presión de aceite",
    "unit_coolantHotLight": "Indicador liquido refrigerante",
    "unit_cruiseControl": "Control de crucero",
    "unit_engineCode": "Código de motor",
    "unit_milStatus": "Estado MIL",
    "unit_ptoStatus": "Estado PTO",

    "unit_batteryVoltage": "Voltaje de la batería <=",
    "unit_voltage": "Voltaje",
    "unit_coolantTemp": "Temperatura del refrigerante °F",
    "unit_engineSpeed": "Velocidad del motor >=",
    "unit_fuelLevel": "Nivel de combustible <=",
    "unit_fuelRemaining": "Combustible restante <=",
    "unit_fuelRate": "Tasa de combustible >=",
    "unit_alertOdometer": "Odómetro",
    "unit_throttlePosition": "Posición del acelerador >=",
    "unit_seatbeltFastened": "Cinturón de seguridad abrochado",
    "unit_brakeSwitchStatus": "Estado del interruptor de freno",
    "unit_ignitionStatus": "Estado de encendido",
    "unit_seatbeltFastenedAlert": "Cinturón de seguridad abrochado (Velocidad >=)",
    "unit_alertContacts": "Contactos de alerta",
    "unit_tripOdometer": "Odómetro de viaje",
    "unit_tripFuel": "Consumo de combustible de viaje",
    "unit_vehicleSpeed": "Velocidad del vehículo >=",

    "unit_rapidAccelerationHelpText": `Límite: 5.6 MPH/s, Disparador: 1.5s - 3.0s, La aceleración rápida se detecta en función de un vehículo que acelera por encima de un umbral. La aceleración se expresa como el aumento de la velocidad en millas por hora (MPH) durante un período de un segundo.`,
    "unit_hardBrakeHelpText": `Límite: 4 MPH/s, Disparador: 1.5s - 3.0s, El frenado brusco se detecta en función de un vehículo que reduce la velocidad por encima de un umbral. La desaceleración se expresa como la disminución de la velocidad en millas por hora (MPH) durante un período de un segundo.`,
    "unit_suddenStopHelpText": `Límite: 9g, Disparador: 1.5s - 3.0s, Sudden Stop mide la fuerza ejercida, en unidades de Gravedad (Gs). El umbral se establece en un múltiplo de la fuerza de la gravedad, que se siente como peso sobre el cuerpo humano.`,
    "unit_hardTurnHelpText": `Límite: 4 MPH/s, Disparador: 1.5s - 3.0s, Un giro brusco se detecta utilizando la dirección de desplazamiento. Mide las desviaciones, medidas en centímetros, de esta dirección en cortos períodos de tiempo (Fracciones de segundo).`,
    "unit_coolantTempHelpText": `Alerta de activación cuando la temperatura es igual o superior...`,
    "unit_alertOdometerHelpText": `Alerta activada cuando el odómetro alcanza o supera este valor`,
    "unit_diagnosticAlertsHelpText": `*Los dispositivos aplicables incluyen L2000V (con dongle OBDII), G3000 y CP4000. Estos dispositivos también requieren programación adicional.`,
    "unit_tripOdometerHelpText": "Alerta activada cuando el odómetro de viaje alcanza o supera este valor durante un viaje determinado",

    "unit_jbusAlerts": "DTC de servicio pesado",
    "unit_jbusDtc": "DTC",
    "unit_jbusBatteryVoltage": "Voltaje de la batería <",
    "unit_jbusVoltage": "Voltaje",
    "unit_jbusEngineCrankCasePressure": "Presión del cárter del motor >= (PSI)",
    "unit_jbusEngineCrankCasePressureHelpText": "Activar alerta si la presión es igual o superior",
    "unit_jbusOdometer": "Odómetro > (Miles)",
    "unit_jbusOdometerHelpText": "Activar alerta cuando el odómetro sobrepasa",
    "unit_jbusRpm": "RPM del motor >=",
    "unit_jbusRpmHelpText": "Activar alerta cuando RPM sobrepasa",
    "unit_jbusNotSet": "No establecido",
    "unit_jbusOk": "Ok",
    "unit_jbusNotBucklet": "No abrochado",
    "unit_jbusError": "Error",
    "unit_jbusOff": "Off",
    "unit_jbusFlashing": "Brillante",
    "unit_jbusOn": "On",
    "unit_jbusSeatbeltSwitch": "Interruptor del cinturón de seguridad",
    "unit_jbusHazard": "Peligro",
    "unit_jbusHazardHelpText": "Activar alerta cuando el estado es",
    "unit_jbusGeneral": "General",
    "unit_jbusPedals": "Pedales",
    "unit_jbusAcceleratorPedalPosition": "Posición del pedal de aceleración >=",
    "unit_jbusAcceleratorPedalPositionHelpText": "Activar alerta si la posición del pedal está en o supera",
    "unit_jbusBrakePedalPosition": "Posición del pedal de freno >=",
    "unit_unit_jbusBrakePedalPositionHelpText": "Alerta de activación cuando la posición del pedal está en o supera",

    "unit_jbusBrakes": "Frenos",
    "unit_jbusBrakeSystemSwitchStatus": "Estado del interruptor del sistema de frenos",
    "unit_jbusServiceBrakeSwitchStatus": "Estado del interruptor del freno de servicio",
    "unit_jbusParkingBrakeSwitch": "Interruptor de freno de mano",
    "unit_jbusActive": "Activo",
    "unit_jbusInactive": "Inactivo",

    "unit_jbusCoolant": "Refrigerante del motor",
    "unit_jbusEngineCoolantLevel": "Nivel <=",
    "unit_jbusEngineCoolantPressure": "Presión (PSI) >=",
    "unit_jbusEngineCoolantTemp": "Temperatura (F) >=",

    "unit_jbusOil": "Aceite del motor",
    "unit_jbusEngineOilLevel": "Nivel <=",
    "unit_jbusEngineOilPressure": "Presión (PSI) >=",
    "unit_jbusEngineOilTemp": "Temperatura (F) >=",

    "unit_jbusLampIndicators": "Indicadores de luz",
    "unit_jbusAmberWarningLamp": "Luz de advertencia ámbar",
    "unit_jbusFlashAmberWarningLamp": "Luz de advertencia ámbar intermitente",
    "unit_jbusFlashMalfunctionIndicatorLamp": "Luz indicadora de mal funcionamiento del flash",
    "unit_jbusFlashProtectLamp": "Luz de protección de flash",
    "unit_jbusFlashRedStopLamp": "Luz roja de freno intermitente",
    "unit_jbusProtectLampStatus": "Protección de estado de luz ",
    "unit_jbusRedStopLampStatus": "Estado de la luz roja de freno",

    "unit_jbusFuel": "Combustible del Motor",
    "unit_jbusEnginefuelrate": "Tasa de combustible >",
    "unit_jbusFuelLevelOne": "Nivel de combustible 1 <=",
    "unit_jbusFuelLevelTwo": "Nivel de combustible 2 <=",

    "unit_jbusAlertContacts": "Contactos",

    "unit_apu": "Controles APU",
    "unit_apuPingsRemaining": "Pings Restantes",
    "unit_apuStarterControl": "Control de Arranque",
    "unit_apuEnableStarter": "Habilitar Arrancador",
    "unit_apuDisableStarter": "Deshabilitar Arrancador",
    "unit_apuPingUnit": "Ping de la Unidad",
    "unit_apuSendPing": "Enviar Ping",

    "unit_apuPingSuccess": "Comando enviado con éxito",
    "unit_unitAlerts": "Alertas de Unidades",
    "unit_subscribers": "Subscriptores",
    "unit_contacts": "Contactos",
    "unit_driverBehavior": "Comportamiento del Conductor",

    "unit_diagnosticsAlerts": "DTC de servicio liviano",

    "unit_settings_belongs_tags": "Esta unidad pertenece a las siguientes etiquetas:",

    "unit_settings_decode_vin_error": "No se ha encontrado información para este VIN",
    "unit_settings_decode_vin": "Decodificar VIN",
    "unit_settings_start_troubleshooting": "Iniciar la resolución de problemas",
};