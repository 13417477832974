import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {clientMutation} from '../../../../../../../core/data/GraphQLApi';
import {endpoints} from '../../../../../../../core/defaultValues';
import {ExceptionManager} from '../../../../../../../core/logManager';
import {resetALL} from '../../../../../../../.globals/redux/actions';
import {useFormatMetric} from "Modules/settings/views/behavior/redux/helpers";

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    error: null,
    createMetricResult: null,
    updateMetricResult: null,
    metric: null
};

const route = 'modules/settings/views/behavior/modals/create/redux/slice.js'

export const createMetricService = createAsyncThunk('manage/behavior/createMetricService', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    const {metric, metricValue, points} = payload;
    try {
        let query = `
        mutation createPointMetric ($metricValue: Int!, $points: Int!, $metric: enumMetric!) {
            createPointMetric (metricValue: $metricValue, points: $points, metric: $metric) {
                metric
                metricValue
                points
            }
        }`;

        const formatMetric = useFormatMetric().formatMetric(metric);
        return await clientMutation(
            query,
            {
                metric: formatMetric,
                metricValue: metricValue?.value || 0,
                points: points || 1,
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
    } catch (exc) {
        ExceptionManager(exc, route, 'createMetricService');
        return rejectWithValue(exc)
    }
});

export const updateMetricService = createAsyncThunk('manage/behavior/updateMetricService', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    const {metric, metricValue, points, id} = payload;
    try {
        let query = `
        mutation updatePointMetric ($id: Int!, $metricValue: Int!, $points: Int!, $metric: enumMetric!) {
            updatePointMetric (id: $id, metricValue: $metricValue, points: $points, metric: $metric) {
                metric
                metricValue
                points
                id
            }
        }`;

        const formatMetric = useFormatMetric().formatMetric(metric);
        return await clientMutation(
            query,
            {
                metric: formatMetric,
                metricValue: metricValue?.value || 0,
                points: points || 1,
                id,
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
    } catch (exc) {
        ExceptionManager(exc, route, 'updateMetricService');
        return rejectWithValue(exc)
    }
});

const manageCreateUpdateMetricServiceRedux = createSlice({
    name: 'manage/behavior',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? {...state, ...action.payload} : {...INIT_STATE}
        },
        openModalCreateUpdateMetricService(state, action) {
            state.open = true;
            state.metric = action?.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(createMetricService.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createMetricService.fulfilled, (state, action) => {
            state.loading = false;
            state.createMetricResult = action.payload;
        });
        builder.addCase(createMetricService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(updateMetricService.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateMetricService.fulfilled, (state, action) => {
            state.loading = false;
            state.updateMetricResult = action.payload;
        });
        builder.addCase(updateMetricService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = manageCreateUpdateMetricServiceRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalCreateUpdateMetricService,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
