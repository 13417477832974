import createEditRouteLanguage from '../components/createEditRouteModalMain/lan/en'
import createEditRoutePointLanguage from '../components/createEditRoutePoint/lan/en'
import modalMarkerRouteLanguage from '../components/modalMarkerRoute/lan/en'
import modalDeleteRoutePointLanguage from '../components/deleteRoutePoint/lan/en'
import modalCreateEditRouteModalMainLanguage from '../components/createEditRouteModalMain/lan/en'
import editPanelRouteLanguague from '../components/editPanelRoute/lan/en'
import modalCloneRouteLanguague from '../components/modalCloneRoute/lan/en'
import alertWatcher from '../components/alertWatchers/lan/en'
import etaRoutes from '../components/etaRoutes/lan/en'

export default {
    ...createEditRouteLanguage,
    ...createEditRoutePointLanguage,
    ...modalMarkerRouteLanguage,
    ...modalDeleteRoutePointLanguage,
    ...modalCreateEditRouteModalMainLanguage,
    ...editPanelRouteLanguague,
    ...modalCloneRouteLanguague,
    ...alertWatcher,
    ...etaRoutes,

    fsmRoute_title: "Routes",
    fsmRoute_title_schedule: "Schedule Route",
    fsmRoute_nameRoute: "Name",
    fsmRoute_tracker: "tracker",
    fsmRoute_color: "Color",
    fsmRoute_start: "Start",
    fsmRoute_end: "End",
    fsmRoute_stop: "Stop",
    fsmRoute_stops: "Stops",
    fsmRoute_upload_route: "Upload Routes",
    fsmRoute_updated_by: "Updated On",
    fsmRoute_updated_on: "Updated By",
    fsmRoute_create_route: "Create Route",
    fsmRoute_edit_route: "Edit Route",
    fsmRoute_label_type: "Type",
    fsmRoute_label_stop: "Stop",
    fsmRoute_label_address: "Address",
    fsmRoute_label_radius: "Radius",
    fsmRoute_label_display: "Display Level",
    fsmRoute_label_notes: "Notes",
    fsmRoute_label_close: "Close",
    fsmRoute_label_add_stop: "Add Stop",
    fsmRoute_label_add_stoporwaypoint: "Add Stop / Waypoint",
    fsmRoute_label_add_waypoint: "Add Waypoint",
    fsmRoute_label_add_clone_route: "Clone Route",
    fsmRoute_label_add_clone_route_success: "The Route was cloned successfully",
    fsmRoute_label_add_route_save: "The Route was saved successfully",
    fsmRoute_label_route_directions_title: "Route Directions",
    fsmRoute_label_route_directions: "Get Route Directions",
    fsmRoute_label_delete_all_stop: "Delete all stops",

    
};