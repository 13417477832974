import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
    ContextMenu,
    Button
} from 'Components';

import {
    Chip,
    Icon
} from "@mui/material";
import { getTagColor, cutNotes } from 'Core/data/Helpers';

import Tags from './tags';
import colors from 'Assets/sass/core/vars.scss';

// Styles
import '../styles.scss';

const TagPickerDropdown = (props) => {
    const { messages } = useIntl();

    const inputRef = useRef();
    const containerRef = useRef();

    const {
        label,
        placeholder,
        searchText,
        setSearchText,
        tags,
        values,
        onChange,
        disabled,
        required,
        requiredMessage,
        showSelectAll,
        multiple,
        page,
        setPage,
        total
    } = props;

    const allTags = useSelector(state => state.newEntityTagPickerRedux.tags);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (e) => {
        if (containerRef?.current?.contains(e.target)) {
            return;
        }

        setOpen(false);
        setSearchText("");
    };

    const openMenu = useCallback((e) => {
        if (e) e.preventDefault();
        setOpen(!open);
        inputRef?.current?.focus();
    });

    return (
        <div>
            {
                label && (
                    <label className="input-normal-label">
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )
            }

            <div ref={containerRef} open={open} className="gtc-tag-picker-dropdown-container">
                <div className="gtc-tag-picker-input-container">
                    {
                        !open &&
                        <React.Fragment>
                            <div
                                className={`gtc-tag-picker-value-container ${disabled ? 'gtc-tag-picker-disabled' : ''}`}
                                id="tag-picker-value-container"
                                onClick={(e) => {
                                    if (!disabled) {
                                        openMenu(e);
                                    }
                                }}
                            >
                                <span className='text-muted'>
                                    {placeholder || messages['entityTagPicker_searchTag']}
                                </span>
                            </div>

                            {
                                required && requiredMessage && (
                                    <label className="input-normal-label text-danger">{requiredMessage || messages['required_field']}</label>
                                )
                            }

                            <div
                                style={{
                                    marginTop: 8,
                                    maxHeight: 178,
                                    overflow: 'hidden auto'
                                }}
                            >
                                {
                                    (values?.length > 0) && values.map((value, index) => {
                                        const currentTag = allTags?.find(tag => tag?.id == value?.id);
                                        let label = cutNotes(value?.label, 15);
                                        let currentColor = value?.color || currentTag?.color;
                                        currentColor = currentColor?.replace("#", "");
                                        let color = `#${getTagColor(currentColor)}`;

                                        if (value?.id == -99) {
                                            label = messages['tagPicker_allTagsAreSelected'];
                                            color = colors?.green;
                                        } else if (value?.id == -1) {
                                            label = messages['untagged'];
                                        }

                                        return (
                                            <Chip
                                                label={label}
                                                key={index}
                                                style={{
                                                    color: "white",
                                                    backgroundColor: color,
                                                    marginBottom: 8
                                                }}
                                                clickable={true}
                                                deleteIcon={<Icon style={{ fontSize: 14 }}>close</Icon>}
                                                onDelete={() => {
                                                    let newValues = values.filter(nv => nv.id != value.id);
                                                    if (onChange) onChange(newValues);
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </React.Fragment>
                    }

                    {
                        open &&
                        <input
                            ref={inputRef}
                            className="gtc-tag-picker-input input-normal form-control"
                            type="text"
                            value={searchText}
                            placeholder={placeholder || messages['entityTagPicker_searchTag']}
                            onChange={(e) => {
                                setSearchText(e?.target?.value);
                            }}
                            autoFocus
                            disabled={disabled}
                        />
                    }

                    <div className="gtc-tag-picker-menu-arrow-button">
                        <Button
                            type="icon"
                            icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                            placement="bottom"
                            className="btn-xs"
                            fontSize="inherit"
                            disabled={disabled}
                            onClick={openMenu}
                        />
                    </div>
                </div>

                <ContextMenu
                    style={{
                        width: '100%',
                        marginTop: 2,
                        position: 'absolute',
                        maxHeight: 250,
                        overflow: 'auto'
                    }}
                    open={open}
                >
                    <div className="gtc-tag-picker-options-container">
                        <Tags
                            searchText={searchText}
                            tags={tags}
                            values={values}
                            onChange={onChange}
                            disabled={disabled}
                            showSelectAll={showSelectAll}
                            multiple={multiple}
                            page={page}
                            setPage={setPage}
                            total={total}
                        />
                    </div>
                </ContextMenu>
            </div>
        </div>
    );
}

export default TagPickerDropdown;
