import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import SliderOneColor from './SliderOneColor';
import './index.scss';

/**
 * Builds a slider component.
 * @property {int} sliderStep - Allows setting the number of units through which the slider increases and decreases.
 * ...
 * ..
 * .
 */
const CustomizedSlider = (props) => {
  const {
    min,
    max,
    colorOne,
    colorThree,
    colorTwo,
    value,
    isMultiple,
    valueLabelDisplay,
    onChange,
    sliderOneColor,
    colorFour,
    colorFive,
    showValue,
    measure,
    disabled,
    sliderStep,
    showCenterValue
  } = props

  let valueOne, valueTwo, valueThree, valueFour;
  if (isMultiple) {
    [valueOne, valueTwo, valueThree, valueFour] = value;
  }
  const handleChange = (e, value) => {
    if (isMultiple) {
      const [start, end] = value;
      if (end !== start && end < max && start > min) {
        onChange(value);
      }
    } else {
      onChange(value);
    }
  };

  const getColors = () => {
    if (valueOne) {
      const calculatePercentage = (value, total) => {
        return (value / total) * 100;
      }
      // to five colors change colorthree for colorfive and valuetwo for valuefour
      const colors= `linear-gradient(to right, ${colorOne}, ${colorOne} ${calculatePercentage(valueOne,max)}%, ${colorThree} ${calculatePercentage(valueTwo,max)}%, ${colorThree})`;
      return colors;
    }
  }

  const showCenter = showCenterValue ? {
    top: 20,
    borderRadius: '50%'
  } : {};

  const sliderColors = getColors()

  // Dynamic Styles to be Apply depending of the Slider Type
  const customStyles = {
    root: {
      sx:{
        color: '#ccc',
        width: '100%',
        overflow: 'initial !important'
      }
    },
    thumb: {
      sx:{
        height: (isMultiple || showCenterValue) ? "24px" : "17px",
        width: (isMultiple || showCenterValue) ? "24px" : "17px",
        backgroundColor: '#fff',
        "&>.row-value-label": {
          ... showCenter,
          marginTop: "5px",
          left: (isMultiple || showCenterValue) ? `${value > 9 ? 'calc(-25%)' : (value === 1 ? 'calc(-12%)' : '') }` : 'calc(-50% + 1px)'
        },
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
        background: colorOne,
        "&~&": {
          background: colorTwo
        },
        "&~&~&": {
          background: colorThree
        },
        "&~&~&~&": {
          background: colorFour
        },
        "&~&>.row-value-label": {
          background: colorTwo
        },
        "&~&~&>.row-value-label": {
          background: colorThree
        },
        "&~&~&~&>.row-value-label": {
          background: colorFour
        },
        "&~&>.row-value-label::after": {
          borderTopColor: `${colorTwo} !important`
        },
        "&~&~&>.row-value-label::after": {
          borderTopColor: `${colorThree} !important`
        },
        "&~&~&~&>.row-value-label::after": {
          borderTopColor: `${colorFour} !important`
        },
        circle: {
          borderRadius: '0 0 0 0',
        }
      }
    },
    //active: {},
    valueLabel: {
      sx:{
        left: 'calc(-50% + 7px)',
        circle: {
          borderRadius: '0 0 0 0'
        },
        '&::after': {
          borderTopColor: `${colorOne} !important`
        },
        background: colorOne
      }
    },
    track: {
      sx:{
        background: (isMultiple ? colorTwo : colorOne) || "red"
      }
    },
    rail: {
      sx:{
        opacity: 1,
        background: sliderColors,
      }
    }
  }

  return (
    <div style={{ display: 'flex', width: '100%' }} className='SliderCustom'>
      {
        sliderOneColor ?
          (
            <SliderOneColor
              value={value}
              onChange={handleChange}
              min={min}
              max={max}
              start={valueOne}
              end={valueTwo}
              colorone={colorOne}
              colortwo={isMultiple ? colorTwo : colorOne}
              colorthree={colorThree}
              colorfour={colorFour}
              colorfive={colorFive}
              valueLabelDisplay={valueLabelDisplay}
              disabled={ props.disabled || false }
              //blueSlider={blueSlider}
            />
          ) : (
            <Slider
              className="myCustomSlider"
              classes={{
                valueLabel: showCenterValue ? 'row-value-label slider-circle' : 'row-value-label'
              }}
              value={value}
              arrayvalues={value}
              onChange={handleChange}
              min={min}
              max={max}
              valueLabelDisplay={valueLabelDisplay}
              disabled={ disabled || false }
              step={sliderStep}
              //blueSlider={blueSlider}
              slotProps={customStyles}
            />
          )
      }
      {showValue && (
        <div className="row-value-display"><strong>{`${value} ${measure ? measure : ""}`}</strong></div>
      )}
    </div>
  );
};

CustomizedSlider.defaultProps = {
  min: 0,
  max: 100,
  colorOne: '#00AEEF',
  colorThree: '#ccc',
  value: 30,
  isMultiple: false,
  valueLabelDisplay: "on",
  sliderStep: 1,
}

CustomizedSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: (props, propName, componentName) => {
    let error;
    const max = props[propName];
    const min = props['min'];
    if (!max) {
      error = new Error(`\`${componentName}\` max is required.`,);
    }
    if (max <= min) {
      error = new Error(`\`${componentName}\` max should be greater than min.`,);
    }
    return error;
  },
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string
  ]),
  isMultiple: PropTypes.bool,
  valueLabelDisplay: PropTypes.string,
  colorOne: PropTypes.string,
  colorTwo: PropTypes.string,
  colorThree: PropTypes.string,
  colorFour: PropTypes.string,
  colorFive: PropTypes.string,
  sliderOneColor: PropTypes.bool,
  sliderStep: PropTypes.number,
};

export default CustomizedSlider;
