import React, {Fragment, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";

// controls
//helpers
import {
    formatDateFromFilter,
    formatDatetime,
    formatGeneralNumber,
    getGroupId, getNameUnitDriverForSubtables,
    getNameUnitDriverTag,
    isKPHAcronym,
    useHandleExportReport,
} from "Modules/reports/views/main/components/reportsTab/helper";

//hooks
import {useReportsSelector} from "Core/hooks";
//actions
import {reportsMainGenerateReportUpdate,} from "Redux/actions";
import {Grid} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfiniteScrollTable from "Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable";
import {cutNotes, GetFormatForMoment} from "Core/data/Helpers";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";
import {Tooltip} from "@gpstrackit/gtc-react-controls";
import './maintenanceItemsReport.scss';

const ROW_PER_PAGE = 25;

const MaintenanceItemsReport = (props) => {

  const {messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive} = props;
  const dispatch = useDispatch()
  const {loading, asyncLoading, data, filters, timeZone} = report
  const {filterEntity, filterArray, filterType} = filters;
  const exportHandler = useHandleExportReport(report)
  const [lastEntity, setLastEntity] = useState({});
  const momentFormat = GetFormatForMoment(user.dateformat)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)


  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (isActive) {
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])


  const buildTableHeader = () => {
      if (report.categoryName === 'deviceId'){
          return (
              <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>
                  <Grid item sm={2} className={'reports-cut-text'}>
                      {messages['generatedReport_timeAndDate']}
                  </Grid>
                  <Grid item sm={2} className={'reports-cut-text'}>
                      {messages['reportsMainRT_serviceTitle']}
                  </Grid>
                  <Grid item sm={2} className={'reports-cut-text'}>
                      {messages['reportsMainRT_distance']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_days']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_hours']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['generatedReport_price']}
                  </Grid>
                  <Grid item sm={3} className={'reports-cut-text'}>
                      {messages['generatedReport_notes']}
                  </Grid>
              </Grid>
          )
      } else {
          return (
              <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>
                  <Grid item sm={2} className={'reports-cut-text'}>
                      {getNameUnitDriverForSubtables(filterEntity, messages)}
                  </Grid>
                  <Grid item sm={2} className={'reports-cut-text'}>
                      {messages['generatedReport_timeAndDate']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_serviceTitle']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_distance']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_days']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['reportsMainRT_hours']}
                  </Grid>
                  <Grid item sm={1} className={'reports-cut-text'}>
                      {messages['generatedReport_price']}
                  </Grid>
                  <Grid item sm={3} className={'reports-cut-text'}>
                      {messages['generatedReport_notes']}
                  </Grid>
              </Grid>
          )
      }

  }

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;

    return (
        <div
            className="listItem groupItem"
            id={id}
            key={id}
            style={style}
        >

            <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'  style={{marginTop: "25px"}}>
            <Grid container item className='table-scroll-reports-header'>
              <Grid item sm={3}>

                {
                  element?.tagId > 0 ?
                      (<LocalOfferIcon
                          style={{
                            fontSize: "16.8px",
                            color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`,
                            position: 'absolute'
                          }}/>)
                      : ""
                }
                <span
                    className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                {
                    report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                }
              </Grid>
              <Grid item sm={9}>
<span
    className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
              </Grid>
            </Grid>
            <div className='table-scroll-reports-separator'></div>
            <Grid item>
              <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
            </Grid>
          </Grid>
        </div>
    )
  }

  const TableViewSummary = ({index, style, content, id, previousItem}) => {
    const item = content?.recordDetail;
    return (
        <div
            className="listItem groupItem"
            id={id}
            key={id}
            style={style}
        >
          <Grid container className='table-scroll-reports-group' style={{marginTop: '1px'}}>
            <div className='table-scroll-reports-separator'></div>
            <Grid item sm={4}>
              <strong>{messages['generatedReport_totalCost']}: {item.totalCost}</strong>
            </Grid>
          </Grid>
          {buildTableHeader()}
        </div>
    )
  }

  const TableViewDetail = ({index, style, content, id, previousItem}) => {
    const element = content?.recordDetail;

    return (
        <div
            className="listItem detailItem"
            style={style}
            id={id}
            key={id}
        >
            <Grid container direction={"column"} style={{marginBottom: "10px", marginTop: "1px"}}>
                <Grid container item direction={"row"} style={{paddingTop: "1px", paddingBottom: "1px"}}>
              {
                element.noData ?
                    <Grid item className={"report-ctn-text"} sm={12}>
                      {messages["reportsMainRT_noDataEvent"]}
                    </Grid>
                    :
                    <>
                    {
                        report.categoryName === 'deviceId' ?
                            <>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    {element.serviceTitle}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    {element.distance ? formatGeneralNumber(element.distance) : '0.00'} {messages[isKPHAcronym(element.isKPH)]}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.days}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.hours}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.cost}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={3}>
                                  <span className='detailText'>
                                    <Tooltip
                                        title={element.notes || ''}
                                        placement="top"
                                    >
                                      {cutNotes(element.notes, 20)}
                                    </Tooltip>
                                  </span>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    {filterEntity === "Unit" ? element?.driverName : element?.deviceLabel}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.serviceTitle}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.distance ? formatGeneralNumber(element.distance) : '0.00'} {messages[isKPHAcronym(element.isKPH)]}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.days}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.hours}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    {element.cost}
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={3}>
                                  <span className='detailText'>
                                    <Tooltip
                                        title={element.notes || ''}
                                        placement="top"
                                    >
                                      {cutNotes(element.notes, 20)}
                                    </Tooltip>
                                  </span>
                                </Grid>
                            </>
                    }
                    </>
              }
            </Grid>
          </Grid>
        </div>
    )
  }

    const renderItems = (parameters) => {
        const {content} = parameters;
        const type = content.recordInfo?.recordType;
        if (type === "GROUP") {
            const groupId = getGroupId(content)
            const entitiesArray = entitiesArrayRef.current || {};
      if (!entitiesArray[groupId]) {
        const newEntityArray = {...entitiesArray};
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }
      if (parameters.index === 0) {
        return;
      }

      return (
          <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if (type === "UNIT_SUMMARY") {
      return <TableViewSummary {...parameters}></TableViewSummary>;
    } else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }

    if (parameters.index === 0) {
      return;
    }

    return (
        <div>
          unknown type
        </div>
    )
  }

  const onStartIndex = (index) => {
    const item = data?.items[index];

    if (item) {
      if (item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId) {
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if (itemHeader) {
      return (
          <TableViewGroup
              style={{
                backgroundColor: '#fff',
                position: 'sticky',
                top: 0,
                zIndex: 2

              }}
              content={itemHeader}
              id={itemHeader?.recordInfo?.entityId}
          ></TableViewGroup>
      );
    }
    return;

  };

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
      (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
  return (
      <>
        <InfiniteScrollTable
            refContainer={detailReportTableRef}
            renderItems={renderItems}
            loading={asyncLoading || loading}
            data={data.items || []}
            total={data.total}
            reportTitle={messages['reportsMainRT_vehicleMaintenanceItemsReport']}
            editReport={() => {
              copyReportToSetup({...report, data: []})
            }}
            onSchedule={() => {
              scheduleReport(report)
            }}
            onCreate={openReportSetup}
            tableContainerClass={"detailed-report-reports-container"}
            type={type}
            handleNewPageLoad={(page) => {
              let currentReport = {...report};
              currentReport.exportType = "json";
              currentReport.filters = {
                ...currentReport.filters,
                unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected : null,
                accumulateData: true,
                dateSelected: dateSelected?.id != -99 ? unitDriverSelected : null,
              };
              dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
            }}
            renderHeader={renderHeader}
            onStartIndex={onStartIndex}
            isActive={isActive}
            {...reportTableSelect}
            {...exportHandler}
        />
      </>
  );
};

export default MaintenanceItemsReport;
