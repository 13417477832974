import {
    MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL,
    MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL_EDIT,
    MAP_LANDMARKS_GET_LANDMARK,
    MAP_LANDMARKS_GET_LANDMARK_RESPONSE,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_RESPONSE,
    MAP_LANDMARKS_EDIT_IMPORT_OPEN_MODAL
} from 'Redux/actionTypes';

export const mapLandmarksOpenEditCreateModal = (open, id) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL,
    payload: { open, id },
});

export const mapLandmarksOpenEditCreateModalWithParams = (open, data, form = null) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL_EDIT,
    payload: { open, data, form },
});

export const mapLandmarksGetLandmark = (id) => ({
    type: MAP_LANDMARKS_GET_LANDMARK,
    payload: { id },
});

export const mapLandmarksGetLandmarkResponse = (data) => ({
    type: MAP_LANDMARKS_GET_LANDMARK_RESPONSE,
    payload: { data },
});

export const mapLandmarksEditCreateLandmark = (landmark) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_LANDMARK,
    payload: { landmark },
});

export const mapLandmarksEditCreateLandmarkResponse = (data) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_LANDMARK_RESPONSE,
    payload: { data },
});

export const mapLandmarkImportModal = (open, id) => ({
    type: MAP_LANDMARKS_EDIT_IMPORT_OPEN_MODAL,
    payload: { open, id },
});