import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

const INIT_STATE = {
    loading: false,
    result: [],
    total: 0,
    status: null,
    limit: 10,
    offset: 0
};

const eta = "realtimeMaps/main/eta";

export const realtimeMapsEtaSearchEtaLinks = createAsyncThunk('realtimeMaps/main/eta/realtimeMapsEtaSearchEtaLinks', async (payload, { rejectWithValue, dispatch }) => {
    try {

        let conditions = [
            {
                fields: "status",
                terms: payload?.status || ["Active"],
                exact: true
            },
            {
                fields: "entityName",
                terms: ["Unit"],
                exact: true
            }
        ]
        if (payload.openSearch) {
            conditions.push(
                {
                    fields: ["unit_label", "code"],
                    terms: payload.openSearch,
                    exact: false
                }
            )
        }
        const response = await clientQuery(
            `
                query searchEtaLinks ($conditions: [EtaLinkSearchInput], $sort: [EtaLinkSortInput], $limit: Int, $offset: Int) {
                    result: searchEtaLinks (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                        items {
                            id
                            companyId
                            code
                            eta
                            unitId
                            address
                            unit {
                                id
                                label
                                timeZone {
                                    id
                                    name
                                }
                            }
                            status
                            address
                            latitude
                            longitude
                            expirationDate
                            sharedWith {
                                contacts
                                emails
                                sms
                            }
                            isKph
                            startDate
                            scheduleSnapshot
                            notes
                        }
                        total
                    }
                }
            `,
            {
                conditions: conditions,
                limit: payload?.limit || 10,
                offset: payload?.offset || 0,
                sort: {
                    "field": "startDate",
                    "order": "DESC"
                }
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { ...response?.result, newPage: payload?.newPage } || { items: [], total: 0 }
    } catch (exc) {
        ExceptionManager(exc, eta, 'realtimeMapsEtaSearchEtaLinks');
        return rejectWithValue(exc)
    }
});

export const RealtimeMapEtaLinkRedux = createSlice({
    name: eta,
    initialState: INIT_STATE,
    reducers: {
        etaReset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(realtimeMapsEtaSearchEtaLinks.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(realtimeMapsEtaSearchEtaLinks.fulfilled, (state, action) => {
            state.result = action?.payload?.newPage ? [...state.result, ...action?.payload?.items] : action?.payload?.items
            state.total = action?.payload?.total
            state.loading = false;
        });
        builder.addCase(realtimeMapsEtaSearchEtaLinks.rejected, (state, action) => {
            state.loading = false;
        });
    }
})

const { actions, reducer } = RealtimeMapEtaLinkRedux;

export const { etaReset } = actions;

export default reducer;