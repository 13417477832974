export default {
  videoMainView_availableTab: 'Disponibles',
  videoMainView_deletedTab: 'Eliminados',
  videoMainView_starredTab: 'Destacados',
  videoMainView_completedTab: 'Completado',
  videoMainView_pendingTab: 'Pendiente',
  videoMainView_failedTab: 'Fallido',
  videoMainView_inProgressTab: 'En progreso',
  videoMainView_clipRequestTab: 'Solicitud de clip',
  videoMainView_eventType: 'Tipo de Evento',
  videoMainView_availableMedia: 'Multimedia disponible',
  videoMainView_events: 'Eventos',
  videoMainView_starred: 'Favoritos',
  videoMainView_deleted: 'Eliminados',
  videoMainView_requested: 'Solicitados',
  videoMainView_all: 'Todos',
  videoMainView_deleteEventSuccess: 'El evento ha sido eliminado con éxito',
  videoMainView_updateEventSuccess: 'El evento ha sido actualizado con éxito',
  videoMainView_deleteEventsSuccess: 'Los eventos han sido eliminados con éxito',
  videoMainView_selectOption: 'Seleccione al menos una opción',
  videoMainView_restoreEventSuccess: 'El evento ha sido restaurado con éxito',
  videoMainView_restoreEventsSuccess: 'Los eventos han sido restaurados con éxito',
  videoMainView_manageCameras: 'Gestionar Cámaras',
  // Event Codes
  // https://gpstrackit.atlassian.net/wiki/spaces/WIKI/pages/645431301/Event-Codes
  videoMainView_drivingHit: 'Parada repentina',
  videoMainView_drivingheavyHit: 'Parada repentina',
  videoMainView_hardAccel: 'Aceleración Rápida',
  videoMainView_hardBrake: 'Freno duro',
  videoMainView_sharpTurn: 'Giro duro',
  videoMainView_harshAccel: 'Aceleración Rápida',
  videoMainView_harshBrake: 'Freno duro',
  videoMainView_harshTurn: 'Giro duro',
  videoMainView_severeAccel: 'Aceleración Rápida',
  videoMainView_severeBrake: 'Freno duro',
  videoMainView_severeTurn: 'Giro duro',
  videoMainView_asleep: 'Dormido',
  videoMainView_drowsy: 'Somnoliento',
  videoMainView_yawn: 'Bostezo',
  videoMainView_noDriver: 'Cámara obstruida',
  videoMainView_dayDreaming: 'Sueño diurno',
  videoMainView_addNote: 'Agregar nota',
  videoMainView_share: 'Compartir',
  videoMainView_delete: 'Eliminar',
  videoMainView_noteAdded: 'Nota guardada con éxito',
  videoMainView_note: "Nota",
  videoMainView_restore: "Restaurar",
  videoMainView_manual: 'Manual',
  videoMainView_usingPhone: "Usando el teléfono",
  videoMainView_distracted: "Distraído",
  videoMainView_smoking: "Fumando",
  videoMainView_noSeatBelt: "Sin cinturón de seguridad",
  videoMainView_addStarred: "Agregar a destacados",
  videoMainView_removeStarred: "Quitar de destacados",
  videoMainView_collisionWarning: "Aviso de colisión",
  videoMainView_closeFollowing: "Aviso de proximidad",
  videoMainView_tailgating: "Emergencia de Proximidad",
  videoMainView_stopSignViolation: "Violación de la señal de alto",
  videoMainView_TitleTimeLine: "Seleccione el videoclip desde la linea de tiempo",
  videoMainView_subtitleTimeLine: 'Arrastra el selector negro a la sección de la línea de tiempo que te interesa y haz clic en "Obtener clip".',
  videoMainView_selectorWarning: "el selector debe de estar en un area con datos",
  videoMainView_snapshots: "Instantáneas",
  videoMainView_videoClips: "Videos",
  videoMainView_mediaTypeBoth: "Ambas",
  videoMainView_noDriver: "Cámara obstruida",
  videoMainView_idleNoDriver: "Inactiva sin conductor",
  videoMainView_requestClip: "Obtener clip",
  videoMainView_laneDeparture: "Salida de carril",
  videoMainView_potentialIncident: "Incidente potencial",
  videoMainView_errorRequestClip: "El video clip seleccionado se encuentra duplicado, seleccione otro rango de tiempo",
  videoMainView_headwayMonitoringWarning: "Seguir de cerca",
  videoMainView_forwardCollisionWarning: "Aviso de colision",
  videoMainView_headwayMonitoringEmergency: "Emergencia de Proximidad",
  videoMainView_NO_SEATBELT: "Sin cinturón de seguridad",
  ideoMainView_noValidTime: "Hora no valida",
  videoMainView_reviewStatus: "Revisar Estado",
  videoMainView_unreviewed: "Sin revisar",
  videoMainView_accepted: "Aceptado",
  videoMainView_disputed: "Disputado",
  videoMainView_reviewAll: "Todos",
  videoMainView_newDriverFaceDetected:"Nueva cara del conductor detectada",

  videoMainView_PostedSpeed:"Limite de velocidad",
  videoMainView_speedThreshold :"Exceso de Velocidad",
  videoMainView_pedestrianDetection:"Detección de peatones",
  videoMainView_cyclistWarning:"Alerta de Ciclista",
  videoMainView_passengerInside:"Detección de Pasajero",
  videoMainView_passengerSeatbelt:"Pasajero sin cinturón",

  videoMainView_AlarmOut: "Alarma Desactivada",
  videoMainView_AlarmIn: "Alarma Activada",
  videoMainView_PanicButton: "Botón de pánico",
  videoMainView_cameraObstructed: "Cámara Obstruida",
  videoMainView_Turn: "Giro fuerte",
  videoMainView_SevereShock: "Choque fuerte",
  videoMainView_Shock: "Choque",
  videoMainView_Brake: "Frenado brusco",
  videoMainView_Accelerate: "Acelerar",
  videoMainView_IgnitionOff: "Encendido Desconectado",
  videoMainView_LocationChanged: "Cambio de ubicación",
  videoMainView_Moving: "En movimiento",
  videoMainView_IgnitionOn: "Encendido Conectado",
  videoMainView_drowsiness: 'Somnoliento',
  videoMainView_hardTurn: 'Giro duro',
  videoMainView_rapidAcceleration: 'Aceleración Rápida',
  videoMainView_mainPowerDisconnect: "Desconectado principal",
  videoMainView_noSeatbelt: "Sin cinturón de seguridad",
  videoMainView_clipPreview: "Vista Previa del Clip",
  videoMainView_fullSegment: "Segmento Completo",
  videoMainView_selectedTime: "Tiempo Seleccionado",
  videoMainView_send_streaming_clip: 'Buscar clip de vista previa',
  videoMainView_deletedBy: "Eliminado por",
  videoMainView_reviewed: "Revisado",
  videoMainView_falsePositive: "Falso Positivo",
  videoMainView_notes: "Notas",
  videoMainView_assign: "Asignar Conductor",
  videoMainView_powerLost: "Desconectado principal",
  videoMainView_sDCardError: "Error de tarjeta SD"
};
