import {
    searchRoutesTrackerRefresh
} from '../../../../../.globals/redux/actions'
import { isValidJson } from 'Core/data/Helpers'

export class ROUTE {
    execute = (payload, state, dispatch) => {

        let data = null;
        if(isValidJson(payload)){
            data = JSON.parse(payload);
        }else{
            data = payload;
        }

        switch (data?.key) {

            case "CREATE": 
                dispatch(searchRoutesTrackerRefresh(true));
                return [false];

            case "UPDATE": 
                dispatch(searchRoutesTrackerRefresh(true));
                return [false];
            
            case "routePoint_arrival":
                dispatch(searchRoutesTrackerRefresh(true));
                return [false];

            case "routePoint_departure":
                dispatch(searchRoutesTrackerRefresh(true));
                return [false]; 

            default:
                return [false]
        }

        
    };
}
  
  