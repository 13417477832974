// Dependencies
import { all } from 'redux-saga/effects';

// Sagas
import coreSagas from 'Core/.globals/redux/sagas';
// import maintenanceSagas from 'Modules/maintenance/.globals/redux/sagas';
import fleet from 'Modules/fleet/.globals/redux/sagas';
import commomSagas from 'Core/common/redux/sagas';
import reportSagas from 'Modules/reports/.globals/redux/sagas';
import alertSagas from 'Modules/alerts/.globals/redux/sagas';
import entitySelectorSaga from 'Modules/.common/components/EntitySelector/redux/sagas';
import realtimeMaps from 'Modules/realtimeMaps/.globals/redux/sagas';
import moduleCommonSagas from 'Modules/.common/.globals/redux/sagas';
import videoSagas from 'Modules/video/.globals/redux/sagas';
import accountSagas from 'Modules/account/.globals/redux/sagas';
import settingsSagas from 'Modules/settings/.globals/redux/sagas';
import dashboardSagas from 'Modules/dashboard/.globals/redux/sagas';
import fsmSagas from 'Modules/fsm/.globals/redux/sagas';

export default function* rootSaga() {
	yield all([
		coreSagas(),
		// maintenanceSagas(),
		fleet(),
		commomSagas(),
		reportSagas(),
		alertSagas(),
		entitySelectorSaga(),
		realtimeMaps(),
		moduleCommonSagas(),
		videoSagas(),
		accountSagas(),
		fsmSagas(),
		settingsSagas(),
		dashboardSagas()
	]);
}
