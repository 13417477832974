import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useParams } from "react-router-dom";
import { TypeLayer, TypeObjectMap } from 'Components/Map/providers/enums'
import MapObject from '../../../components/Map/providers/mapObject';

import { Loading } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useWindowDimensions } from 'Core/hooks';
import { validateJsonObject } from 'Core/data/Helpers';

import { convertSecondsToTime, validateEventColor } from '../../../components/Map/utils';

import Map from '../../../components/Map';

import {
    mapsEtaLinkPublicGet,
    addPointers,
    drawGetDirections,
    setMapRoutesOptions,
    clearRedux
} from "Redux/actions";

import './styles.scss';

let intervalNewData;

const EtaLink = (props) => {
    const { messages } = useIntl();
    const mapRef = useRef();
    const dispatch = useDispatch();

    const { code } = useParams();
    const [formData, setFormData] = useState({});
    const [drawLineRoute, setDrawLineRoute] = useState();
    const [eta, setEta] = useState();

    const { width } = useWindowDimensions();

    //define the default values of the routes.... this values can be changed
    const routeProperties = {
        optimizeWaypoints: true,
        avoidTolls: false,
        avoidHighways: false
    }

    //Get the information at the points saved 
    const {
        pointers,
        directions
    } = useSelector((state) => state.realtimeMapRedux);

    const response = useSelector(state => state.etaLinkRedux.response);
    const error = useSelector(state => state.etaLinkRedux.error);
    const loading = useSelector(state => state.etaLinkRedux.loading);

    useEffect(() => {

        //temporally define the points into the view
        dispatch(mapsEtaLinkPublicGet(code, true))

        //create interval to get information from API
        if (!intervalNewData) {
            intervalNewData = setInterval((codeParam) => {
                mapRef?.current?.showLayer(TypeLayer?.Units, true);
                dispatch(clearRedux({ response: null }))
                dispatch(mapsEtaLinkPublicGet(codeParam))
            }, 15000, code);
        }
    }, [])

    useEffect(() => {
        if (response) {
            if (response?.entityName == "Unit") {

                const startPoint = {
                    latitude: response?.unit?.lastReading?.latitude,
                    longitude: response?.unit?.lastReading?.longitude,
                    driverName: response?.unit?.driverName,
                    label: response?.unit?.label,
                    eventCode: response?.unit?.lastReading?.eventCode,
                    eventName: response?.unit?.lastReading?.eventName,
                    heading: response?.unit?.lastReading?.heading,
                    id: response?.unitId
                };

                const endPoint = {
                    latitude: response?.latitude,
                    longitude: response?.longitude,
                    label: response?.address
                };
                setFormData({ entityName: response?.entityName, startPoint, endPoint, isKph: response.isKph });

            }

            if (response?.entityName == "Route") {

                const vehicle = {
                    latitude: response?.unit?.lastReading?.latitude,
                    longitude: response?.unit?.lastReading?.longitude,
                    driverName: response?.unit?.driverName,
                    label: response?.unit?.label,
                    eventCode: response?.unit?.lastReading?.eventCode,
                    eventName: response?.unit?.lastReading?.eventName,
                    heading: response?.unit?.lastReading?.heading,
                    id: response?.unitId
                };

                setFormData({
                    entityName: response?.entityName,
                    routePoints: response?.routePoints,
                    vehicle,
                    isKph: response.isKph,
                    drawLineRoute: response?.drawLineRoute
                });
            }
        }
    }, [response]);


    useEffect(() => {
        if (error == "NO_DATA") {
            //end the get information
            clearInterval(intervalNewData);
        }
        if (error == "LOAD_FAILED") {
            if (!intervalNewData) {
                dispatch(mapsEtaLinkPublicGet(code))
                intervalNewData = setInterval((codeParam) => {
                    dispatch(mapsEtaLinkPublicGet(codeParam))
                }, 15000, code);
            }
        }
    }, [error]);

    useEffect(() => {
        if (
            formData?.startPoint?.latitude &&
            formData?.startPoint?.longitude &&
            formData?.endPoint?.latitude &&
            formData?.endPoint?.longitude
        ) {
            if (formData?.entityName == "Unit") {
                createRouteOnView(formData?.startPoint, formData?.endPoint);
            }
        }
    }, [
        formData?.startPoint?.latitude,
        formData?.startPoint?.longitude,
        formData?.endPoint?.latitude,
        formData?.endPoint?.longitude
    ]);

    useEffect(() => {
        if (formData?.vehicle?.latitude && formData?.vehicle?.longitude) {
            if (formData?.entityName == "Route") {
                createRouteView(formData);
            }
        }
    }, formData?.vehicle)

    const createRouteOnView = (startPoint, endPoint) => {
        let getOpenInfoWindow = mapRef.current?.getOpenInfoWindow()
        mapRef.current.hideMarkerModal();
        mapRef?.current?.removeLayer(TypeLayer.Units);
        mapRef?.current?.deleteRouteInMap();
        dispatch(drawGetDirections(false));
        dispatch(addPointers([]));

        mapRef?.current?.removeMapObject(startPoint?.id, TypeObjectMap.Marker);

        const tempPointers = [
            {
                id: startPoint?.id,
                lat: parseFloat(startPoint?.latitude || 0),
                lng: parseFloat(startPoint?.longitude || 0),
                label: startPoint?.driverName && startPoint?.driverName != "true" && startPoint?.driverName != "null"
                    ?
                    `${startPoint?.label} (${startPoint?.driverName})` : startPoint?.label,
                heading: startPoint?.heading || 0,
                color: '000',
                eventName: startPoint?.eventName,
                draggable: false,
                updatePointers: true,
                hideMarker: true,
                isUnit: response?.scheduleSnapshot && response?.hasVideo
            },
            {
                id: '2',
                lat: parseFloat(endPoint?.latitude || 0),
                lng: parseFloat(endPoint?.longitude || 0),
                label: endPoint.label || '',
                heading: 0,
                color: '000',
                eventName: 'no event',
                draggable: false,
                updatePointers: true,
                hideMarker: true
            }
        ];

        //save pointers and shows into map
        dispatch(addPointers([...tempPointers]));
        dispatch(setMapRoutesOptions(routeProperties));

        if (response?.scheduleSnapshot && response?.hasVideo) {
            mapRef.current?.updateModalPosition({
                position: {
                    lat: parseFloat(startPoint?.latitude || 0),
                    lng: parseFloat(startPoint?.longitude || 0),
                }
            })
            if (getOpenInfoWindow) {
                setTimeout(() => {
                    mapRef?.current?.showInfoWindow("1");
                }, 500)
            }
        }

    }

    const createRouteView = (data) => {
        const { entityName, routePoints, vehicle, isKph, drawLineRoute : paramDrawLineRoute } = data
        if(!mapRef?.current) return;
        
        dispatch(addPointers([]));

        let points = validateJsonObject(routePoints);
        points = validateJsonObject(points);

        if(!drawLineRoute && paramDrawLineRoute){
            setDrawLineRoute(paramDrawLineRoute);
        }

        let getOpenInfoWindow = mapRef.current?.getOpenInfoWindow()
        mapRef?.current?.hideMarkerModal();
        mapRef?.current?.removeLayer(TypeLayer.Units);
        mapRef?.current?.removeMapObject(vehicle?.id, TypeObjectMap.Marker);

        //set the vehicle value
        const tempPointers = [{
            id: 1,
            lat: parseFloat(vehicle?.latitude || 0),
            lng: parseFloat(vehicle?.longitude || 0),
            label: vehicle?.driverName && vehicle?.driverName != "true" && vehicle?.driverName != "null"
                ?
                `${vehicle?.label} (${vehicle?.driverName})` : vehicle?.label,
            heading: vehicle?.heading || 0,
            color: '000',
            eventName: vehicle?.eventName,
            draggable: false,
            updatePointers: true,
            hideMarker: true,
            isUnit: response?.scheduleSnapshot && response?.hasVideo
        },{
            id: '2',
            lat: parseFloat(vehicle?.latitude || 0),
            lng: parseFloat(vehicle?.longitude || 0),
            label: points[points.length - 1]?.name || '',
            heading: 0,
            color: '000',
            eventName: 'no event',
            draggable: false,
            updatePointers: true,
            hideMarker: true
        }]

        const position = {
            lat: parseFloat(vehicle?.latitude || 0),
            lng: parseFloat(vehicle?.longitude || 0)
        };

        mapRef?.current?.addMapObject(new MapObject().buildMarker({
            id: vehicle?.id,
            deviceId: vehicle?.id,
            position,
            layerName: TypeLayer.Units,
            label: vehicle?.label,
            heading: vehicle?.heading,
            color: validateEventColor(vehicle),
            eventCode: vehicle?.eventCode,
            eventName: vehicle?.eventName,
            draggable: false,
            updatePointers: false,
            hasVideo: false,
            hideMarker: false,
            value: '',
            hasChild: false,
            isRouteTracker: false,
            trail:  null,
            trailTimestamp: 0,
            noClickEvent: true
        }));


        points?.map((element, index) => {
            tempPointers.push({
                ...element,
                id: index + 4,
                latitude: element?.lat,
                longitude: element?.lon,
                lng: element?.lon,
                layerName: TypeLayer?.FSMRoute,
                eventName: 'Stop',
                sequence: index,
                typePoint: 'Stop',
                type: 'RoutePoint',
                noClickEvent: true,
                value: index
            })
        });
        dispatch(addPointers([...tempPointers]));

        if(drawLineRoute || paramDrawLineRoute){
            mapRef?.current?.removeMapObject(`temporaldrawRoute_polyline_${1}`, TypeObjectMap.Polyline);
            mapRef?.current?.addMapObject(new MapObject().buildPolyLine({
                id: `temporaldrawRoute_polyline_${1}`,
                coordinates: drawLineRoute || paramDrawLineRoute,
                layerName: TypeLayer.FSMRoute,
                heading: 0,
                color: formData?.colorRoute || "#66bb6a",
                hideDrawDirection: true,
                strokeOpacity: 0.7,
                strokeWeight: 5,
            }), true, true);
        }

        
        getEtaFromRoute(position, { lat: tempPointers[tempPointers?.length - 1].lat, lng: tempPointers[tempPointers?.length - 1].lng }, paramDrawLineRoute);

        if (response?.scheduleSnapshot && response?.hasVideo) {
            mapRef.current?.updateModalPosition({
                position: {
                    lat: parseFloat(vehicle?.latitude || 0),
                    lng: parseFloat(vehicle?.longitude || 0),
                }
            })
            if (getOpenInfoWindow) {
                setTimeout(() => {
                    mapRef?.current?.showInfoWindow("1");
                }, 500)
            }
        }

    }

    /********************************************************
     * recreate the route in map when have 2 points
     ********************************************************/
    useEffect(() => {
        if (pointers && pointers.length > 1 && response?.entityName != "Route") {
            //delete the old rounte on map
            mapRef?.current?.deleteRouteInMap()
            //create the new pointers of the view
            dispatch(drawGetDirections(true))
        }
    }, [pointers])

    const getEtaFromRoute = (origin, destination, paramDrawLineRoute) => {
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const directionsService = new google.maps.DirectionsService();
        
        directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
            map: mapRef?.current?.mapItem, // Asignar el mapa al DirectionsRenderer
            polylineOptions: {
              strokeColor: "#00ad00",
              strokeOpacity: 0.7,
              strokeWeight: 5
            }
        });
        // Make the petition to directions.
        const request = {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING
        };

        // Get the petitions to directions
        directionsService.route(request, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                // Get the eta for the results
                const valueTime = convertSecondsToTime(result.routes[0].legs[0].duration.value || 0)
                
                const timeFormatToShow = `
                    ${valueTime?.days > 0 ? `${valueTime?.days} ${valueTime?.days == 1 ? messages['Day'] : messages['Days']}` : ''} 
                    ${valueTime?.hour > 0 ? `${valueTime?.hour} ${valueTime?.hour == 1 ? messages['hour'] : messages['hours']}` : ''} 
                    ${valueTime?.min > 0 ? `${valueTime?.min} ${valueTime?.min == 1 ? messages['min'] : messages['mins']}` : ''} `
                let cleanedStr = timeFormatToShow.replace(/\s+/g, ' ').trim();
                setEta(cleanedStr);
                
                if(!drawLineRoute && !paramDrawLineRoute){
                    directionsRenderer?.setMap(null);
                    directionsRenderer?.setDirections(result);
                    directionsRenderer?.setMap(mapRef?.current?.mapItem);
                }
                
            }
        });
    }

    let messagesToMap = new Array();
    if (messages) {
        messagesToMap['kilometers'] = messages['kilometers']
        messagesToMap['miles'] = messages['miles']
        messagesToMap['Day'] = messages['Day']
        messagesToMap['Days'] = messages['Days']
        messagesToMap['hour'] = messages['hour']
        messagesToMap['hours'] = messages['hours']
        messagesToMap['min'] = messages['min']
        messagesToMap['mins'] = messages['mins']
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            {
                loading ?
                    <Loading full /> :
                    <Fragment>
                        {
                            error == "NO_DATA" ?
                                <div className='eta-error-container'>
                                    <div className='eta-error-message'>
                                        Error 400: Content not available
                                    </div>
                                </div> :
                                <Map
                                    ref={mapRef}
                                    pointers={pointers || []}
                                    directions={directions}
                                    noRealtime={true}
                                    isEtaLink={true}
                                    hiddenButton
                                    hiddenLegends={true}
                                    hiddenTraffic={true}
                                    hiddenLayerToggle={true}
                                    hiddenSearchMap
                                    height="100vh"
                                    showEtaLink={true}
                                    isMobile={width <= 767}
                                    messages={messagesToMap}
                                    isKph={formData?.isKph || false}
                                    showInfoWindow
                                    noCenterPoints={mapRef?.current?.getOpenInfoWindow() || false}
                                    etaLabel={eta ? `${messages['etalink_eta']}: ${eta}` : null}
                                />
                        }
                    </Fragment>
            }
        </div>
    )

}

export default EtaLink;