//View Keys
export default {
  maputilities_menutitle: 'Map Utilities',
  maputilities_vehicles: 'Vehicles',
  maputilities_realtimemaps: 'Realtime Maps',
  maputilities_vehicletrails: 'Vehicle Trails',
  maputilities_landmarks: 'Landmarks',
  maputilities_geofences: 'Geofences',
  maputilities_routemarker: 'Routes',
  maputilities_vehicledirections: 'Vehicle Directions',
  maputilities_findnearest: 'Find Nearest',
  maputilities_sendsms: 'Send Messages',
  maputilities_mapsettings: 'Map Settings',
  maputilities_googleearth: 'Google Earth',
  maputilities_video: 'Video',
  maputilities_back: '< Back',
  maputilities_kml: 'Kml Refresh',
  maputilities_fitbounds_vehicles: 'Center on Fleet',
  maputilities_kml_update: "Kmls Updated",
  maputilities_layer: 'Map Layer',
  maputilities_eta: 'ETA',
  maputilities_speedOverride: 'Speed Override',
  maputilities_vicinityTool: 'Vicinity Tool',
  maputilities_vicinityToolUnits: 'Vicinity Tool - Units',
  maputilities_tagsFilterOnMap: 'Tags Filter',
  //----------------------------------------
  //Map Settings
  //Units
  mapsettings_unitlabels: 'Unit Labels',
  mapsettings_unitlabelsOverlap: 'Label Clusters',
  mapsettings_arrowicon: 'Arrow Icon',
  mapsettings_unitclusters: 'Unit Clusters',
  mapsettings_followselectunits: 'Follow Selected Units',
  mapsettings_grouptagsonlabels: 'Group Tags on Label',
  //Landmarks
  mapsettings_landmarks: 'Landmarks',
  mapsettings_landmarkslabels: 'Landmarks Labels',
  mapsettings_singlelandmarkmode: 'Single landmarks Mode',
  mapsettings_ocuppiedlandmarkonly: 'Ocuppied Landmark Only',
  //geofences
  mapsettings_geofences: 'Geofences',
  mapsettings_geofenceslabels: 'Geofences Labels',
  //Zoom
  mapsettings_vehiclezoom: 'Vehicle Zoom',
  mapsettings_trailzoom: 'Trail Zoom',
  mapsettings_geofencezoom: 'Geofence Zoom',
  mapsettings_geofencesvisibleatzoom: 'Geofences Visible at Zoom',
  mapsettings_landmarkzoom: 'Landmark Zoom',
  mapsettings_landmarkmarkersvisibleatzoom: 'Landmark Markers Visible at Zoom',
  mapsettings_landmarkradiusvisibleatzoom: 'Landmark Radius Visible at Zoom',
  mapsettings_selectedUnits: 'Selected Units',
  mapsettings_selectedUnits_map: 'Vehicles in this area',
  mapsettings_currentZoom: 'Current Zoom',
  mapsettings_animationMap: 'Animation',
  mapsettings_animationMap_activated: 'Activated',
  mapsettings_animationMap_deactivated: 'Deactivated',
};