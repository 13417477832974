// Dependencies
import { all } from "redux-saga/effects";
// Views
import crewSaga from "Modules/settings/views/crew/redux/saga";
import driverGroupsSaga from "Modules/settings/views/driverGroups/redux/saga";
import inputLabelsSaga from "Modules/settings/views/inputLabels/redux/saga";
import vehicleStatusSaga from "Modules/settings/views/vehicleStatus/redux/saga";
import driverSaga from "Modules/settings/views/driver/redux/saga";
import vehicleEquipmentSaga from "Modules/settings/views/vehicleEquipment/redux/saga";
import keyFobSaga from "Modules/settings/views/keyFob/redux/saga";
import temperatureSaga from "Modules/settings/views/temperature/redux/saga";
import unitSettingsSaga from "Modules/settings/views/unitSettings/redux/saga";
import fuelCardSaga from "Modules/settings/views/fuelCard/redux/saga";
import editUnitSagas from "Modules/settings/views/unitSettings/components/editUnitSettings/redux/saga";

export default function* rootSaga() {
    yield all([
        crewSaga(),
        driverGroupsSaga(),
        inputLabelsSaga(),
        vehicleEquipmentSaga(),
        vehicleStatusSaga(),
        driverSaga(),
        keyFobSaga(),
        temperatureSaga(),
        unitSettingsSaga(),
        fuelCardSaga(),
        editUnitSagas()
    ]);
}
