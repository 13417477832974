export default {
    fsmRoute_edit_panel_route_savedRoute: "Information stored correctly",
    fsmRoute_edit_panel_route_remove_point: "Remove the incorrect point in order to continue",
    fsmRoute_edit_panel_route_template: "Template",
    fsmRoute_edit_panel_route_driver: "Driver",
    fsmRoute_edit_panel_route_schedule_at: "Scheduled at",
    fsmRoute_edit_panel_route_is_template: "Is Template",
    fsmRoute_edit_panel_route_assigned_route: "Assigned Routes",
    fsmRoute_edit_panel_route_assigned_actions: "Actions",
    fsmRoute_edit_panel_route_assigned_add_stop: "Add Stop",
    fsmRoute_edit_panel_route_close_confirmation_modal_title: "Warning!",
    fsmRoute_edit_panel_route_close_confirmation_modal: "Are you sure you want to close the route editor? Any unsaved changes will be lost",
    fsmRoute_edit_panel_route_accordion_point: "Stop or Waypoint (Add / Edit)",
    fsmRoute_edit_panel_route_accordion_stops: "Route Stops",
    fsmRoute_edit_panel_route_accordion_clear: "Clear",
    fsmRoute_edit_panel_route_info_optimize_route: 'Do you have multiple stops on your route? Our directions system helps you organize them efficiently. The "Optimize Route" button automatically creates your route by reordering the waypoints to minimize the total travel distance and adapt to current traffic conditions.',
    fsmRoute_edit_panel_route_accordion_edit: "Edit",
    fsmRoute_edit_panel_route_accordion_name_mandatory: "The name of Route must be at least 3 characters long",
    fsmRoute_edit_panel_route_close_option_title: "Close the route automatically when:",
    fsmRoute_edit_panel_route_close_option_final_stop: "Vehicle arrives to final stop",
    fsmRoute_edit_panel_route_close_option_final_all_stop_visited: "All stops are visited",
    fsmRoute_edit_panel_route_close_option_final_after: "After",
    fsmRoute_edit_panel_route_close_option_final_after_end: "hours of route start",
    
};