export default {
    entityTagPicker_units: "Units",
    entityTagPicker_drivers: "Drivers",
    entityTagPicker_landmarks: "Landmarks",
    entityTagPicker_geofences: "Geofences",
    entityTagPicker_forms: "Forms",
    entityTagPicker_users: "Users",
    entityTagPicker_all: "All",
    entityTagPicker_allUnit: "All Units",
    entityTagPicker_allDriver: "All Drivers",
    entityTagPicker_allLandmark: "All Landmarks",
    entityTagPicker_allGeofence: "All Geofences",
    entityTagPicker_allForm: "All Forms",
    entityTagPicker_allUser: "All Users",
    entityTagPicker_singleUnit: "Single Units",
    entityTagPicker_singleDriver: "Single Drivers",
    entityTagPicker_singleLandmark: "Single Landmarks",
    entityTagPicker_singleGeofence: "Single Geofences",
    entityTagPicker_singleForm: "Single Forms",
    entityTagPicker_singleUser: "Single Users",
    entityTagPicker_single: "Single",
    entityTagPicker_tags: "Tags",
    entityTagPicker_allUnitSelected: "All units selected",
    entityTagPicker_allDriverSelected: "All drivers selected",
    entityTagPicker_allLandmarkSelected: "All landmarks selected",
    entityTagPicker_allGeofenceSelected: "All geofences selected",
    entityTagPicker_allFormSelected: "All forms selected",
    entityTagPicker_allUserSelected: "All users selected",
    entityTagPicker_searchTag: "Select Tags or Search",
    entityTagPicker_searchUnit: "Select units or search by name",
    entityTagPicker_searchDriver: "Select drivers or search by name",
    entityTagPicker_searchLandmark: "Select landmarks or search by name",
    entityTagPicker_searchGeofence: "Select geofences or search by name",
    entityTagPicker_searchForm: "Select forms or search by name",
    entityTagPicker_searchUser: "Select users or search by name",
    entityTagPicker_noItemsFound: "No items found",
    entityTagPicker_inactive: "Inactive",
    entityTagPicker_active: "Active",
    entityTagPicker_yesOption: "Yes",
    entityTagPicker_notOption: "No",
    entityTagPicker_includeInactiveDrivers: "Include Inactive drivers",
    entityTagPicker_includeUnassignedDrivers: "Include Unassigned drivers",
    entityTagPicker_searchCameras: "Select cameras or search by name",
    entityTagPicker_allSelectedCameras: "All selected cameras",
};
