/********************************************************
 * Third Controls
 ********************************************************/
import React, { useEffect, useState } from 'react'

/********************************************************
 * Icons
 ********************************************************/
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PauseIcon from '@mui/icons-material/Pause';
import { Button } from 'Components';
//import StopIcon from '@mui/icons-material/Stop';
import { Grid } from '@mui/material';

/********************************************************
 * Styles
 ********************************************************/
 import './style.scss' 

/********************************************************
 * Class to show playback controls for vehicle trails elements
 ********************************************************/
const PlaybackBar = (props) => {

  const speeds = props?.speeds || [1, 1.5, 2, 2.5, 3];
  const [currentSpeedIndex, setCurrentSpeedIndex] = useState(0);


  useEffect(()=> {
    const index = speeds.indexOf(props?.speedPlayback || 0)
    setCurrentSpeedIndex(index >= 0 ? index : 0)
  },[props?.speedPlayback])

  /********************************************************
   * Start or stop the play elements
   ********************************************************/
  const onPlay = () => {
    if(props.setPlay){
      props.setPlay(true)
    }
    if(props.onPlay){
      props.onPlay()
    }
  }

  /********************************************************
   * Start or stop the play elements
   ********************************************************/
  const onPause = () => {
    if(props.setPlay){
      props.setPlay(false)
    }
    if(props.onPause){
      props.onPause()
    }
  }
  

  /********************************************************
   * go to previus element
   ********************************************************/
  const onPrevious = () => {
    if(props.onPrevious){
      props.onPrevious()
    }
  }

  /********************************************************
   * go the next element
   ********************************************************/
  const onNext = () => {
    if(props.onNext){
      props.onNext()
    }
  }

  const onChangeSpeed = () => {
    const speedIndex = currentSpeedIndex < speeds.length - 1 ? currentSpeedIndex + 1 : 0;
    setCurrentSpeedIndex(speedIndex);
    props?.onChangeSpeed && props.onChangeSpeed(speeds[speedIndex])
  };

  return(
    <div className='mapPagination-classtotal'>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={3}>
          <SkipPreviousIcon viewBox={'0 0 20 20'} className='mapPagination-icon' onClick={()=> onPrevious()} />
        </Grid>
        <Grid item xs={3}>
        {
          props.play
          ?
          <PauseIcon viewBox={'0 0 20 20'} className='mapPagination-icon' onClick={()=> {
            onPause()
          }} />
          :
          <PlayArrowIcon viewBox={'0 0 20 20'} className='mapPagination-icon' onClick={()=> {
            onPlay()
          }} />
        }
        </Grid>
        <Grid item xs={3}>
          <SkipNextIcon viewBox={'0 0 20 20'} className='mapPagination-icon' onClick={()=> onNext()} />
        </Grid>
        <Grid item xs={3}>  
          <span
            className={'mapPagination-playspeed-circle'}
            onClick={onChangeSpeed}
          >
            {speeds[currentSpeedIndex]}x
          </span>
        </Grid>
      </Grid>
    </div>
  )

}

export default PlaybackBar