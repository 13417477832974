import React, { Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { HasPermission } from 'Core/security';
import './sidebar.scss';
import Icon from '@mui/material/Icon';

import { endpoints, defaultLocale } from 'Core/defaultValues';
import { useSelector, useDispatch } from "react-redux";
import {
	catalogLoad
} from 'Redux/actions';
import { useIntl } from 'react-intl';
import { upperCase } from 'lodash';

const MenuItem = (props) => {
	const { messages } = useIntl()
	return (
		<li className={`${props.id}`}>
			{
				props.to
					?
					<NavLink to={props.to}>
						<Icon>{props.icon}</Icon>
						<div className={`text-menu`}>{props?.messages[props.label] || props.label}</div>
						{props.new && <span className="newLabel">{upperCase(messages['new'])}</span>}
					</NavLink>
					:
					<a
						href={props.url}
						onClick={(event) => {
							if (props.onClick) props.onClick(event);
						}}
						target={`${props.target || '_blank'}`}
						rel="noreferrer"
					>
						<Icon>{props.icon}</Icon>
						<div className={`text-menu`}>{props?.messages[props.label] || props.label}</div>
						{props.new && <span className="newLabel">{upperCase(messages['new'])}</span>}
					</a>
			}
		</li>
	);
};

const MENUS = [
	{ // TODO module-approved
		id: "dashboard",
		label: 'menu_dashboard',
		icon: 'dashboard',
		to: '/app/dashboard/main',
		permission: 'db',
		subuser: [63]
	},
	{ // TODO module-approved
		id: "fleet",
		label: 'menu_fleet',
		icon: 'insights',
		to: '/app/fleet/main',
		permission: 'vstat',
		subuser: [103]
	},
	{
		id: "realtime-maps",
		label: 'menu_realtimeMaps',
		icon: 'location_on',
		to: '/app/realtimemaps/main'
	},
	{ // TODO module-approved
		id: "reports",
		label: 'menu_reports',
		icon: 'equalizer',
		to: '/app/reports/main',
		permission: 'nr',
		subuser: [21]
	},
	{ // TODO module-approved
		id: "video",
		label: 'menu_video',
		icon: 'videocam',
		to: '/app/video',
		permission: 'vidfleetvideo',
		subuser: [217]
	},
	{ // TODO module-approved
		id: "routes",
		label: 'menu_routes',
		icon: 'route',
		to: '/app/routes',
		permission: 'dapprta',
		subuser: [249]
	},
	{ // TODO module-approved
		id: 'manage',
		label: 'menu_manage',
		icon: 'build',
		to: '/app/settings/main',
		permission: 'manage',
		subuser: [23]
	},
	{ // TODO module-approved
		id: 'behavior',
		label: 'menu_behavior',
		icon: 'person',
		to: '/app/behavior',
		permission: 'behavior',
		subuser: [214],
		new: true
	},
	{ // TODO module-approved
		id: 'maintenance',
		label: 'menu_maintenance',
		icon: 'av_timer',
		to: '/app/maintenance/main',
		permission: 'mntnnc-beta',
		subuser: [213]
	},
	{
		id: 'fieldSync',
		label: 'menu_fieldSync',
		icon: 'sync',
		to: '/app/fieldSync',
		permission: 'fldsync',
		subuser: [263],
		new: true
	},
	{ // TODO module-approved
		id: "fsm",
		label: 'menu_FSM',
		icon: 'account_circle',
		to: '/app/fsm/main',
		permission: 'fsm',
		// subuser: [59, 60, 61, 62], // TODO Review permissions
		subuser: [59]
	},
/* 	{ // TODO module-approved
		id: 'legacy-media',
		label: 'menu_media',
		icon: 'videocam',
		to: '/app/legacy/mediaevents',
		permission: 'media',
		subuser: [201],
	}, */
	{ // TODO module-approved
		id: 'promiles',
		label: 'menu_promiles',
		icon: 'network_check',
		url: '',
		permission: 'promiles',
		subuser: [57],
	},
	{ // TODO module-approved
		id: 'eldfleet',
		label: 'menu_eldfleet_eld',
		icon: 'power',
		to: '/app/eldfleet',
		permission: 'eld',
		subuser: [148],
	},
	{ // TODO module-approved
		id: 'apollo-eld',
		label: 'menu_apollo_eld',
		icon: 'power',
		to: '/app/apolloeld',
		permission: 'apolloeld',
		subuser: [228],
	},
	{ // TODO module-approved
		id: 'car-iq',
		label: 'menu_car_iq',
		icon: 'payments',
		to: '/app/cariq',
		permission: 'cariq',
		subuser: [232],
		//new: true
	},
	{ // TODO module-approved
		id: 'monarch-driveri',
		label: 'menu_monarch_driveri',
		icon: 'power',
		to: '/app/monarch/driveri',
		permission: 'mdriveri',
		//subuser: [232],
		//new: true
	},
	{ // TODO module-approved
		id: 'monarch-eld',
		label: 'menu_monarch_eld',
		icon: 'power',
		to: '/app/monarch/eld',
		permission: 'meld',
		//subuser: [232],
		//new: true
	},
	{ // TODO module-approved
		id: 'monarch-classic',
		label: 'menu_monarch_classic',
		icon: 'power',
		to: '/app/monarch/classic',
		permission: 'mclassic',
		//subuser: [232],
		//new: true
	},
	{ // TODO module-approved
		id: 'monarch-ditat',
		label: 'menu_monarch_ditat',
		icon: 'power',
		to: '/app/monarch/ditat',
		permission: 'mditat',
		//subuser: [232],
		//new: true
	},
	{ // TODO module-approved
		id: 'account',
		label: 'menu_account',
		icon: 'manage_accounts',
		to: '/app/account/main',
		permission: 'account',
		subuser: [24]
	},
	{ // TODO module-approved
		id: 'dynamicforms',
		label: 'menu_dynamic_forms',
		icon: 'toc',
		to: '/app/dynamicforms',
		permission: 'dappfrm',
		subuser: [246]
	},
	{ // TODO module-approved
		id: 'help',
		label: 'menu_help',
		icon: 'help',
		//url: endpoints.HELP_URL + defaultLocale + "/",
		url: endpoints.HELP_URL,
		permission: 'help',
		subuser: [104]
	},
	{ // TODO module-approved
		id: 'marketplace',
		label: 'menu_marketplace',
		icon: 'shopping_basket',
		permission: 'marketplace',
		subuser: [216],
		url: endpoints.MARKET_PLACE_LOGIN_URL
	},
	{ // TODO module-approved
		id: 'feedback',
		label: 'menu_feedback',
		icon: 'feedback',
		permission: 'feedback',
		url: "#",
		target: '_self',
		subuser: [220],
		onClick: (event) => {
			pendo?.feedback?.openFeedback(event);
		}
	}
];

const Sidebar = (props) => {

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(catalogLoad(['unitsList']));
	}, []);

	let isDeveloper = props.isDeveloper;
	const { messages } = props;

	let environment;
	if (isDeveloper)
		environment = "developer enabled"

	if (!isDeveloper && process.env.ENVIROMENT && (process.env.ENVIROMENT === "staging")) {
		isDeveloper = true;
		environment = "staging enabled"
	}

	if (!isDeveloper && process.env.ENVIROMENT === "beta") {
		isDeveloper = true;
		environment = ""
	}
	const current_reseller = useSelector(state => state.resellerRedux.current_reseller);

	let newMenus = [...MENUS];
	if (HasPermission(props.user, "realtimemaps")) {
		newMenus = newMenus.filter(m => m.label !== "menu_map");
	}

	return (
		<div className={cx('sidebar', !props.sidebarShow ? 'sidebar-min' : '')}>
			<div className={cx('version', 'sidebar-version-top50')}></div>
			{
				environment &&
				<div className="version">{environment}</div>
			}
			<ul>
				{
					newMenus.map((menu, index) => {
						menu.messages = messages;
						if (menu.label === 'menu_help' && current_reseller) {
							// If the current user is a reseller and have helpUrl, the url for help will be replaced
							if (current_reseller.helpUrl) {
								menu = { ...menu, url: current_reseller.helpUrl };
							} else {
								return;
							}
						}
						else if (menu.label === 'menu_promiles') {
							const promiles = props.user.promilesData ? props.user.promilesData.split(",") : []
							menu = { ...menu, url: endpoints.PROMILES_URL + `/Redirect.aspx?source=GTI&action=startsessionftax&pmo=1&fto=1&userid=${promiles[0]}&password=${promiles[1]}` }
						}
						return (
							<Fragment key={index}>
								{
									((menu.forDev && isDeveloper) || !menu.forDev) &&
									HasPermission(props.user, menu.permission, menu.subuser) &&
									<MenuItem {...menu} />
								}
							</Fragment>
						);
					})
				}



			</ul>
		</div>
	);
};

/**
* Export component
*/
export default Sidebar;
