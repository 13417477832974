/*********************************************************
 * Imports 
 *********************************************************/
import { TypeObjectMap, TypeLayer } from './enums'

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    30 Nov 2020
 * Module:  Realtime Maps
 * 
 * Make the object elements to realtime maps
 ********************************************************/
export default class mapObject {

  /*********************************************************
   * Make the default Json object
   ********************************************************/
  defaultJsonMapObject(){
    return {
      id : -1,
      index: -1,
      type : TypeObjectMap.Default,
      showLabel: false,
      groupId: -1,
      icon : '',
      directional : false,
      radius: 0,
      heading : 0,
      label : '',
      labelDriver: '',
      labelPosition: false,
      labelPositionSpace: null, //[{ pointLatFirst: 0, pointLatSecond: 0, pointLngFirst: 0, pointLngSecond: 0 }],
      labelGroup: null, //ids to label group
      labelGroupIdMain: null, //id main or center unit from group
      labelGroupArea: null,//{ pointLatFirst: 0, pointLatSecond: 0, pointLngFirst: 0, pointLngSecond: 0 },
      labelType: 'off',
      position : {lat: 0, lng: 0},
      startPosition: {lat: 0, lng: 0},
      endPosition: {lat: 0, lng: 0}, //this position is used when the focus is recovered to the window
      coordinates : [], 
      boundsRectangle: { north: 0, south: 0, east: 0, west : 0 },
      minBoundsRectangle: { north: 0, south: 0, east: 0, west : 0 },
      layerName : TypeLayer.Default,
      instance : null,
      mapRoute: null, //instance of mapRoute
      draggable : false,
      updatePointers: false,
      eventName: '', //name of event / in FSMRoutes type of point (Stop or Waypoint)
      color : '#FFFFFF',
      url: '',
      offline: false,
      hasVideo: false,
      hasChild: false,
      childType: TypeObjectMap.Default,
      childInstance: null,
      enable: true,
      hideMarker: false, //enables to draw marker on map
      value: '',
      valueChild: '',
      onCallbackEvent: null,
      fillOpacity: 0.35,
      strokeWeight: 2,
      strokeColor: null,
      saveDragEvent: false,
      dragEvent: null,
      dragEndEvent: null,
      dragStartEvent: null,
      onUpdateList: null,
      freeRectangle: false, //perform the draw square into map
      maxSize: 0, //max Size to Radius of circle or diagonal to square (in metters)
      optimized: true, //the marker in map is optimazed to improved the performance, this options disabled the styles for marker.
      defaultIcon: null, //set the default icon for markers on map
      defaultIconIncrease: null,
      zIndex: 10,
      isRouteTracker: false,
      timeStamp: 0,
      trail: [], //multiples position to the units,
      newEvents: [], //stack for events to reproduce
      originalTrail: [],
      onReproduction: false,
      indexTrail: 0,
      trailTimestamp: 0, 
      isAnimated: false,
      unitTime: 0, 
      stopAnimation: false,
      stopAnimationFunction: null,
      trailPlaybackSpeed: 1,
      followMarker: false,
      noClickEvent: false //disable the click event on marker
    }
  }

  /********************************************************
   * 
   * @param {JSONObject} jsonObject 
   * @param {array} arrayObjects to delete from JSON object
   ********************************************************/
  removeElementsDefaultObject(jsonObject = {}, arrayObjects = []){
    arrayObjects.forEach(function(key){
      delete jsonObject[key];
    });
    return jsonObject;
  }

  /*******************************************************
   * Build the marker object to Maps Controller Class
   * @param {JSON} jsonObject 
   *******************************************************/
  buildMarker(jsonObject){
    try{
      
      let defaultJsonObject = this.defaultJsonMapObject();
      defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius', 'coordinates','hasChild','childType','childInstance','value', 'valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
      jsonObject = {
        ...jsonObject,
        position: { lat: parseFloat(jsonObject.position.lat), lng: parseFloat(jsonObject.position.lng)},
        heading: parseInt(jsonObject.heading)
      }

      return {...defaultJsonObject, type: TypeObjectMap.Marker, ...jsonObject}
    }catch(ex){
      //console.log('mapObject.buildMarker() => ', ex);
    }
  }

  /*******************************************************
   * Build the polygon object to Map Controller Class
   * @param {JSON} jsonObject 
   *******************************************************/
  buildPolygon(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject();
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Polygon, ...jsonObject}
  }

  /*******************************************************
   * Build de polygon circle object
   * @param {JSON} jsonObject 
   *******************************************************/
  buildCircle(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject();
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['coordinates', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Circle, ...jsonObject}
  }

  /*******************************************************
   * Build de line into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
  buildPolyLine(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Polyline, ...jsonObject}
  }

  /*******************************************************
   * Build the Rectangle object to Map Controller Class
   * @param {JSON} jsonObject 
   *******************************************************/
  buildRectangle(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject();
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Rectangle, ...jsonObject}
  }

  /*******************************************************
   * Build the Rectangle object to Map Controller Class
   * @param {JSON} jsonObject 
   *******************************************************/
  buildSquare(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject();
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Square, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
  buildMarkerCluster(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['label', 'directional', 'radius', 'heading', 'position', 'coordinates','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle']);
    return {...defaultJsonObject, type: TypeObjectMap.MarkerCluster, ...jsonObject}
  }

  /*******************************************************
   * Build the marker object to Maps Controller Class
   * @param {JSON} jsonObject 
   *******************************************************/
  buildKml(jsonObject){
    try{
      
      let defaultJsonObject = this.defaultJsonMapObject();
      defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['radius', 'coordinates', 'position', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
      return {...defaultJsonObject, type: TypeObjectMap.KmlFile, ...jsonObject}
    }catch(ex){
      //console.log('mapObject.buildMarker() => ', ex);
    }
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
   buildRoute(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'radius', 'heading', 'coordinates', 'offline','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Routes, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
  buildLabel(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'radius', 'heading', 'coordinates','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle']);
    return {...defaultJsonObject, type: TypeObjectMap.Label, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
  *******************************************************/
  buildLandmark(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'heading','value','valueChild','eventName','url','offline','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Landmark, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
  *******************************************************/
   buildGeofence(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'heading','value','valueChild','eventName','url','offline','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle','labelType']);
    return {...defaultJsonObject, type: TypeObjectMap.Geofence, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster landmark object into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
  buildCluster(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'radius', 'heading', 'coordinates','hasChild','childType','childInstance','value','valueChild','callbackEvent', 'fillOpacity', 'strokeWeight','boundsRectangle']);
    return {...defaultJsonObject, type: TypeObjectMap.Cluster, ...jsonObject}
  }

  /*******************************************************
   * Build de cluster object into the realtime maps
   * @param {JSON} jsonObject 
   *******************************************************/
  buildSettings(jsonObject){
    let defaultJsonObject = this.defaultJsonMapObject()
    defaultJsonObject = this.removeElementsDefaultObject(defaultJsonObject, ['directional', 'radius', 'heading', 'coordinates', 'hasChild', 'childType', 'childInstance', 'url', 'draggable', 'groupId', 'hasVideo', 'icon', 'offline', 'label', 'index', 'eventName','position','layerName', 'color', 'fillOpacity', 'strokeWeight','boundsRectangle']);
    return {...defaultJsonObject, type: TypeObjectMap.Settings, ...jsonObject}
  }
} 