import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    alertEvents: null,
    error: null
};

const route = "settings/views/routes/components/alertsWatchers";


export const routesAlertsGetEvents = createAsyncThunk('routes/routesTab/templates/components/alertWatchers/getAlertType', async (payload, { rejectWithValue, dispatch }) => {
    try {
        
        let data =  await clientQuery(
            `query getAlertType ($code: String) {
                result: getAlertType (code: $code){
                    name
                    code
                    parentId
                }
            }`,
            {
                code: 'RouteWatchers'
            },
            endpoints.GRAPHQL_GENERAL,
        );
        return [ ...data?.result?.filter(item => item?.parentId > 0) ] || null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesAlertsGetEvents');
        return rejectWithValue(exc)
    }
});

export const RoutesTemplatesAlertWatchers = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(routesAlertsGetEvents.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(routesAlertsGetEvents.fulfilled, (state, action) => {
            state.alertEvents = action?.payload;
            state.loading = false;
        });
        builder.addCase(routesAlertsGetEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });        
    }
})

const { actions, reducer } = RoutesTemplatesAlertWatchers;

export const { reset } = actions;

export default reducer;
