//Global keys
let locales = {
	ok: 'Ok',
	close: 'Close',
	add: 'Add',
	cancel: 'Cancel',
	okay: 'Okay',
	print: 'Print',
	continue: 'Continue',
	back: 'Back',
	save: "Save",
	new: "New",
	required_field: 'Required field',
	clearAll: 'Clear All',
	clear: 'Clear',
	selectAnOption: 'Please select an option',
	select: 'Select',
	files: 'Files',
	remove_all: 'Remove all',
	choose: 'Choose',

	all: 'All',
	days: 'Days',
	day: 'Day',
	Days: 'Days',
	Day: 'Day',
	miles: 'Miles',
	kilometers: 'Kilometers',
	feet: 'Feet',
	hours: 'Hours',
	hour: 'Hour',
	Hours: 'Hours',
	Hour: 'Hour',
	min: 'min',
	mins: 'Mins',
	seconds: 'Seconds',
	months: 'Months',
	month: 'Month',
	Months: 'Months',
	Month: 'Month',
	engineHours: 'Engine Hours',
	odometer: 'Odometer',
	select_date: 'Select date',
	select_time: 'Select time',
	etalink_eta: 'Estimated Time of Arrival (ETA)',

	Monday: 'M',
	Tuesday: 'T',
	Wednesday: 'W',
	Thursday: 'Th',
	Friday: 'F',
	Saturday: 'S',
	Sunday: 'Su',

	dtc_codes: 'DTC Codes',
	success: 'Success',

	min: 'Min',
	max: 'Max',
	characters: 'Characters',

	pending: 'Pending',
	due: 'Due',
	dueSoon: 'Due Soon',
	dueNow: 'Due Now',
	pastDue: 'Past Due',
	scheduled: 'Scheduled',
	schedule: 'Schedule',
	reminder: 'Reminder',
	next: 'Next',
	done: "Done",
	edit: "Edit",
	complete: 'Complete',
	update: 'Update',
	delete: 'Delete',
	DELETE: 'DELETE',
	ago: "ago",
	// "1010": "There is already a record with the name entered",
	duplicated_name: 'There is already a record with the name entered',
	invalid_params: 'Invalid Params',
	invalid_params_ftp: 'Error connecting to FTP: Invalid Params',
	exist_ftp: 'The FTP cannot be deleted until those schedules be deleted or updated to use another FTP Server or delivery mode.',
	attachmentsFilesAllowed: 'jpg, png, gif, txt, doc, docx, pdf, csv, xls, xlsx. 4MB',


	context_menu_table: 'Table Columns',
	context_menu_show_all: 'Show All',
	visibility_validation_title: 'Wait!',
	visibility_validation_message: 'you must have at least one column visible!',

	collapse: 'Collapse',
	expand: 'Expand',

	time_today_reported: 'Reported Today',
	time_today_not_reported: 'Not Reported Today',
	time_not_reported_seven_days: 'Not Reported in 7 Days',
	time_not_reported_thirty_days: 'Not Reported in 30 Days',

	//select menu
	select_all: "Select all",
	must_select_at_least_one_option: "Must select at least one option",

	search: 'Search',
	noOptions: 'No options',
	prev: 'Previous',
	nextp: 'Next',
	perPage: 'per page',
	noMoreItems: 'There are no more items.',
	fetching_more_list_items: "loading more list items...",

	//table Pagination
	pag_prev: "Previous",
	pag_next: "Next",
	pag_per_page: "per page",

	//table toolbar
	table_search: "Search",
	table_export_csv: "Export CSV",
	table_export_pdf: "Export PDF",
	table_filter: "Filter",
	table_add: "Add",
	table_delete: "Delete",
	table_refresh: "Refresh",
	table_export: "Export",
	table_visibility: "Visibility",
	table_all: "all",
	table_share: "Share",

	//input search
	Search: "Search",
	Cancel: "Cancel",
	Apply: "Apply",

	yes: "Yes",
	no: "No",

	// Dates
	dates: 'Dates',
	date_today: 'Today',
	date_current: 'Current',
	date_yesterday: 'Yesterday',
	date_this_week: 'This Week',
	date_last_week: 'Last Week',
	date_this_quarter: 'This Quarter',
	date_last_quarter: 'Last Quarter',
	date_custom: 'Custom',
	date_this_month: 'This Month',
	date_last_month: 'Last Month',
	date_last_24hours: 'Last 24 Hours',
	date_last_today: 'Today',
	date_last_yesterday: 'Yesterday',
	date_last_7days: 'Last 7 Days',
	date_last_15days: 'Last 15 Days',
	date_last_30days: 'Last 30 Days',
	date_last_90days: 'Last 90 Days',
	date_last_3months: 'Last 3 Months',
	//for interval pill
	due_every: "Due every",
	reminder_at: "Remind at",
	pastDue_at: "Past due at",
	next_in: "Next in",

	//for dates
	at_time: "at",
	dateAndTime: "Date & Time",
	start: "Start",
	end: "End",
	set: "Set",

	//collapse button select menu
	show_all: "Show all",
	show_less: "Show less",
	collapse_to_ten: "Collapse to ten",

	menu_dashboard: "Dashboard",
	menu_fleet: "Status", //Renamed from "Fleet" temporary
	menu_vehicleStatus: "Vehicle Status",
	menu_map: "Maps",
	menu_realtimeMaps: "Maps",
	menu_reports: "Reports",
	menu_video: "Video",
	menu_delete: "Delete",
	menu_add: "Add",
	menu_manage: "Manage",
	menu_behavior: "Behavior",
	menu_maintenance: "Maintenance",
	menu_fieldSync: "FieldSync",
	menu_routes: "Routes",
	menu_MDT: "MDT",
	menu_FSM: "FSM",
	menu_media: "Media",
	menu_account: "Account",
	menu_preferences: "Preferences",
	menu_contacts: "Contacts",
	menu_help: "Help Center",
	menu_marketplace: "Marketplace",
	menu_driverStatus: "Driver Status",
	menu_advaceRouting: "Advance Routing",
	menu_customers: "Customers",
	menu_rfid: "RFID",
	menu_promiles: "Promiles",
	menu_apollo_eld: "Apollo ELD",
	menu_car_iq: "Car IQ Pay",
	menu_eldfleet_eld: "ELDFleet (Legacy)",
	menu_elogs: "Elogs",
	menu_loginHistory: "Login History",
	menu_accountInformation: "Account Information",
	menu_legacyApiKeys: "Legacy Api Keys",
	menu_apiKeys: "API Keys (Legacy)",
	menu_users: "Users",
	menu_feedback: "Feedback",
	menu_settings: "Settings",
	menu_apiKeys: "API",
	menu_tags: "Tags",
	menu_role: "Roles",
	menu_dataExport: "Data Exports",
	menu_dynamic_forms: "Forms",
	menu_billingPortal: "Billing Portal",

	menu_monarch_driveri: 'Driver I Cameras',
	menu_monarch_eld: 'ELD Premium',
	menu_monarch_classic: 'Classic Platform',
	menu_monarch_ditat: 'DITAT TMS',

	from: "from",
	to: "to",

	FROM: "from",
	TO: "to",

	error: 'Error',
	information: 'Information',
	errorMessage: "An error occurred, please try again.",
	notAvailable: "N/A",

	download: "Download",
	addFavorites: "Add to favorites",
	removeFavorites: "Remove from favorites",
	favorites: "Favorites",
	noItemsFound: "No matches found.",

	unassigned: "Unassigned",

	excellent: "Excellent",
	fair: "Fair",
	good: "Good",
	poor: "Poor",
	unknown: "Unknown",
	offline: "Offline",
	parking: "Parking",
	driving: "Driving",
	idling: "Idling",
	stopped: "Stopped",
	av_clips: "Av Clips",
	no_data: "No Data",
	no_video_av: "No Video Available",
	others: "Other",
	second_clip: "Seconds Clip",

	low: 'Low',
	medium: 'Medium',
	high: 'High',

	at: 'at',

	show: "Show",
	expandAll: "Expand All",
	hide: "Hide",
	documentation: "Documentation",
	draw: 'Draw Control',
	zoomOut: 'Zoom Out',
	zoomIn: 'Zoom In',
	layerControl: 'Layer Control',

	exportCSV: 'Export CSV',
	exportPDF: 'Export PDF',
	export: 'Export',
	downloadExport: 'Download Report',
	changeColumnOrder: 'Change column order',
	typeToSearch: "Type to search",
	address: "Address:",
	unit: "Unit:",
	landmark: "Landmark:",
	geofence: "Geofence:",
	unit_value: "Unit",

	custom: "Custom",

	create: 'Create',
	daily: 'Daily',
	weekly: 'Weekly',
	monthly: 'Monthly',
	assign: "Assign",

	merge: "Merge",
	map_legend: 'Legend',

	//modules
	units: 'Units',
	Unit_Groups: 'Unit groups',
	drivers: 'Drivers',
	driver_groups: 'Driver groups',

	filters: 'Filters',
	apply: 'Apply',
	//map layers
	Hybrid_layer: 'Hybrid',
	Map_layer: 'Map',
	Satellite_layer: 'Satellite',
	Terrain_layer: 'Terrain',

	//map traffic
	map_traffic: 'Traffic',
	map_weather: 'Weather',
	map_radar: 'Radar',
	map_satellite: 'Satellite',
	map_heat_index: 'Heat Index',
	map_forecasted_heat_index: 'Forecasted Heat Index',
	map_humidity: 'Humidity',
	map_surface_analysis: 'Surface Analysis',
	map_drought_monitor: 'Drought Monitor',
	map_forecasted_feels_like_temperature: 'Forecasted Feels Like Temperature',
	map_forecasted_jet_stream: 'Forecasted Jet Stream (250mb)',
	map_forecasted_precipitation: 'Forecasted Precipitation (1 Hour)',
	map_forecasted_precipitation_6: 'Forecasted Precipitation (6 Hour)',
	map_view_detail: 'View Detail...',

	remove: 'Remove',
	untagged: "Untagged",

	on: "On",
	off: "Off",
	email: "Email",
	none: "None",
	selectAll: "Select All",

	exportInProcessTitle: "Exporting...",
	exportInProcessBody: "This could take some time, the file will be downloaded automatically when it is ready",
	exportSuccess: 'The file has been downloaded successfully',

	loading: "Loading...",
	phone: "Phone",
	contacts: "Contacts",
	emailAddress: "Email Address:",
	phoneNumber: "Phone Number:",
	selectContact: "Select a Contact:",
	add_recipient: "Add Recipient",
	required_email: "Please enter a valid email",
	required_phone: "Please enter a valid phone number",
	select_country_code: "Select a Country:",
	enterEmailAddress: "Enter Email Address",
	enterPhoneNumber: "Enter Phone Number",
	OF: 'OF',
	chat: 'Chat',
	troubleshooting: "Troubleshooting",
	supportAgent: "support agent",
	unitOverViewPanel: "Vehicle Overview",
	system: 'System',
	driverID: "Driver ID",
	vehicle: "Vehicle",
	eventID: 'Event ID',
	date: "Date",
	time: "Time",
	notes: "Notes"
};

let videoEvents = {
	'Asleep': 'Asleep',
	'Camera Obstructed': 'Camera Obstructed',
	'Close Following': 'Close Following',
	'Collision Warning': 'Collision Warning',
	'Cyclist Warning': 'Cyclist Warning',
	'Distracted': 'Distracted',
	'Drowsiness': 'Drowsiness',
	'Hard Brake': 'Hard Brake',
	'Hard Turn': 'Hard Turn',
	'Idle No Driver': 'Idle No Driver',
	'Lane Departure': 'Lane Departure',
	'Main Power Disconnect': 'Main Power Disconnect',
	'Manual':'Manual',
	'No Seatbelt': 'No Seatbelt',
	'Passenger Inside': 'Passenger Inside',
	'Passenger Seatbelt': 'Passenger Seatbelt',
	'Pedestrian Detection': 'Pedestrian Detection',
	'Posted Speed': 'Posted Speed',
	'Potential Incident': 'Potential Incident',
	'Rapid Acceleration': 'Rapid Acceleration',
	'Smoking': 'Smoking',
	'Speed Threshold': 'Speed Threshold',
	'Stop Sign Violation': 'Stop Sign Violation',
	'Tailgating': 'Tailgating',
	'Using Phone': 'Using Phone',
	'Yawn': 'Yawn',

	'Parking': 'Parking Hit',
	'AutoSnapshot': 'AutoSnapshot'
}

//Merge Modules
import alerts from 'Modules/alerts/.globals/lan/en';
import behavior from 'Modules/behavior/.globals/lan/en';
import fleet from 'Modules/fleet/.globals/lan/en';
import maintenance from 'Modules/maintenance/.globals/lan/en';
import reports from 'Modules/reports/.globals/lan/en';
import security from 'Core/.globals/lan/en';
import realtimeMaps from 'Modules/realtimeMaps/.globals/lan/en';
import common from 'Modules/.common/.globals/lan/en';
import video from 'Modules/video/.globals/lan/en';
import account from 'Modules/account/.globals/lan/en';
import settings from 'Modules/settings/.globals/lan/en';
import dashboard from 'Modules/dashboard/.globals/lan/en';
import fsm from 'Modules/fsm/.globals/lan/en';
import { gtc_locales_en } from "@gpstrackit/gtc-react-controls"
import dynamicForms from 'Modules/dynamicForms/.globals/lan/en';
import chat from '../../../modules/chat/.globals/lan/en';
import routes from '../../../modules/routes/.globals/lan/en';
import incidents from 'Modules/incidents/.globals/lan/en'

locales = {
	...alerts,
	...behavior,
	...fleet,
	...locales,
	...maintenance,
	...reports,
	...security,
	...realtimeMaps,
	...common,
	...video,
	...account,
	...fsm,
	...settings,
	...dashboard,
	...gtc_locales_en,
	...dynamicForms,
	...chat,
	...routes,
	...incidents,
	...videoEvents
};

export default locales;
