import routesTrackerModalFiltersLanguage from '../components/modalFilters/lan/es';
import routesTrackerModalStopInformationLanguage from '../components/modalStopInformation/lan/es';
import routesTrackerModalStopsVisitedLanguage from '../components/modalStopsVisited/lan/es';
import routesTrackerModalAddRoutesTrackerLanguage from '../components//modalAddRoutesTracker/lan/es';

export default {
    ...routesTrackerModalFiltersLanguage,
    ...routesTrackerModalStopInformationLanguage,
    ...routesTrackerModalStopsVisitedLanguage,
    ...routesTrackerModalAddRoutesTrackerLanguage,

    fsmRoute_routesTracker_name: "Nombre de Ruta",   
    fsmRoute_routesTracker_driver: "Conductor",   
    fsmRoute_routesTracker_schedule_at: "Programada en",   
    fsmRoute_routesTracker_stops_visited: "Paradas visitadas",   
    fsmRoute_routesTracker_stops_detail: "Detalle de paradas",  
    fsmRoute_routesTracker_stops_status: "Estado", 
    fsmRoute_routesTracker_stops_status_pending: "Pendiente",   
    fsmRoute_routesTracker_stops_status_inProgress: "En Progreso",   
    fsmRoute_routesTracker_stops_status_done: "Realizada",   
    fsmRoute_routesTracker_stops_status_close: "Cerrada",  
    fsmRoute_routesTracker_stops_status_unassigned: "Sin Asignar",
    fsmRoute_routesTracker_stops_status_vehicle: "Unidad"
};