import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {clientQuery} from '../../../../../core/data/GraphQLApi';
import {endpoints} from '../../../../../core/defaultValues';
import {ExceptionManager} from '../../../../../core/logManager';
import {resetALL} from '../../../../../.globals/redux/actions';
import {refactorDataMetrics} from "Modules/behavior/views/report/redux/helpers";
import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    items: [],
    total: 0,
    services: [],
    reportResult: null,
    error: null,
};

const route = 'modules/behavior/views/report/redux/slice.js'

export const searchUnitBehaviors = createAsyncThunk('report/behavior/searchUnitBehaviors', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    let sortBy = 'name';
    if (payload.sort?.key === 'totalSafetyEvents'){
        sortBy = 'total';
    } else if (payload.sort?.key === 'scores'){
        sortBy = 'score';
    }
    try {
        let query = `
        query searchUnitBehaviors ($openSearch: String, $entities: [EntityInput]!, $startDate: String, $endDate: String, $filterBy:enumDailyBehaviorFilter!, $sort: BehaviorSortInput,  $risk: enumBehaviorRisk, $scores: [Int], $limit: Int, $offset: Int) {
            units: searchUnitBehaviors (openSearch: $openSearch, entities: $entities, startDate: $startDate, endDate: $endDate, filterBy: $filterBy,  sort: $sort,  risk: $risk, scores: $scores, limit: $limit, offset: $offset) {
                items {
                    entity {
                      name
                      id
                      label
                    }
                    name
                    safetyEvents
                    score
                    risk
                    metrics {
                        metric
                        metricValue
                        events
                        category
                    }
                    metricTotal{
                        category
                        total
                    }
                }
                total
            }
        }`;
        const data = await clientQuery(
            query,
            {
                openSearch: payload.openSearch,
                entities: payload.entities || [],
                startDate: moment(payload.dateRange.start).format('YYYY-MM-DD'),
                endDate: moment(payload.dateRange.end).format('YYYY-MM-DD'),
                filterBy: payload.entity,
                risk: payload.risk === 'All' ? null : payload.risk,
                scores: payload.scores || [],
                limit: payload.limit,
                sort: payload.sort ? {field:sortBy,order:payload.sort?.order.toUpperCase()} : {field:"name",order:"ASC"},
                offset: payload.offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        const refactorMetrics = refactorDataMetrics(data.units.items, false);
        return {items: refactorMetrics, total: data.units.total};
    } catch (exc) {
        ExceptionManager(exc, route, 'searchUnitBehaviors');
        return rejectWithValue(exc)
    }
});

const reportSearchUnitBehaviorsRedux = createSlice({
    name: 'report/behavior',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? {...state, ...action.payload} : INIT_STATE
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(searchUnitBehaviors.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(searchUnitBehaviors.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload?.items || []
            state.total = action.payload?.total || 0
        });
        builder.addCase(searchUnitBehaviors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = reportSearchUnitBehaviorsRedux;

// Extract and export each action creator by name
export const {reset} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
