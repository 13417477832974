export default {
    fsmRoute_routesTracker_modalAdd_title: "Schedule a Route",    
    fsmRoute_routesTracker_modalAdd_close: "Close",  
    fsmRoute_routesTracker_modalAdd_useTemplate: "Use a Template Route",  
    fsmRoute_routesTracker_modalAdd_template: "Template",  
    fsmRoute_routesTracker_modalAdd_select_template: "Select Template",  
    fsmRoute_routesTracker_modalAdd_select_driver_select: "Select Driver", 
    fsmRoute_routesTracker_modalAdd_select_driver: "Driver", 
    fsmRoute_routesTracker_modalAdd_select_date: "Select Date", 
    fsmRoute_routesTracker_modalAdd_next: "Next", 
};