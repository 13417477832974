//View Keys
export default {
  
    //UnitForm
    "unit_edit_unit":"EDIT UNIT",
    "unit_label":"UNIT LABEL",
    "unit_label_placeholder":"UNIT",

    "unit_odometer":"ODOMETER",
    "unit_odometer_placeholder":"odometer",
    "unit_odometer_dash_reading_label":"DASH READING",
    "unit_odometer_dash_reading_placeholder":"dash reading",
    "unit_odometer_offset_label":"OFFSET",
    "unit_odometer_offset_placeholder":"273.4",

    "unit_engine_hours":"ENGINE HOURS",
    "unit_engine_hours_placeholder":"engine hours",
    "unit_engine_hours_dash_reading_label":"DASH READING",
    "unit_engine_hours_dash_reading_placeholder":"dash reading",
    "unit_engine_hours_offset_label":"OFFSET",
    "unit_engine_hours_offset_placeholder":"engine hours",

    "unit_speed":"SPEED",
    "unit_speed_placeholder":"speed",
    
    "unit_consistent_speed":"Consistent Speed",

    "unit_driver":"DRIVER",
    "unit_select_placeholder":"Select",

    "unit_group":"GROUP",
    "unit_general":"GENERAL",
    "unit_description":"DESCRIPTION",
    "unit_vin":"VIN",

    "unit_location":"LOCATION",
    "unit_time_zone":"TIMEZONE",

    "unit_year":"YEAR",
    "unit_engine_liters":"ENGINE LITERS",
    "unit_engine_liters_placeholder":"engine liters",

    "unit_mpg":"MPG",

    "unit_highway":"HIGHWAY",
    "unit_highway_placeholder":"highway",
    "unit_city":"CITY",
    "unit_city_placeholder":"city",


    "unit_notes":"NOTES",
    "unit_input_labels":"INPUT LABELS",
    "unit_input_label":"INPUT LABEL",

    "unit_output_labels":"OUTPUT LABELS",
    "unit_output_label":"OUTPUT LABEL",

    "unit_on_label":"On Label",
    "unit_off_label":"Off Label",

    "unit_on_label_placeholder":"On",
    "unit_off_label_placeholder":"Off",

    "unit_make":"MAKE",
    "unit_make_new":"Add New Make",
    "unit_model":"MODEL",
    "unit_model_new":"Add New Model",

    "unit_inputs_alert_message":" This input is already set ",
    "unit_inputs_alert_title":" Wait",

    "unit_validation_alert_message":" There are validations errors that you must check before to continue ",
    "unit_validation_alert_title":" Validation Error ",

    "unit_validation_succes_message":" Good! data updated ",
    "unit_validation_success_title":" Success ",

    "unit_vehicle_Status_icon": "VEHICLE STATUS ICON",


    "unit_data":"DATA",
    "unit_disable_all":"Disable All",
    "unit_enable_all":"Enable All",

    "unit_calculated_by_ecu":"Calculated by ECU",
    "unit_calculated_by_gps":"Calculated by GPS",

    "unit_actual":"ACTUAL",
    "unit_output_alerts": "Alerts",

    "unit_output_low_battery_alert": "Low Battery Alert",
    "unit_output_motion": "Motion",
    "unit_output_no_gps": "No GPS",
    "unit_output_rapid_acceleration": "Rapid Acceleration",
    "unit_output_hard_brake": "Hard Brake",
    "unit_output_sudden_stop": "Sudden Stop",
    "unit_output_hard_turn": "Hard Turn",
    "unit_output_tow": "Tow",
    "unit_output_consistent_speed": "Consistent Speed",
    "unit_output_consistent_speed_duration": "Trigger alert when speed is 4 minutes",
    "unit_output_trigger_alert_when": "Trigger Alert When...",
    "unit_output_within": "within",

    "unit_time": "time",
    "unit_times": "times",
    "unit_day": "day",
    "unit_days": "days",

    "unit_vbusAlerts": "OBDII Alerts",
    "unit_absActiveLamp": "ABS Active Lamp",
    "unit_absIndicator": "ABS Indicator",
    "unit_airbagIndicator": "Air Bag Indicator",
    "unit_brakeIndicator": "Brake Indicator",
    "unit_oilPressure": "Oil Pressure",
    "unit_coolantHotLight": "Coolant Hot Light",
    "unit_cruiseControl": "Cruise Control",
    "unit_engineCode": "Engine Code",
    "unit_milStatus": "MIL Status",
    "unit_ptoStatus": "PTO Status",

    "unit_batteryVoltage": "Battery Voltage <=",
    "unit_voltage": "Voltage",
    "unit_coolantTemp": "Coolant Temp °F",
    "unit_engineSpeed": "Engine Speed >=",
    "unit_fuelLevel": "Fuel Level <=",
    "unit_fuelRemaining": "Fuel Remaining <=",
    "unit_fuelRate": "Fuel Rate >=",
    "unit_alertOdometer": "Odometer",
    "unit_throttlePosition": "Throttle Position >=",
    "unit_seatbeltFastened": "Seatbelt Fastened",
    "unit_brakeSwitchStatus": "Brake Switch Status",
    "unit_ignitionStatus": "Ignition Status",
    "unit_seatbeltFastenedAlert": "Seatbelt Fastened (Speed >=)",
    "unit_alertContacts": "Alert Contacts",
    "unit_tripOdometer": "Trip Odometer",
    "unit_tripFuel": "Trip Fuel Consump",
    "unit_vehicleSpeed": "Vehicle Speed >=",

    "unit_rapidAccelerationHelpText": `Threshold: 5.6 MPH/s, Trigger: 1.5s - 3.0s, Rapid Acceleration is detected based on a vehicle accelerating in excess of a threshold. Acceleration is expressed as the increase in speed in Miles Per Hour (MPH) over a period of one second.`,
    "unit_hardBrakeHelpText": `Threshold: 4 MPH/s, Trigger: 1.5s - 3.0s, Hard braking is detected based on a vehicle slowing in excess of a threshold. Deceleration is expressed as the decrease in speed in Miles Per Hour (MPH) over a period of one second.`,
    "unit_suddenStopHelpText": `Threshold: 9g, Trigger: 1.5s - 3.0s, Sudden Stop measures the force exerted, in units of Gravity (Gs). The threshold is set to a multiple of the force of gravity, felt as weight on the human body.`,
    "unit_hardTurnHelpText": `Threshold: 4 MPH/s, Trigger: 1.5s - 3.0s, A hard turn is detected using the direction of travel. It measures deviations, measured in centimeters, from this direction over short periods of time (Fractions of a second).`,
    "unit_coolantTempHelpText": `Trigger alert when temperature is at or above...`,
    "unit_alertOdometerHelpText": `Alert triggered when the Odometer meets or exceeds this value`,
    "unit_diagnosticAlertsHelpText": `*Applicable devices include L2000V (with OBDII dongle), G3000, and CP4000. These devices also require additional programming.`,
    "unit_tripOdometerHelpText": "Alert triggered when the Trip Odometer meets or exceeds this value during a given Trip",

    "unit_jbusAlerts": "Heavy Duty DTC",
    "unit_jbusDtc": "DTC",
    "unit_jbusBatteryVoltage": "Battery Voltage <",
    "unit_jbusVoltage": "Voltage",
    "unit_jbusEngineCrankCasePressure": "Eng Crankcase Pressure >= (PSI)",
    "unit_jbusEngineCrankCasePressureHelpText": "Trigger alert if pressure is at or above",
    "unit_jbusOdometer": "Odometer > (Miles)",
    "unit_jbusOdometerHelpText": "Trigger alert when odometer exceeds",
    "unit_jbusRpm": "Engine RPM >=",
    "unit_jbusRpmHelpText": "Trigger alert when rpm is at or exceeds",
    "unit_jbusNotSet": "Not Set",
    "unit_jbusOk": "Ok",
    "unit_jbusNotBucklet": "Not Buckled",
    "unit_jbusError": "Error",
    "unit_jbusOff": "Off",
    "unit_jbusFlashing": "Flashing",
    "unit_jbusOn": "On",
    "unit_jbusSeatbeltSwitch": "Seatbelt Switch",
    "unit_jbusHazard": "Hazard",
    "unit_jbusHazardHelpText": "Trigger alert when status is",
    "unit_jbusGeneral": "General",
    "unit_jbusPedals": "Pedals",
    "unit_jbusAcceleratorPedalPosition": "Accel Pedal Position >=",
    "unit_jbusAcceleratorPedalPositionHelpText": "Trigger alert if pedal position is at or exceeds",
    "unit_jbusBrakePedalPosition": "Brake Pedal Position >=",
    "unit_unit_jbusBrakePedalPositionHelpText": "Trigger alert when pedal position is at or exceeds",

    "unit_jbusBrakes": "Brakes",
    "unit_jbusBrakeSystemSwitchStatus": "Brake System Switch Status",
    "unit_jbusServiceBrakeSwitchStatus": "Service Brake Switch Status",
    "unit_jbusParkingBrakeSwitch": "Parking Brake Switch",
    "unit_jbusActive": "Active",
    "unit_jbusInactive": "Inactive",

    "unit_jbusCoolant": "Engine Coolant",
    "unit_jbusEngineCoolantLevel": "Level <=",
    "unit_jbusEngineCoolantPressure": "Pressure (PSI) >=",
    "unit_jbusEngineCoolantTemp": "Temperature (F) >=",

    "unit_jbusOil": "Engine Oil",
    "unit_jbusEngineOilLevel": "Level <=",
    "unit_jbusEngineOilPressure": "Pressure (PSI) >=",
    "unit_jbusEngineOilTemp": "Temperature (F) >=",

    "unit_jbusLampIndicators": "Lamp Indicators",
    "unit_jbusAmberWarningLamp": "Amber warning lamp",
    "unit_jbusFlashAmberWarningLamp": "Flash amber warning lamp",
    "unit_jbusFlashMalfunctionIndicatorLamp": "Flash malfunction indicator lamp",
    "unit_jbusFlashProtectLamp": "Flash protect lamp",
    "unit_jbusFlashRedStopLamp": "Flash red stop lamp",
    "unit_jbusProtectLampStatus": "Protect lamp status",
    "unit_jbusRedStopLampStatus": "Red stop lamp status",

    "unit_jbusFuel": "Engine Fuel",
    "unit_jbusEnginefuelrate": "Fuel Rate >",
    "unit_jbusFuelLevelOne": "Fuel Level 1 <=",
    "unit_jbusFuelLevelTwo": "Fuel Level 2 <=",

    "unit_jbusAlertContacts": "Alert Contacts",

    "unit_apu": "APU Controls",
    "unit_apuPingsRemaining": "Pings Remaining",
    "unit_apuStarterControl": "Starter Control",
    "unit_apuEnableStarter": "Enable Starter",
    "unit_apuDisableStarter": "Disable Starter",
    "unit_apuPingUnit": "Ping Unit",
    "unit_apuSendPing": "Send Ping",

    "unit_apuPingSuccess": "Ping sent successfully",
    "unit_apuStarterSuccess": "Command sent successfully",
    "unit_unitAlerts": "Unit Alerts",
    "unit_subscribers": "Subscribers",
    "unit_contacts": "Contacts",
    "unit_driverBehavior": "Driver Behavior",

    "unit_diagnosticsAlerts": "Light Duty DTC",

    "unit_settings_belongs_tags": "This unit belongs to the following tags: ",

    "unit_settings_decode_vin_error": "No information found for this VIN",
    "unit_settings_decode_vin": "Decode VIN",
    "unit_settings_start_troubleshooting": "Start Troubleshooting",
};