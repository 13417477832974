
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
  loadingLocations: false,
  locationsData: [],
  error: null
}

const route = "modules/settings/views/locations/redux/slice";

export const settingsSearchLocations = createAsyncThunk("settings/views/locations/search", async ({ conditions, sort, limit, offset }, { rejectWithValue, dispatch }) => {

    try {
      let response = await clientQuery(
        `
               query searchLocations ($conditions: [LocationSearchInput], $sort: [LocationSortInput], $limit: Int, $offset: Int) {
                  searchLocations (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                      items {
                          id
                          title
                          unitsCount
                          enabledELDRule
                          address {
                                address
                                city
                                formatted
                                state
                                zip
                          }
                          latitude
                          longitude
                          timezone
                      }
                      total
                  }
              }
            `,
        {
          conditions,
          sort,
          limit,
          offset
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return response.searchLocations
    } catch (exc) {
      // ALWAYS RETURN ACTION
      ExceptionManager(exc, route, 'settingsSearchLocations');
      return rejectWithValue(exc);
    }
  }
)

export const settingsCreateLocations = createAsyncThunk('settings/views/locations/create', async ({
        title,
        enabledELDRule,
        address,
        latitude,
        longitude,
        tagIds
      }, { rejectWithValue, dispatch }) => {
  try {
    const response = await clientMutation(
      `
             mutation createLocation ($title: String!, $enabledELDRule: Boolean, $address: AddressInput, $latitude: Float, $longitude: Float, $tagIds: [Int]) {
                createLocation (title: $title, enabledELDRule: $enabledELDRule, address: $address, latitude: $latitude, longitude: $longitude, tagIds: $tagIds) {
                    id
                    title
                    unitsCount
                    enabledELDRule
                    address {
                        address
                        city
                        formatted
                        state
                        zip
                    }
                    latitude
                    longitude
                    timezone
                    tagIds
                }
            }
            `,
      {
        title,
        enabledELDRule,
        address,
        latitude,
        longitude
      },
      endpoints.GRAPHQL_GENERAL_V2
    );

    return response?.data || false
  } catch (exc) {
    ExceptionManager(exc, route, 'settingsCreateLocations');
    return rejectWithValue(exc)
  }
});

export const settingsEditLocations = createAsyncThunk('settings/views/locations/edit', async ({ id, title,  enabledELDRule, address, latitude, longitude, tagIds}, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
        `
                     mutation updateLocation ($id: Int!, $title: String, $enabledELDRule: Boolean, $address: AddressInput, $latitude: Float, $longitude: Float, $tagIds: [Int]) {
                    updateLocation (id: $id, title: $title, enabledELDRule: $enabledELDRule, address: $address, latitude: $latitude, longitude: $longitude, tagIds: $tagIds) {
                        id
                        title
                        unitsCount
                        enabledELDRule
                        address {
                            address
                            city
                            formatted
                            state
                            zip
                        }
                        latitude
                        longitude
                        timezone
                        tagIds
                    }
                }
                `,
        {
            id,
            title,
            enabledELDRule,
            address,
            latitude,
            longitude
        },
        endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.updateLocation || false
    } catch (exc) {
        ExceptionManager(exc, route, 'settingsEditLocations');
        return rejectWithValue(exc)
    }
});

export const settingsDeleteLocations = createAsyncThunk('settings/views/locations/delete', async ({ ids, moveChildsToLocationId = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
        `
                     mutation deleteLocations ($ids: [Int]!, $moveChildsToLocationId: Int!) {
                        deleteLocations (ids: $ids, moveChildsToLocationId: $moveChildsToLocationId)
                      }
                `,
        {
            ids,
            moveChildsToLocationId
        },
        endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.data || false
    } catch (exc) {
        ExceptionManager(exc, route, 'settingsDeleteLocations');
        return rejectWithValue(exc)
    }
});

export const settingsLocationsRedux = createSlice({
  name: 'settings/views/locations',
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
    }
  },

  extraReducers: (builder) => {

    // SEARCH LOCATIONS
    builder.addCase(settingsSearchLocations.pending, (state, action) => {
        state.loadingLocations = true;
    });

    builder.addCase(settingsSearchLocations.fulfilled, (state, action) => {
     
      const { isBySelect } = action.meta.arg;
       isBySelect? state.locationsDataSelect = action.payload : state.locationsData = action.payload;
      state.loadingLocations = false;
      state.error = null;
    });

    builder.addCase(settingsSearchLocations.rejected, (state, action) => {
        state.loadingLocations = false;
        state.error = action.payload;
    });

    // CREATE LOCATIONS
    builder.addCase(settingsCreateLocations.pending, (state, action) => {
        state.loadingNewState = true;
      state.error = null;
    });

    builder.addCase(settingsCreateLocations.fulfilled, (state, action) => {
        state.loadingNewState = false;
        state.locationsData = action.payload;
        state.error = null;
    });

    builder.addCase(settingsCreateLocations.rejected, (state, action) => {
        state.loadingNewState = false;
        state.error = action.payload
    });

    // EDIT LOCATIONS
    builder.addCase(settingsEditLocations.pending, (state, action) => {
      state.loadingNewState = true;
      state.error = null;
    });

    builder.addCase(settingsEditLocations.fulfilled, (state, action) => {
      state.loadingNewState = false;
      state.locationEditData = action.payload;
       state.error = null;
    });

    builder.addCase(settingsEditLocations.rejected, (state, action) => {
      state.loadingNewState = false;
        state.error = action.payload;
    });

    // DELETE LOCATIONS
    builder.addCase(settingsDeleteLocations.pending, (state, action) => {
        state.loadingNewState = true;
        state.error = null;
    });

    builder.addCase(settingsDeleteLocations.fulfilled, (state, action) => {
        state.loadingNewState = false;
        state.correctDeleteLocation = action.payload;
        state.error = null;
    });

    builder.addCase(settingsDeleteLocations.rejected, (state, action) => {
        state.loadingNewState = false;
        state.error = action.payload
    });
  },
})

const { actions, reducer } = settingsLocationsRedux;

// Extract and export each action creator by name
export const {
  reset,
} = actions;

export default reducer;

