export default {
    routes_create_edit_point: "Punto",
    routes_create_edit_point_name: "Nombre",
    routes_create_edit_point_notes: "Notas",
    routes_create_edit_point_new: "Punto de ruta adicionado correctamente",
    routes_create_edit_point_new_stop_button: "Adicionar Parada",
    routes_create_edit_point_update_stop_button: "Actualizar Parada",
    routes_create_edit_point_new_waypoing_button: "Adicionar Referencia",
    routes_create_edit_point_update_waypoing_button: "Actualizar Referencia",
    routes_create_edit_point_option_middle: "Medio",
    routes_create_edit_point_edit_stop: "Editar Parada Stop",
    routes_create_edit_point_edit_waypoint: "Editar Punto de Referencia",
    routes_create_edit_point_edit_typepoint: "Tipo de punto",
    routes_create_edit_point_edit_stop: "Parada",
    routes_create_edit_point_edit_waypoint: "Punto de Referencia",
    routes_create_edit_point_button_update: "Actualizar",
    routes_create_edit_point_button_add: "Agregar",
    routes_create_edit_max_points: "No se puede adicionar mas de {points} puntos de ruta.",
};