export default {
    "cameraSetup_on_full": "On",
    "cameraSetup_off_full": "Off",
    "cameraSetup_trigger": "Trigger",
    "cameraSetup_warning": "Warning",
    "cameraSetup_audio": "Audio",
    "cameraSetup_collision_warning": "Collision Warning",
    "cameraSetup_tailgating": "Tailgating",
    "cameraSetup_lane_departure": "Lane Departure",
    "cameraSetup_stop_sign_violation": "Stop Sign Violation",
    "cameraSetup_using_phone": "Using Phone",
    "cameraSetup_smoking": "Smoking",
    "cameraSetup_drowsy": "Drowsy",
    "cameraSetup_no_seatbelt": "No Seatbelt",
    "cameraSetup_daydreaming": "Daydreaming",
    "cameraSetup_yawn": "Yawn",
    "cameraSetup_asleep": "Asleep",
    "cameraSetup_distracted": "Distracted",
    "cameraSetup_no_driver": "No Driver",
    "cameraSetup_idle_no_driver": "Idle No Driver",
    "cameraSetup_rapid_acceleration": "Rapid Acceleration",
    "cameraSetup_hard_brake": "Hard Brake",
    "cameraSetup_hard_turn": "Hard Turn",
    "cameraSetup_power_off": "Power Off",
    "cameraSetup_heavy_duty": "Heavy Duty",
    "cameraSetup_light_truck": "Light Truck (pickup, SUV)",
    "cameraSetup_sedan": "Sedan",
    "cameraSetup_normal": "Normal",
    "cameraSetup_upside_down": "Upside Down",
    "cameraSetup_rotate_right": "Rotate Right",
    "cameraSetup_rotate_left": "Rotate Left",
    "cameraSetup_vehicle_class": "Vehicle Class",
    "cameraSetup_driver_facing_camera": "Driver Facing Camera",
    "cameraSetup_driver_id": "Driver ID",
    "cameraSetup_passenger_detection": "Passenger Detection",
    "cameraSetup_camera_orientation": "Camera Orientation",
    "cameraSetup_aux_one_orientation": "Aux 1 Orientation",
    "cameraSetup_aux_two_orientation": "Aux 2 Orientation",
    "cameraSetup_volume": "Volume",
    "cameraSetup_on": "On",
    "cameraSetup_off": "Off",
    "cameraSetup_are_you_sure_want_to_apply": "Are you sure want to apply these changes",
    "cameraSetup_clicking_apply": "Clicking Apply will send a configuration update to the select camera(s). This update may not be accepted by the camera until a trip is completed",
    "cameraSetup_the_following_changes": "The following changes will be applied",
    "cameraSetup_normal": "Normal",
    "cameraSetup_upsidedown": "Upsidedown",
    "cameraSetup_rotate_right": "Rotate Right",
    "cameraSetup_rotate_left": "Rotate Left",
    "cameraSetup_general": "General",
    "cameraSetup_events": "Events",
    "cameraSetup_adas": "ADAS",
    "cameraSetup_dms": "DMS",
    "cameraSetup_camera_setup": "Camera Setup",
    "cameraSetup_success_messages": "The camera settings have been updated",
    "cameraSetup_error_messages": "Unable to update camera settings",
    "cameraSetup_count": "Count",
    "cameraSetup_trigger_description": "Enables or disables event logging",
    "cameraSetup_warning_description": "Enables or disables camera warnings, grouped by the number of registered events",
    "cameraSetup_audio_description": "Enables or disables event sounds in the cabin",
    "cameraSetup_dms_off": "DMS is off, contact support",
    "cameraSetup_adas_off": "ADAS is off, contact support",
    "cameraSetup_bulk_settings": "Bulk Settings",
    "cameraSetup_does_not_support": "Not Supported",
    "cameraSetup_no_data": "No configuration available for this camera.",
    "cameraSetup_cameras": "Cameras",
    "cameraSetup_all_cameras": "All Cameras",
    "cameraSetup_deviceModel": "Device Model",
    "cameraSetup_config": "Config",
    "cameraSetup_summary": "Summary",
    "cameraSetup_select_device_model": "Select the device model of the devices to be configured",
    "cameraSetup_no_camera_available": "No cameras available for the selected tags",
    "cameraSetup_tags": "Tags",
    "cameraSetup_pedestrian_collision": "Pedestrian Collision",
    "cameraSetup_cyclist_collision": "Cyclist Collision",
    "cameraSetup_dms_lite": "DMS LITE",
    "cameraSetup_passengers_no_seatbelt": "Passengers No Seatbelt",
    "cameraSetup_requires_dms_camera": "Requires DMS+ camera",
}