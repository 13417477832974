import React from "react";
import VidFleetVideo from "Modules/.common/components/VidFleetVideo";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import SmartWitnessVideo from "Modules/.common/components/SmartWitnessVideo";

export const LiveVideo = () => {
  const { messages } = useIntl();
  const { isOpenOverViewUnit } = useSelector((state) => state.incidentsRedux);

  const unit = isOpenOverViewUnit?.data?.unit;
  const lastReading = isOpenOverViewUnit?.data?.reading;
  //unit.linkedUnit.manufacturerName=="SmartWitness"
  
  return (
    <div className="incident_liveVideo_map_liveVideo">
      {unit?.linkedUnit?.manufacturerName == "SmartWitness" ? (
        <SmartWitnessVideo
          recorderId={unit?.linkedUnit?.esn}
          deviceId={unit?.linkedUnit?.unit?.id}
          cameraChannel={
            unit?.linkedUnit?.cameraChannel || "*"
          }
          messages={messages}
          timeZone={unit?.timeZone?.name}
          vehicleTrail={unit?.linkedUnit?.vehicleTrail}
        />
      ) : (
        <VidFleetVideo
          messages={messages}
          mode="video"
          type={unit?.liveMode ? unit?.liveMode?.toLowerCase() : "streaming"}
          id={unit?.linkedUnit?.esn}
          unitId={unit?.linkedUnit?.id}
          autoplay={false}
          deviceModel={unit?.linkedUnit?.unitVersionProduct === "360" ? "360" : "4k"}
          simProviderName={unit?.simProviderName}
          vehicleEvent={lastReading?.eventName}
          timeZone={unit?.timeZone?.name}
        />
      )}
    </div>
  );
};
