export default {
    /*
     * Columns
     */
    behavior_driver: 'Driver',
    behavior_vehicle: 'Vehicle',
    behavior_riskFactors: 'Risk Factors',
    behavior_riskFactorsPoints: 'Risk Factors (points)',
    behavior_category: 'Category',
    behavior_metric: 'Metric',
    behavior_warningPoints: 'Warning (points)',
    behavior_violationPoints: 'Violation (points)',
    behavior_totalSafetyEvents: 'Total Safety Events',
    behavior_total: 'Total',
    /*
     * Metric Types
     */

    behavior_aggression: 'Aggression',
    behavior_HardBrake: 'Hard Brake',
    behavior_HardTurn: 'Hard Turn',
    behavior_RapidAcceleration: 'Rapid Acceleration',
    behavior_PotentialIncident: 'Potential Incident',
    behavior_driverMonitoringSystem: 'Driver Monitoring System (DMS)',
    behavior_dms: '(DMS)',
    behavior_UsingPhone: 'Using Phone',
    behavior_Smoking: 'Smoking',
    behavior_Drowsiness: 'Drowsiness',
    behavior_NoSeatbelt: 'No Seatbelt',
    behavior_DayDreaming: 'Day Dreaming',
    behavior_Yawn: 'Yawn',
    behavior_Asleep: 'Asleep',
    behavior_Distracted: 'Distracted',
    behavior_CameraObstructed: 'Camera Obstructed',
    behavior_IdleNoDriver: 'Idle No Driver',
    behavior_PassengersDetection: 'Passengers Detection',
    behavior_advancedDriverAssistanceSystem: 'Advanced Driver Assistance System (ADAS)',
    behavior_adas: '(ADAS)',
    behavior_ForwardCollisionWarning: 'Forward Collision Warning',
    behavior_CloseFollowing: 'Close Following',
    behavior_Tailgating: 'Tailgating',
    behavior_LaneDeparture: 'Lane Departure',
    behavior_StopSignViolation: 'Stop Sign Violation',
    behavior_vehicleSpeed: 'Vehicle Speed',
    behavior_speedLabel: 'Speed > ',
    behavior_speedingOverPosted: 'Speeding Over Posted',
    behavior_speedOverLow: '1-5 (Low)',
    behavior_speedOverMedium: '6-10 (Medium)',
    behavior_speedOverHigh: '11+ (High)',

    behavior_today: 'Today',
    behavior_yesterday: 'Yesterday',
    behavior_week: 'This Week',
    behavior_lastWeek: 'Last Week',
    behavior_thisMonth: 'This Month',
    behavior_lastMonth: 'Last Month',
    behavior_quarter: 'This Quarter',
    behavior_lastQuarter: 'Last Quarter',
    behavior_custom: 'Custom',
    behavior_last7days: 'Last 7 Days',
    behavior_last15days: 'Last 15 Days',
    behavior_last30Days: 'Last 30 Days',

    behavior_dateToday: 'Today',
    behavior_dateYesterday: 'Yesterday',
    behavior_dateThisWeek: 'This Week',
    behavior_dateLastToday: 'Last Week',
    behavior_dateThisQuarter: 'This Quarter',
    behavior_dateLastQuarter: 'Last Quarter',
    behavior_dateCustom: 'Custom',
    behavior_dateThisMonth: 'This Month',
    behavior_dateLastMonth: 'Last Month',
    behavior_dateLast7days: 'Last 7 Days',
    behavior_dateLast15days: 'Last 15 Days',
    behavior_dateLast30Days: 'Last 30 Days',

    behavior_filterBy: 'Filter By:',
    behavior_timeFrame: 'Timeframe',
    behavior_singleUnits: 'Units',
    behavior_drivers: 'Drivers',
}
