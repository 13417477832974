import {
  getRouteCoordinatesFromDirectionService, returnOptimizeRoutePoints
} from '../../../components/Map/utils' // 'Components/Map/utils'

import { TypeObjectMap, TypeLayer } from './enums'
import MapObject from './mapObject';
let isHandlingError = false;
class MapRoute {
    constructor(props) {
      const { 
        mapItem, 
        propsMap, 
        directionsRenderer, 
        points, 
        errorDrawRoute, 
        otherProps,
        request,
        googleProviderReference,
        noDrawMap
      } = props;

      // Constructor: set the value at the properties on class.
      this.mapReference = mapItem;
      this.propsMap = propsMap;
      this.directionsRenderer = directionsRenderer;
      this.points = points;
      this.errorDrawRoute = errorDrawRoute;
      this.otherProps = otherProps;
      this.request = request;
      this.colorRoute = request?.colorRoute;
      this.googleProviderReference = googleProviderReference;
      this.noDrawMap = noDrawMap || false;
      this.encodedPath = null;

      
      this.drawRouteCallback = this.drawRouteCallback.bind(this);
      //this.onEventClickOnMap = this.onEventClickOnMap.bind(this);

    }
  
    //Get the info for routes values
    drawRoute() {

      var directionsService = new google.maps.DirectionsService(); // eslint-disable-line no-undef

      //delete the routeModule property before the get info for service
      delete this.request['routeModule'];
      delete this.request['colorRoute'];
      

      directionsService.route(this.request, this.drawRouteCallback);

      //request.routes.legs[0].start_location



    }

    drawRouteCallback(response, status) {
      switch (status) {
        case 'OK':
    
            // Configurar las opciones del DirectionsRenderer
            this.directionsRenderer.setOptions({
              suppressMarkers: true,
              preserveViewport: true,
              map: this.mapReference, // Asignar el mapa al DirectionsRenderer
              polylineOptions: {
                strokeColor: this.colorRoute || "#FF0000",
                strokeOpacity: 0.7,
                strokeWeight: 5
              }
            });

            if(!this?.noDrawMap){
              //Draw the route
              this.DrawRouteOnMap(response)
            }

            this.GetDistance(response)
  
          
          break;
  
        case 'ZERO_RESULTS':
          
          if (this?.errorDrawRoute && !isHandlingError) {
            
            isHandlingError = true;
            this.errorDrawRoute(this.propsMap, 'realtimemaps_route_error');

            setTimeout(() => {
              isHandlingError = false;
            }, 1000);
          }
          break;
      }
    }
  
    //Draw the route on map
    DrawRouteOnMap(response) {

      // get coordinates from the route
      if(response.routes && response.routes.length > 0){

        var totalDistance = 0;
        response?.routes[0]?.legs?.forEach(step => {
          totalDistance += step.distance.value;
        });
        let toKilometers = (totalDistance / 1000).toFixed(1);
        var route = response.routes[0];
        this.encodedPath = route.overview_polyline;
      }


      if(this?.otherProps?.drawLineRoute){

        this.googleProviderReference?.current?.removeMapObject(`temporaldrawRoute_polyline_${1}`, TypeObjectMap.Polyline);
        this.googleProviderReference?.current?.addMapObject(new MapObject().buildPolyLine({
          id: `temporaldrawRoute_polyline_${1}`,
          coordinates: this?.otherProps?.drawLineRoute,
          layerName: TypeLayer.FSMRoute,
          heading: 0,
          color: this.colorRoute || "#FF0000",
          hideDrawDirection: true,
          strokeOpacity: 0.7,
          strokeWeight: 5,
        }), true, true);
        this.directionsRenderer?.setMap(null);
        this.directionsRenderer?.setDirections(response);
      }
      else {
        // Assing the response to the map (DirectionsRenderer)
        this.directionsRenderer?.setMap(null);
        this.directionsRenderer?.setDirections(response);
        this.directionsRenderer?.setMap(this.mapReference);
      }

      if(this.otherProps?.showDirectionsPanel){
        //Enable Print elements in UI
        const panelContainer = document?.getElementById('modalDirectionsOnNewRoutes');
        if (panelContainer) {
          panelContainer.innerHTML = "";
          this.directionsRenderer?.setPanel(panelContainer);
        }  
      }else{
        const panelToPrint = document.getElementById('directionsPanelToPrint');
        if(panelToPrint){
          panelToPrint.innerHTML = "";
          this.directionsRenderer?.setPanel(panelToPrint);
        }
      }

      //callback function
      this?.otherProps?.callbackWayPoints && returnOptimizeRoutePoints(response, this?.otherProps?.callbackWayPoints)

      //update external direction renderer
      this?.otherProps?.opUpdateDirectionRenderer && this.otherProps.opUpdateDirectionRenderer(this.directionsRenderer)
    }

    GetDistance(response){
      if(response?.routes?.[0]?.legs?.length > 0){
        const distanceMeters = response.routes[0].legs.reduce((total, leg) => total + leg?.distance?.value || 0, 0);
        const etaRouteDurationValue = response.routes[0]?.legs?.reduce((sum, leg) => sum + (leg?.duration?.value || 0), 0);

        const points = [];
        response.routes[0].legs.forEach(element => {
          points.push({
            distance: element.distance,
            duration: element.duration
          })
        });
        this?.otherProps?.callbackDistancePoints && this?.otherProps?.callbackDistancePoints({
          distanceMeters: distanceMeters,
          etaRouteDurationValue: etaRouteDurationValue,
          points, 
          encodedPath: this.encodedPath
        })
      }
    }

    removeRoute(){
      if(this.directionsRenderer){
        this.directionsRenderer?.setMap(null);
      }
    }

  }

  export default MapRoute;