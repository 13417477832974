export default {
    fsmRoute_alertWatchers_route_recipients: "Recipients",
    fsmRoute_alertWatchers_route_recipientscopy: "Copy ETA Recipients",
    fsmRoute_alertWatchers_route_contactMethod: "Choose Contact Method:",
    fsmRoute_alertWatchers_route_copy: "Copy",
    fsmRoute_alertWatchers_route_info: "This option allows recipients to be notified about route events such as route deviations, stop visits, and other differences configured in the alerts module.",
    fsmRoute_alertWatchers_route_alert: "Activate Alerts",
    fsmRoute_alertWatchers_route_alert_info: "When you enable this feature, alerts will be sent according to the type of event that is configured.",
    fsmRoute_alertWatchers_route_events: "Events",
    fsmRoute_alertWatchers_route_events_select: "Select Events",
    fsmRoute_alertWatchers_route_events_RouteDeviation: "Route Deviation",
    fsmRoute_alertWatchers_route_events_StartRoute: "Start Route",
    fsmRoute_alertWatchers_route_events_EndRoute: "End Route",
    fsmRoute_alertWatchers_route_events_noevent: "Unidentified event",
};