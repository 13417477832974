import createEditRouteLanguage from '../components/createEditRouteModalMain/lan/es'
import createEditRoutePointLanguage from '../components/createEditRoutePoint/lan/es'
import modalMarkerRouteLanguage from '../components/modalMarkerRoute/lan/es'
import modalDeleteRoutePointLanguage from '../components/deleteRoutePoint/lan/es'
import editPanelRouteLanguague from '../components/editPanelRoute/lan/es'
import modalCloneRouteLanguague from '../components/modalCloneRoute/lan/es'
import alertWatcher from '../components/alertWatchers/lan/es'
import etaRoutes from '../components/etaRoutes/lan/es'

export default {

    ...createEditRouteLanguage,
    ...createEditRoutePointLanguage,
    ...modalMarkerRouteLanguage,
    ...modalDeleteRoutePointLanguage,
    ...editPanelRouteLanguague,
    ...modalCloneRouteLanguague,
    ...alertWatcher,
    ...etaRoutes,

    fsmRoute_title: "Rutas",
    fsmRoute_title_schedule: "Programar una Ruta",
    fsmRoute_nameRoute: "Nombre",
    fsmRoute_tracker: "Seguimiento",
    fsmRoute_color: "Color",
    fsmRoute_start: "Inicio",
    fsmRoute_end: "Fin",
    fsmRoute_stop: "Parada",
    fsmRoute_stops: "Paradas",
    fsmRoute_upload_route: "Cargar Rutas",
    fsmRoute_updated_by: "Actualizado Por",
    fsmRoute_updated_on: "Fecha Actualización",
    fsmRoute_create_route: "Crear Ruta",
    fsmRoute_label_type: "Tipo",
    fsmRoute_edit_route: "Editar Ruta",
    fsmRoute_label_stop: "Parada",
    fsmRoute_label_address: "Dirección",
    fsmRoute_label_radius: "Radio",
    fsmRoute_label_display: "Enfoque Vista",
    fsmRoute_label_notes: "Notas",
    fsmRoute_label_close: "Cerrar",
    fsmRoute_label_add_stop: "Adicionar Parada",
    fsmRoute_label_add_stoporwaypoint: "Adicionar Parada o Punto de Ruta",
    fsmRoute_label_add_waypoint: "Adicionar Punto de Ruta",
    fsmRoute_label_add_clone_route: "Clonar Ruta",
    fsmRoute_label_add_clone_route_success: "La ruta se clono correctamente",
    fsmRoute_label_add_route_save: "La ruta se almaceno correctamente",
    fsmRoute_label_route_directions_title: "Indicaciones de Ruta",
    fsmRoute_label_route_directions: "Indicaciones de Ruta",
    fsmRoute_label_delete_all_stop: "Borrar todos los puntos",

};