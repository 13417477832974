import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    loadingCamerasDefaultConfigure: false,
    camerasDefaultConfigure: null,
    error: false,
    fleetDefaultConfigure: null,
    loadingFleetDefaultConfigure: false,
    supportedPropertiesCombinations: null,
    loadingSupportedPropertiesCombinations: false,
    cameraConfiguration: null,
    loadingCameraConfiguration: false,
    open: false,
    units: [],
    loadingUpdate: false,
    update: null,
    errorUpdate: null,
    bulk: false,
    deviceModels: [],
    loadingDeviceModels: false
};

const route = "modules/.common/components/CameraSetup/redux/slice";

const getAvailableDeviceModels = (cameras) => {
    const VIDFLEET = "VIDFLEET";

    const deviceModelMap = new Map();

    cameras.forEach(camera => {
        const { deviceModel } = camera || {};
        const { name, manufacturer } = deviceModel || {};

        if (name && manufacturer?.name?.toUpperCase() === VIDFLEET && !deviceModelMap.has(name)) {
            deviceModelMap.set(name, {
                id: name,
                label: name
            });
        }
    });

    return Array.from(deviceModelMap.values());
};

export const cameraSetupGetCamerasDefaultConfigure = createAsyncThunk("cameraSetup/getCamerasDefaultConfigure", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query {
                data: getCamerasDefaultConfigure
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response?.data) {
            let settings = JSON.parse(response?.data)
            return settings?.settings
        }
        return null

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraSetupGetCamerasDefaultConfigure');
        return rejectWithValue(exc);
    }
})

export const cameraSetupGetFleetDefaultConfigure = createAsyncThunk("cameraSetup/getFleetDefaultConfigure", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query {
                data: getFleetDefaultConfigure
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response?.data) {
            let settings = JSON.parse(response?.data)
            return settings
        }
        return null

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraSetupGetFleetDefaultConfigure');
        return rejectWithValue(exc);
    }
})

export const cameraSetupGetSupportedPropertiesCombinations = createAsyncThunk("cameraSetup/getSupportedPropertiesCombinations", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query {
                data: getSupportedPropertiesCombinations
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response?.data) {
            let settings = JSON.parse(response?.data)
            return settings
        }
        return null

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraSetupGetSupportedPropertiesCombinations');
        return rejectWithValue(exc);
    }
})

export const cameraSetupGetCameraConfiguration = createAsyncThunk("cameraSetup/getCameraConfiguration", async (payload, { rejectWithValue, dispatch }) => {
    const { esn } = payload
    try {
        let response = await clientQuery(
            `
            query getCameraConfiguration($esn: String!) {
                data: getCameraConfiguration(esn: $esn)
            }
            `,
            {
                esn
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response?.data) {
            let settings = JSON.parse(response?.data)
            return settings
        }
        return { noData: true }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraSetupGetCameraConfiguration');
        return rejectWithValue(exc);
    }
})

export const cameraAccountCameraTypes = createAsyncThunk("cameraSetup/cameraAccountCameraTypes", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
                query searchUnits ($conditions: [UnitSearchInput]) {
                    cameras: searchUnits (conditions: $conditions) {
                        items {
                            id
                            label
                            esn
                            deviceModel {
                                name
                                manufacturer {
                                    name
                                }
                            }
                        }
                        total
                    }
                }
            `,
            {
                conditions: [
                    {
                        "fields": ["deviceModel_manufacturer_name"],
                        "terms": ["VidFleet", "SmartWitness",],
                        "exact": true
                    },
                    {
                        "fields": ["linkedMode"],
                        "terms": ["1", "0"],
                        "exact": true
                    }
                ]
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (response.cameras?.items) {
            let currentDevicesModels = getAvailableDeviceModels(response.cameras.items)
            return currentDevicesModels
        }
        return []

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraAccountCameraTypes');
        return rejectWithValue(exc);
    }
})

export const cameraSetupUpdateVidFleetAddConfig = createAsyncThunk("cameraSetup/updateVidFleetAddConfig", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `mutation ($settings: AWSJSON!, $unitIds: [Int]!, $deviceModelName: String) {
                data: updateVidFleetAddConfig(settings: $settings, unitIds: $unitIds, deviceModelName: $deviceModelName)
            }`,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (response.data) {
            let data = JSON.parse(response.data)
            if (data?.settings) {
                return { update: true }
            } else {
                return { update: false }
            }
        } else {
            return { update: false }
        }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'cameraSetupUpdateVidFleetAddConfig');
        return rejectWithValue(exc);
    }
}
)

export const cameraSetupRedux = createSlice({
    name: "cameraSetup/config",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalCameraSetup(state, action) {
            return {
                ...state,
                open: action?.payload?.open,
                units: action?.payload?.units,
                bulk: action?.payload?.bulk || false
            }
        },
        cameraSetupUpdateUnits(state, action) {
            return {
                ...state,
                units: action?.payload
            }
        }
    },
    extraReducers: (builder) => {

        //cameraSetupGetCamerasDefaultConfigure
        builder.addCase(cameraSetupGetCamerasDefaultConfigure.pending, (state, action) => {
            state.loadingCamerasDefaultConfigure = true
        });

        builder.addCase(cameraSetupGetCamerasDefaultConfigure.fulfilled, (state, action) => {
            state.loadingCamerasDefaultConfigure = false;
            state.camerasDefaultConfigure = action.payload;
        });

        builder.addCase(cameraSetupGetCamerasDefaultConfigure.rejected, (state, action) => {
            state.loadingCamerasDefaultConfigure = false;
            state.error = true
        });

        //cameraSetupGetFleetDefaultConfigure
        builder.addCase(cameraSetupGetFleetDefaultConfigure.pending, (state, action) => {
            state.loadingFleetDefaultConfigure = true
        });

        builder.addCase(cameraSetupGetFleetDefaultConfigure.fulfilled, (state, action) => {
            state.loadingFleetDefaultConfigure = false;
            state.fleetDefaultConfigure = action.payload;
        });

        builder.addCase(cameraSetupGetFleetDefaultConfigure.rejected, (state, action) => {
            state.loadingFleetDefaultConfigure = false;
            state.error = true
        });

        //cameraSetupGetSupportedPropertiesCombinations
        builder.addCase(cameraSetupGetSupportedPropertiesCombinations.pending, (state, action) => {
            state.loadingSupportedPropertiesCombinations = true
        });

        builder.addCase(cameraSetupGetSupportedPropertiesCombinations.fulfilled, (state, action) => {
            state.loadingSupportedPropertiesCombinations = false;
            state.supportedPropertiesCombinations = action.payload;
        });

        builder.addCase(cameraSetupGetSupportedPropertiesCombinations.rejected, (state, action) => {
            state.loadingSupportedPropertiesCombinations = false;
            state.error = true
        });

        //cameraSetupGetCameraConfiguration
        builder.addCase(cameraSetupGetCameraConfiguration.pending, (state, action) => {
            state.loadingCameraConfiguration = true
        });

        builder.addCase(cameraSetupGetCameraConfiguration.fulfilled, (state, action) => {
            state.loadingCameraConfiguration = false;
            state.cameraConfiguration = action.payload;

        });

        builder.addCase(cameraSetupGetCameraConfiguration.rejected, (state, action) => {
            state.loadingCameraConfiguration = false;
            state.error = true
        });

        //cameraSetupUpdateVidFleetAddConfig
        builder.addCase(cameraSetupUpdateVidFleetAddConfig.pending, (state, action) => {
            state.loadingUpdate = true
        });

        builder.addCase(cameraSetupUpdateVidFleetAddConfig.fulfilled, (state, action) => {
            state.loadingUpdate = false;
            state.result = action.payload;
        });

        builder.addCase(cameraSetupUpdateVidFleetAddConfig.rejected, (state, action) => {
            state.loadingUpdate = false;
            state.errorUpdate = true
        });

        //cameraAccountCameraTypes
        builder.addCase(cameraAccountCameraTypes.pending, (state, action) => {
            state.loadingDeviceModels = true
        });

        builder.addCase(cameraAccountCameraTypes.fulfilled, (state, action) => {
            state.loadingDeviceModels = false;
            state.deviceModels = action.payload;
        });

        builder.addCase(cameraAccountCameraTypes.rejected, (state, action) => {
            state.loadingDeviceModels = false;
        });
    }
})

const { actions, reducer } = cameraSetupRedux;

// Extract and export each action creator by name
export const {
    openModalCameraSetup,
    cameraSetupUpdateUnits,
    reset
} = actions;

export default reducer;