import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    ladingDeleteEta: false,
    routeEtaLink: null,
    error: null
};

const route = "settings/views/routes/components/etaRoutes";


export const routesEtaCreateUpdateEtaLink = createAsyncThunk('routes/routesTab/templates/components/etaRoutes/routesEtaCreateUpdateEtaLink', async (payload, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation createEtaLink (
                $unitId: Int!,
                $entityId: Int!
                $entityName: String!
                $landmarkId: Int,
                $address: String!,
                $latitude: Float!,
                $longitude: Float!,
                $sharedWith: SharedWithInput!
                $eta: String
                $scheduleSnapshot: Boolean
                $dateRange: AWSDateTimeRange!
                $notes: String
            ) {
                etaLink: createEtaLink(
                    unitId: $unitId,
                    entityId: $entityId, 
                    entityName: $entityName,
                    landmarkId: $landmarkId,
                    address: $address,
                    latitude: $latitude,
                    longitude: $longitude,
                    sharedWith: $sharedWith
                    eta: $eta
                    scheduleSnapshot: $scheduleSnapshot
                    dateRange: $dateRange
                    notes: $notes
                ) {
                    id
                    code
                    status
                }
            }
        `;

        if(payload?.id){
            query = `
                mutation updateEtaLink (
                    $id: Int!
                    $unitId: Int!,
                    $landmarkId: Int,
                    $address: String!,
                    $latitude: Float!,
                    $longitude: Float!,
                    $sharedWith: SharedWithInput!
                    $eta: String
                    $scheduleSnapshot: Boolean
                    $dateRange: AWSDateTimeRange!
                    $notes: String
                ) {
                    etaLink: updateEtaLink(
                        id: $id,
                        unitId: $unitId, 
                        landmarkId: $landmarkId,
                        address: $address,
                        latitude: $latitude,
                        longitude: $longitude,
                        sharedWith: $sharedWith
                        eta: $eta
                        scheduleSnapshot: $scheduleSnapshot
                        dateRange: $dateRange
                        notes: $notes
                    ) {
                        id
                        companyId
                        code
                        eta
                        unitId
                        address
                        unit {
                            id
                            label
                        }
                        status
                        address
                        latitude
                        longitude
                        expirationDate
                        sharedWith {
                            contacts
                            emails
                            sms
                        }
                        isKph
                        startDate
                        scheduleSnapshot
                    }
                }
            `;
        }
        
        const response = await clientMutation(
            query,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.etaLink

    } catch (exc) {
        ExceptionManager(exc, route, 'routesEtaCreateUpdateEtaLink');
        return rejectWithValue(exc)
    }
});


export const routesEtaSearchEtaLinks = createAsyncThunk('routes/routesTab/templates/components/etaRoutes/routesEtaSearchEtaLinks', async (payload, { rejectWithValue, dispatch }) => {
    try {
        let conditions = [
            {
                fields: ["entityName"],
                terms: ["Route"],
                exact: true
            },
            {
                fields: ["entityId"],
                terms: [payload?.routeId],
                exact: true
            } 
        ]
        
        const response = await clientQuery(
            `
                query searchEtaLinks ($conditions: [EtaLinkSearchInput], $sort: [EtaLinkSortInput], $limit: Int, $offset: Int) {
                    result: searchEtaLinks (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                        items {
                            id
                            companyId
                            code
                            eta
                            address
                            status
                            address
                            latitude
                            longitude
                            expirationDate
                            sharedWith {
                                contacts
                                emails
                                sms
                            }
                            isKph
                            startDate
                            scheduleSnapshot
                            notes
                            entityId
                            entityName
                        }
                        total
                    }
                }
            `,
            {
                conditions: conditions,
                limit: payload?.limit || 10,
                offset: payload?.offset || 0,
                sort: [{
                    "field": "startDate",
                    "order": "DESC"
                }]
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { ...response?.result, newPage: payload?.newPage } || { items: [], total: 0 }
    } catch (exc) {
        ExceptionManager(exc, route, 'routesEtaSearchEtaLinks');
        return rejectWithValue(exc)
    }
});

export const routesEtaDeleteEtaLink = createAsyncThunk('routes/routesTab/templates/components/etaRoutes/routesEtaDeleteEtaLink', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { id } = payload
        let query = `
            mutation ($id: Int!){
              etaLink: deleteEtaLink(id: $id)
            }
        `;
        
        const response = await clientMutation(
            query,
            { id: id },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.etaLink

    } catch (exc) {
        ExceptionManager(exc, route, 'routesEtaCreateUpdateEtaLink');
        return rejectWithValue(exc)
    }
});


export const RoutesManagerEditPanelRouteEtaRoutesRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(routesEtaCreateUpdateEtaLink.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(routesEtaCreateUpdateEtaLink.fulfilled, (state, action) => {
            state.routeEtaLink = action?.payload;
            state.loading = false;
        });
        builder.addCase(routesEtaCreateUpdateEtaLink.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(routesEtaSearchEtaLinks.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(routesEtaSearchEtaLinks.fulfilled, (state, action) => {
            state.routeEtaLink = action?.payload;
            state.loading = false;
        });
        builder.addCase(routesEtaSearchEtaLinks.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(routesEtaDeleteEtaLink.pending, (state, action) => {
            state.loading = true;
            state.ladingDeleteEta = false;
        });
        builder.addCase(routesEtaDeleteEtaLink.fulfilled, (state, action) => {
            state.routeEtaLink = action?.payload;
            state.loading = false;
            state.ladingDeleteEta = true;
        });
        builder.addCase(routesEtaDeleteEtaLink.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        
    }
})

const { actions, reducer } = RoutesManagerEditPanelRouteEtaRoutesRedux;

export const { reset } = actions;

export default reducer;
