import RealtimeMapsModalCreateEditRouteLanguage from "../components/modalCreateEditRoute/lan/es";
import RealtimeMapsModalUploadRouteLanguage from "../components/uploadRoute/lan/es";

export default {
  ...RealtimeMapsModalCreateEditRouteLanguage,
  ...RealtimeMapsModalUploadRouteLanguage,
  
  "routes_edit": "Editar",
  "routes_directions": "Obtener Indicaciones",
  "routes_delete": "Eliminar",
  "routes_add": "Agregar Ruta",
  "routes_upload": "Cargar Rutas",
  "routes_upload_route": "Cargar",
  "routes_createRoute": "Crear Ruta",
  "routes_editRoute": "Editar Ruta",
  "routes_cancel": "Cancelar",
  "routes_save": "Guardar",
  "routes_name": "Nombre",
  "routes_start": "Inicio",
  "routes_waypoint": "PR",
  "routes_start_point": "Punto Inicial",
  "routes_start_point_exist": "El punto inicial ya existe, esta opción agregara un nuevo Punto Inicial dejando el anterior como la primer parada.",
  "routes_end": "Fin",
  "routes_stop": "Parada",
  "routes_routeDirections":"Obtener Direcciones de Ruta",
  "routes_end_point": "Punto Final",
  "routes_end_point_exist": "El Punto Final ya existe, esta opción agregara un nuevo punto como Final de la Ruta dejando el anterior como la última parada antes de finalizar la ruta.",
  "routes_title": "Título",
  "routes_file": "Archivo",
  "routes_optimizeRoute": "Optimizar Ruta",
  "routes_avoidTolls": "Evitar Peajes",
  "routes_useDriverApp": "Usar en la Aplicación de Conductores",
  "routes_useDriverAppInfo": "Si se desactiva, la ruta se asignará automáticamente y el seguimiento se iniciará de inmediato; de lo contrario, el conductor deberá configurar 'En Ruta' mediante la aplicación móvil de conductores.",
  "routes_stops": "Paradas",
  "routes_addStop": "Agregar Parada",
  "routes_addStop_end": "No puede agregar más de 25 paradas.",
  "routes_Address": "Dirección",
  "routes_Unit": "Unidad",
  "routes_Landmark": "Punto de Referencia",
  "routes_mapClick": "Usar Click en el Mapa",
  "routes_selectUnit": "Seleccionar Unidad",
  "routes_selectAddressType": "Seleccionar tipo de ubicación",
  "routes_latitude": "Latitud",
  "routes_longitude": "Longitud",
  "routes_removeStop": "Remover",
  "routes_error": "Ha ocurrido un error. Por favor intente de nuevo.",
  "routes_error_name": "Ya existe una ruta con ese nombre.",
  "routes_next": "Siguiente",
  "routes_deleteRouteTitle": "Eliminar ruta: ",
  "routes_delete_routes_title": "Eliminar Rutas",
  "routes_deleteRouteContent": "¿Esta seguro de que desea eliminar esta ruta? Esta acción no se puede deshacer",
  "routes_delete_routes_content": "¿Esta seguro de que desea eliminar esta rutas? Esta acción no se puede deshacer",
  "routes_deleteStopTitle": "Eliminar parada: ",
  "routes_deleteStopContent": "¿Esta seguro de que desea eliminar esta parada? Esta acción no se puede deshacer",
  "routes_deleteRouteSuccess": "Ruta eliminada con éxito",
  "routes_notes": "Notas",
  "routes_createEditRouteSuccess": "Ruta guardada con éxito",
  "routes_selectAddress": "Escriba y seleccione la dirección",
  "routes_attachmentsFilesAllowed": 'csv. 25 Paradas Máximo, 4MB',
  "routes_attachments_latitude": 'Latitud',
  "routes_attachments_longitude": 'Longitud',
  "routes_attachments_address": 'Dirección',
  "routes_attachments_city": 'Ciudad',
  "routes_attachments_state": 'Departamento',
  "routes_attachments_zip": 'Código Postal',
  "routes_attachments_note": 'Notas',
  "routes_attachments_template": 'Descargar Plantilla',
  "routes_attachments_error": 'El archivo no tiene el formato correcto',
  "routes_attachments_max": 'El archivo debe tener 25 paradas como máximo',
  "routes_deleteRoutesSuccess": "Rutas eliminadas con éxito",
  "route_minLengthMessage": "Este campo debe tener al menos de 4 caracteres",
  "route_maxLengthMessage": "Este campo debe tener un máximo de 50 caracteres",
  "route_room": "sala",
  "route_required_field": "es un",
  "route_required_field_click": "Click en el mapa para crear el punto",
  "route_required_field_template": "Plantilla",
  "route_required_field_assgned_route": "Rutas Asignadas",
  "route_required_field_driver": "Conductor",
  "route_required_field_schedule_at": "Programada En",

  "route_status_pending": "Pendiente",
  "route_status_in_route": "En Ruta",
  "route_status_on_site": "En Sitio",
  "route_status_done": "Finalizado",
  "route_status_done_with_exception": "Finalizado con Excepción",

  "routes_menu_settings": "Configuración",
  "routes_menu_routes": "ETA",
  "routes_menu_alerts": "Alertas",
}