import {createSlice} from "@reduxjs/toolkit"
import {resetALL} from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    filter: null,
    preference: null,
    hasFilters: null,
    loading: false,
    open: false,
    limit: 10
};

const reportBehaviorAdvanceFilterRedux = createSlice({
    name: 'report/behavior',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? {...state, ...action.payload} : {...INIT_STATE}
        },
        openModalBehaviorAdvanceFilter(state, action) {
            if (action?.payload.limit !== undefined) {
                state.limit = action?.payload.limit;
            }
            if (action?.payload.open !== undefined) {
                state.open = action?.payload.open;
            }
            if (action?.payload.hasFilters !== undefined) {
            state.hasFilters = action?.payload.hasFilters;
            }
            if (action?.payload.filter !== undefined) {
            state.filter = action?.payload.filter;
            }
            if (action?.payload.preference !== undefined) {
            state.preference = action?.payload.preference;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = reportBehaviorAdvanceFilterRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalBehaviorAdvanceFilter,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
