import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    loadingEventsVideo: false,
    error: false,
    responseListEventsVideo: [],
    openModalChangeStatus: false,
    loadingChangeStatus: false,
    changeStatusData: null,
    resultChangeStatus: null,

    nextPreviousIds: null,
    loadingNextPreviousIds: false,
    errorNextPreviousIds: null,

};


export const getListHasVideoEvents = createAsyncThunk('videoEvents/get', async ({ }, { rejectWithValue, dispatch }) => {
    try {

        const result = await clientQuery(`
                query getListHasVideoEvents {
                    getListHasVideoEvents {
                        eventName
                        rawEvents
                    }
                }`,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        )


        return result?.getListHasVideoEvents || [];
    } catch (exc) {

        return rejectWithValue(exc)
    }
});

export const updateVideoReviewStatus = createAsyncThunk('videoEvents/updateVideoReviewStatus', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { ids, reviewStatus, notePayload } = payload
        let response = await clientMutation(
            `mutation updateVideoReviewStatus ($ids: [ReadingIdInput]!, $reviewStatus: enumVideoReviewStatus!) {
                data: updateVideoReviewStatus (ids: $ids, reviewStatus: $reviewStatus)
            }`,
            {
                ids,
                reviewStatus
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        //Save Notes
        if (notePayload.length) {
            let mutations = [];
            notePayload.forEach((item, index) => {
                mutations.push(`
                    createDeviceReadingExtraField${index}: createDeviceReadingExtraField(
                        rowId: "${item?.rowId}",
                        deviceId: "${item?.deviceId}",
                        fieldName: "${item?.fieldName}",
                        jsonValue: ${JSON.stringify(item?.jsonValue)}
                    ){
                        id
                        createdOn
                        createdBy
                        updatedOn
                        updatedBy
                    }
                `);
            });
            let mutation = `mutation{${mutations.join("")}}`;
            await clientMutation(mutation, {}, endpoints.GRAPHQL_GENERAL_V2);
        }
        return { result: response.data }
    } catch (exc) {
        return rejectWithValue(exc)
    }
});

export const getNextPreviousIds = createAsyncThunk('getNextPreviousIds/get', async (payload, { rejectWithValue, dispatch }) => {

    const {
        filters,
        readingId
    } = payload;

    const entities = 
        !filters?.entities || !filters?.entities?.length ? 
            [{ name: 'All', id: -1 }] : 
            filters?.entities?.map(filter => {
                return { id: filter?.id, label: filter?.label, name: filter?.name }
            })

    try {
        const result = await clientQuery(`
            query getNextPreviousEventsIds(
                $filters: NextPreviousEventBody
                $readingId: Float!
            ) {
            getNextPreviousEventsIds(
                filters: $filters
                readingId: $readingId
            ) {
                previousEventId
                nextEventId
            }
        }`,
            {
                filters: {
                    openSearch: filters?.openSearch,
                    dateRange: filters?.dateRange,
                    entities: entities,
                    mediaDeleted: filters?.mediaDeleted || false,
                    mediaType: filters?.mediaType || "All",
                    reviewStatus: (!filters?.reviewStatus || filters?.reviewStatus === "All" ? null : filters?.reviewStatus),
                    starred: filters?.starred || false,
                    eventNames: filters?.events?.length ? filters?.events : [],
                    hasNotes: filters?.hasNotes || false,
                }, 
                readingId
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        return result?.getNextPreviousEventsIds || {};
    } catch (exc) {

        return rejectWithValue(exc)
    }
});


//Slice
const videoEventsRedux = createSlice({
    name: 'videoEvents',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalEventChangeStatus(state, action) {
            return {
                ...state,
                openModalChangeStatus: action.payload.open,
                changeStatusData: action.payload.data
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getListHasVideoEvents.pending, (state, action) => {
            state.loadingEventsVideo = true
            state.error = null
            state.responseListEventsVideo = []
        });
        builder.addCase(getListHasVideoEvents.fulfilled, (state, action) => {
            return { ...state, loadingEventsVideo: false, responseListEventsVideo: action.payload }
        });
        builder.addCase(getListHasVideoEvents.rejected, (state, action) => {
            state.loadingEventsVideo = false;
            state.error = action.payload;
        });

        builder.addCase(updateVideoReviewStatus.pending, (state, action) => {
            state.loadingChangeStatus = true
        });
        builder.addCase(updateVideoReviewStatus.fulfilled, (state, action) => {
            state.loadingChangeStatus = false
            state.resultChangeStatus = action.payload
        });
        builder.addCase(updateVideoReviewStatus.rejected, (state, action) => {
            state.loadingChangeStatus = false;
            state.error = action.payload;
        });

        builder.addCase(getNextPreviousIds.pending, (state, action) => {
            state.loadingNextPreviousIds = true
        });
        builder.addCase(getNextPreviousIds.fulfilled, (state, action) => {
            state.loadingNextPreviousIds = false
            state.nextPreviousIds = action.payload
        });
        builder.addCase(getNextPreviousIds.rejected, (state, action) => {
            state.loadingNextPreviousIds = false;
            state.errorNextPreviousIds = action.payload;
        });

    },
})

export const { actions, reducer } = videoEventsRedux;
// Extract and export each action creator by name
export const { reset, openModalEventChangeStatus } = actions;
export default reducer;