import React , { Fragment , useEffect , useState } from 'react';
import { Grid, Icon, ClickAwayListener, Paper, Fade, Popper, Typography } from '@mui/material';
import { Tooltip } from 'Components';
import PropTypes from 'prop-types';

import './tag.scss';
import { useDispatch , useSelector } from "react-redux";
import { catalogInitial } from "Core/catalogs/redux/actions";

const Tag = (props) => {
	const { name, placement, color } = props;

	const validateColor = (color) => {
		let newColor = 'gray';
		if (!color || color?.toLowerCase() === "fff" || color?.toLowerCase() === "ffffff" || color?.toLowerCase() === "#ffffff") return newColor;

		if (color) {
			if (color.indexOf('#') > -1) {
				newColor = color;
			} else {
				newColor = `#${color}`;
			}
		}

		return newColor;
	};

	return (
		<Tooltip title={name || ''} placement={placement || 'bottom'}>
			<span>
				<Icon style={{ fontSize: 20, color: validateColor(color) }}>local_offer</Icon>
			</span>
		</Tooltip>
	);
};

export const TagsGroup = (props) => {
	const { options, nameField = "", colorField = "", alwaysGroup = false, alignItems = "flex-start", justifyContent = "flex-start"} = props || {};

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const options1 = (options ? options : []).slice(0, 4);
	const options2 = (options ? options : []).slice(4);

	let singleTag = options?.length ? options[0] : null;

	return (
		<>
			{
				(options?.length === 1 && singleTag && !alwaysGroup) ?
					<Grid
						container
						wrap="nowrap"
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
					>
						<Grid
							item
							style={{
								paddingTop: 4,
								paddingRight: 4
							}}
						>
							<Tag
								name={nameField ? singleTag?.[nameField] : singleTag?.name}
								color={colorField ? singleTag?.[colorField] : singleTag.color}
							/>
						</Grid>
						<Grid item xs zeroMinWidth>
							<Typography className="lSetting-detail" noWrap sx={{fontSize: 14, fontWeight: "600"}}>
								{nameField ? singleTag?.[nameField] : singleTag?.name}
							</Typography>
						</Grid>
					</Grid> :
					<Grid
						container
						direction="row"
						justifyContent={justifyContent}
						alignItems={alignItems}
					>
						<Grid item style={{ width: 55 }}>
							<div className='tags-group-container'>
								{options1.map((option, index) => {
									return (
										<div
											key={index}
											className='tags-group-item'
											style={{
												top: -10,
												left: (index * 10)
											}}
										>
											<Tag
												name={nameField ? option?.[nameField] : option?.name}
												color={colorField ? option?.[colorField] : option?.color}
											/>
										</div>
									);
								})}
							</div>
						</Grid>

						{options && options.length > 4 && (
							<Grid item style={{ position: 'relative' }}>
								<div
									className="show-more-text"
									style={{
										position: "absolute",
										top: -10,
										left: 0
									}}
									onClick={(event) => {
										setOpen(!open);
										setAnchorEl(event.currentTarget);
									}}
								>
									+<span style={{ textDecoration: 'underline', fontSize: 14 }}>{options.length - 4}</span>
								</div>

								<Popper open={open} anchorEl={anchorEl} placement={'right-start'} transition>
									{({ TransitionProps }) => (
										<Fade {...TransitionProps} timeout={350}>
											<Paper>
												<ClickAwayListener onClickAway={() => setOpen(false)}>
													<div style={{ maxWidth: 200 }}>
														<div style={{ padding: '10px 10px 5px 10px' }}>
															{options2.map((option, index) => {
																return (
																	<Tag
																		key={index}
																		name={nameField ? option?.[nameField] : option?.name}
																		color={colorField ? option?.[colorField] : option?.color}
																	/>
																);
															})}
														</div>
													</div>
												</ClickAwayListener>
											</Paper>
										</Fade>
									)}
								</Popper>
							</Grid>
						)}
					</Grid>
			}
		</>
	);
};

export default Tag;

Tag.propTypes = {
	//name: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
};

TagsGroup.propTypes = {
	options: PropTypes.array.isRequired,
};
