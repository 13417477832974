//imports
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CreatableSelect from 'react-select/creatable';
//controls
import { Grid, Icon } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Select, { components } from 'react-select';
import { Chip } from 'Components';
//Custom
import ExtraOptions from './ExtraOptions';
import styles from 'Assets/sass/core/vars.scss';

//styles
import './select.scss';
import colors from 'Assets/sass/core/vars.scss';
import Tooltip from "Components/Tooltip";
//Component

const MaxLengthInput = (props) => {
	return <components.Input {...props} maxLength={props?.selectProps?.selectProps?.maxLength || 40} />
}

/*const colourStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	option: (styles) => ({ ...styles, zIndex: 99999999999999 })
};*/

const mockupStyles = {
	control: (styles, state) => {

		if (state.isFocused) {
			return {
				...styles,
				boxShadow: "0 0 8px #88daf8",
				borderColor: "#009dea",
				borderStyle: "solid",
				borderWidth: "1px",
				backgroundColor: 'white'
			}
		}

		return { ...styles, backgroundColor: 'white' }

	},
	option: (styles, state) => {
		if (state.isSelected && !state.isFocused) {
			return { ...styles, color: "white",  backgroundColor: colors["blue"]}
		}


		if (state.isFocused) {
			return {
				...styles, color: "black", backgroundColor: "#f4f4f4", zIndex: 99999999999999
			}
		}

		return { ...styles, color: "gray", zIndex: 99999999999999 }

	},
	//To change color into multSelect items
	multiValue: (provided, state) => {
		let color = styles.color_button_green;
		if (state.data?.color) {
			color = state.data?.color;
			let key = color.indexOf("#");
			if (key == -1) color = `#${color}`;
		}
		return { ...provided, backgroundColor: color === '#ffffff' ? colors.green : color };
	},
	groupHeading: (styles) => ({
		...styles,
		paddingLeft: '0px',
		paddingRight: '0px'
	})
};

const customComponents = {
	MultiValue: () => null,
};

class SelectComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			textValue: '',
			isValid: true,
			isTyping: false,
			errorMessage: null,
		};

		this.select = React.createRef();
		this.validate = this.validate.bind(this);
		this.changeTxt = this.changeTxt.bind(this);
		this.creatableSelect = React.createRef();
	}

	validate(val) {
		let isValid = true;
		let errorMessage;
		let value = val || this.props.value;
		if (this.props.required) {
			if (!value) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			} else if (this.props.multiple && !value.length) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			} else if (this.props.extraOptions && !value.length) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			}
		}

		this.setState({ isValid, errorMessage });

		return isValid;
	}

	customContainer = ({ children, ...props }) => {
		const { selectProps, hasValue } = props;
		const newChildren = [...children];
		newChildren[0] = selectProps.menuIsOpen ? "" : selectProps.placeholder;

		if (!hasValue) {
			return (
				<components.ValueContainer {...props}>
					{children}
				</components.ValueContainer>
			);
		}

		return (
			<components.ValueContainer onClick={() => this.select.setState({ menuIsOpen: true })} {...props}>
				{newChildren}
			</components.ValueContainer>
		);
	};

	changeTxt(inputValue, actionMeta) {
		if (actionMeta.action == 'set-value') {
			this.props.addNewOption(this.state.textValue, this.creatableSelect, this.validate);
		} else if (actionMeta.action == 'input-change') {
			this.setState({
				textValue: inputValue,
				isTyping: true
			})
		} else if (actionMeta.action == 'menu-close') {
			this.setState({
				textValue: '',
				isTyping: false
			})
		}
	}

	dropdownIndicator({ ...props }) {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<Icon>unfold_more</Icon>
				</components.DropdownIndicator>
			)
		)
	}

	render() {
		const { selectedOptionsBelow, extraOptions } = this.props;
		let optionList = this.props.options || [];
		if (this.props.extraOptions && this.props.value && this.props.value.length) {
			optionList = optionList.filter(
				(x) => !this.props.value.find((y) => x[this.props.valueField] === y[this.props.valueField]),
			);
		}

		let options = {};
		if (this.props.customIcon) {
			options.DropdownIndicator = this.props.customIcon;
		}

		if (extraOptions && extraOptions.length > 0) {
			options.Option = ExtraOptions;
			options.ValueContainer = this.customContainer;
		}

		if (selectedOptionsBelow) {
			options.ValueContainer = this.customContainer;
		}

		if (this.props.icon) {
			options.DropdownIndicator = this.dropdownIndicator;
		}

		if (this.props.customOption) {
			options.Option = this.props.customOption
		}

		if (this.props.NoOptionsMessage) {
			options.NoOptionsMessage = this.props.NoOptionsMessage;
		}

		if (this.props.createOptions) {
			options.Input = MaxLengthInput;
		}

		return (
			<div
				className={cx("form-item",this.props.className)}
				style={this.props.containerStyle || { position: 'relative' }}
				onBlur={() => {
					//this.setState({ isOpen: false })
				}}
			>
				{
					//LABEL AREA
					this.props.label && (
							<label className={cx("input-normal-label", this.props.titleStyles)}>
								{this.props.label}
                              { ( ! this.props.notRequiderdShow && this.props.required ) || this.props.addRequiredLabel ? (
                                <span className="text-danger"> *</span>
                              ) : null }
							</label>
					)
				}

				{
					this.props.customLabel && (
						<label className={cx("input-normal-label", this.props.titleStyles)}>
							{this.props.customLabel}
						</label>
					)
				}

				{
					this.props.iconRight && (
						<Grid className="icon-right-select">

							<Tooltip placement="left" title={this.props.messageInfoIcon}>
								<Icon
									style={this.props.styleIcon || {}}
									fontSize={"small"}
									onClick={this.props.onClickIcon}
								>
									{this.props.iconRight}
								</Icon>
							</Tooltip>
						</Grid>
					)
				}
				{this.props.loading ? (
					<div className="input-loading">
						<Skeleton />
					</div>
				) : (
					this.props.createOptions ?
						(
							<Fragment>
								<CreatableSelect
									className="inputSelectCreatable react-select-container"
									value={this.props.value || ''}
									options={this.state.isTyping && this.state.textValue != "" && !this.props.isSearchable ? [] : (this.props.options || [])}
									onChange={(e) => {
										if (e && e.length && !e[e.length - 1]?.__isNew__) {
											let value = e;
											if (Array.isArray(e) && e.length === 0) value = null;

											if (this.props.onChange && (this.props.isSearchable || !this.state.isTyping)) {
												this.props.onChange(value);
											}

											this.validate(value);
										} else if (typeof e === 'object' && !Array.isArray(e)) {
											if (this.props.onChange) {
												this.props.onChange(e);
											}
										} else if (!e || !e.length) {
											if (this.props.onChange) {
												this.props.onChange([]);
											}
										}
									}}
									isMulti={this.props.multiple || (!!extraOptions)}
									noOptionsMessage={() => this.props.noOptionsMessage}
									selectProps={{ maxLength: this.props.maxLength }}
									onInputChange={this.changeTxt}
									createOptionPosition={'first'}
									styles={mockupStyles}
									components={options}
									formatCreateLabel={() => this.props.messageAdd}
									getOptionValue={(obj) => {
										return obj[this.props.valueField] || obj.value
									}}
									getOptionLabel={
										this.props.getOptionLabel ||
										((obj) => {
											let label = '';
											label = obj[this.props.textField] || obj.label;
											return label;
										})
									}
									ref={(ref) => (this.creatableSelect = ref)}
									isDisabled={this.props.disabled}
									placeholder={this.props.placeholder}
									menuPortalTarget={document.body}
									menuShouldBlockScroll={false}
									isSearchable={this.props.isSearchable}
									isClearable={this.props.isClearable}
									onMenuOpen={() => {
										if (this.props.onMenuOpen) this.props.onMenuOpen()
									}}
								/>
								{
									this.props.showOptionsPills &&
									(
										<Grid container spacing={1} className="top20">
											{this.props.optionList.map((option, key) => {
												return (
													<Grid item sm={12} key={key}>
														<Chip
															label={option[this.props.textField || 'label']}
															handleDelete={() => {
																this.props.handleDeleteContact(option[this.props.valueField || 'key']);
															}}
														/>
													</Grid>
												);
											})}
										</Grid>
									)
								}
							</Fragment>
						) :
						(
							<Fragment>
								<Select
									id={this.props.id}
									key={`unique_select_key__${this.props.value}`}
									ref={(ref) => (this.select = ref)}
									className={cx(
										this.props.useColorDataMultiselect ? 'react-select-use-param-color' : 'react-select-container',
										this.props.className || '',
										!this.state.isValid && 'react-select-container-error',
									)}
									classNamePrefix="react-select"
									defaultValue={this.props.defaultValue}
									value={this.props.value}
									isMulti={this.props.multiple || (!!extraOptions)}
									name={this.props.id}
									options={optionList}
									placeholder={
										(!this.props.multiple && this.props.value && this.props?.value[this.props?.textField]) || this.props.placeholder
									}
									isDisabled={this.props.disabled}
									isSearchable={(this.props.isSearchable != undefined || this.props.isSearchable != null) ? this.props.isSearchable : true}
									isClearable={this.props.isClearable}
									getOptionLabel={
										this.props.getOptionLabel ||
										((obj) => {
											let label = '';
											label = obj[this.props.textField ? this.props.textField : 'label'];
											return label;
										})
									}
									isOptionDisabled={this.props.isOptionDisabled}
									getOptionValue={(obj) => obj[this.props.valueField || 'key']}
									onChange={(e) => {
										let value = e;
										if (Array.isArray(e) && e.length === 0) value = null;
										if (this.props.onChange) {
											this.props.onChange(value);
										}

										this.validate(value);
									}}
									menuPortalTarget={this.props.defaultMenuPortalTarget ? undefined : document.body}
									components={this.props.isChips ? customComponents : options}
									extraOptions={extraOptions}
									required={this.props.required}
									requiredMessage={this.props.requiredMessage}
									extraOptionstextField={this.props.extraOptionstextField}
									extraOptionsvalueField={this.props.extraOptionsvalueField}
									extraOptionsRequiredMessage={this.props.extraOptionsRequiredMessage}
									menuShouldBlockScroll={true}
									styles={mockupStyles}
									menuPlacement={this.props.menuPlacement}
									innerRef={this.select}
									formatGroupLabel={this.props.formatGroupLabel}
									onMenuOpen={() => {
										if (this.props.onMenuOpen) this.props.onMenuOpen()
									}}
								/>
							</Fragment>
						)

				)}

				{!this.state.isValid && this.state.errorMessage && (
					<small className="select-error text-danger">{this.state.errorMessage}</small>
				)}
				{this.props.helpText && (
					<small id={`${this.props.id}-help`} className="form-text text-muted" style={{ paddingTop: 8 }}>
						{this.props.helpText}
					</small>
				)}

				{((extraOptions && extraOptions.length > 0) || selectedOptionsBelow) && (
					<div style={{ marginTop: 10, width: '100%' }} className='containerSelectedOptions'>
						{this.props.value &&
							this.props.value.map((val, index) => {
								let label = val[this.props.textField];
								let opts = [];
								if (val.options) {
									opts = val.options.map((option) => option[this.props.extraOptionstextField]);
								}

								return (
									<Grid key={index}>
										<Chip
											label={`${label} ${opts.length > 0 && ":" || ""} ${opts.join(', ')}`}
											disabled={val?.disabled}
											color={val?.color}
											handleDelete={() => {
												let currentValue = [...this.props.value];
												currentValue = currentValue.filter(
													(cval) => cval[this.props.valueField] != val[this.props.valueField],
												);

												if (this.props.onChange) {
													this.props.onChange(currentValue);
												}
											}}
										/>
									</Grid>
								);
							})}
					</div>
				)}

				{
					this.props.isChips 
					&&
					<Grid container spacing={1} style={{ marginTop: '10px', justifyContent: 'center'}}>
						{
							this.props.value && 
							this?.props?.value?.map((val, index) => {
								let label = val[this.props.textField];
								return(
									<Grid item xs={12} key={index}>
										<Chip 
											label={label}
											handleDelete={() => {
												let currentValue = [...this.props.value];
												currentValue = currentValue.filter(
													(cval) => cval[this.props.valueField] != val[this.props.valueField],
												);

												if (this.props.onChange) {
													this.props.onChange(currentValue);
												}
											}}
											style={{
												width: '100%',
												justifyContent: 'space-between',
												display: 'flex',
												alignItems: 'center'
											}}
										/>
									</Grid>
								)
							})
						}
					</Grid> 
				}

			</div>
		);
	}
}

SelectComponent.propTypes = {
	id: PropTypes.string.isRequired,
	valueField: PropTypes.string.isRequired,
	textField: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	//value: PropTypes.object.isRequired,
};

export default SelectComponent;
