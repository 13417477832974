import colors from "Assets/sass/core/vars.scss";
import {useIntl} from "react-intl";

export const METRIC_SPEED = 'vehicleSpeed'
export const METRIC_SPEED_KEY = 'SpeedOver'
export const METRIC_SPEED_LABEL = 'speedLabel'
export const METRIC_SPEED_OVER_KEY = 'SpeedOverPosted'
export const METRIC_SPEED_OVER_LABEL_LIST = ['speedOverLow', 'speedOverMedium', 'speedOverHigh']
export const METRIC_SPEED_OVER_LABEL_LOW = 'speedOverLow'
export const METRIC_SPEED_OVER_LABEL_MEDIUM = 'speedOverMedium'
export const METRIC_SPEED_OVER_LABEL_HIGH = 'speedOverHigh'
export const METRIC_SPEED_LIST = ['SpeedOver']
export const METRIC_SPEED_OVER_LIST = ['SpeedOverPosted']
export const METRIC_VALUE_SPEED_LIST = [
    {value: 20},
    {value: 25},
    {value: 30},
    {value: 35},
    {value: 40},
    {value: 45},
    {value: 50},
    {value: 55},
    {value: 60},
    {value: 65},
    {value: 70},
    {value: 75},
    {value: 80},
    {value: 85},
    {value: 90},
    {value: 95},
    {value: 100}
]
export const SEARCH_BEHAVIOR_SORT = ['behaviorMetric_category', 'metric']

export const useMetricTypesOptions = () => {
    const {messages} = useIntl();
    const metricTypesOptions = [
        {
            id: 'aggression',
            options: [
                {id: "RapidAcceleration"},
                {id: "HardBrake"},
                {id: "HardTurn"},
                {id: "PotentialIncident"},
            ],
        },
        {
            id: 'driverMonitoringSystem',
            options: [
                {id: "UsingPhone"},
                {id: "Smoking"},
                {id: "Drowsiness"},
                {id: "NoSeatbelt"},
                {id: "DayDreaming"},
                {id: "Yawn"},
                {id: "Asleep"},
                {id: "Distracted"},
                {id: "CameraObstructed"},
                {id: "IdleNoDriver"},
                {id: "PassengersDetection"},
            ],
        },
        {
            id: 'advancedDriverAssistanceSystem',
            options: [
                {id: "ForwardCollisionWarning"},
                {id: "CloseFollowing"},
                {id: "Tailgating"},
                {id: "LaneDeparture"},
                {id: "StopSignViolation"},
                {id: "PedestrianDetection"},
                {id: "CyclistDetection"},
            ],
        },
        {
            id: 'vehicleSpeed',
            options: [
                {id: 'speedLabel'}
            ],
        },
        {
            id: 'speedingOverPosted',
            options: [
                {id: "speedOverLow"},
                {id: "speedOverMedium"},
                {id: "speedOverHigh"},
            ],
        }];

    const menuMetricOptions = [
        messages["edit"],
        messages["delete"],
    ];

    function getSortMetricOptions() {
        const sortInternal = metricTypesOptions.map((category) => {
            category.label = messages['behavior_' + category.id]
            const options = category.options.map((option) => {
                option.name = getNameMetricType({metric: option.id, metricValue: option.value});
                return option;
            })
            category.options = options.sort((a, b) => a.name?.localeCompare(b.name));
            return category;
        })
        return sortInternal.sort((a, b) => a.label?.localeCompare(b.label));
    }

    const getNameMetricType = (metric) => {
        return `${messages['behavior_' + metric.metric]}${metric.metricValue ? (' ' + metric.metricValue) : ''}`
    }

    const getMetricType = (currentMetric) => {
        if (currentMetric) {
            const category = metricTypesOptions.find(category => category.id === currentMetric.category);
            return category.options.find(option => {
                if (option.id === currentMetric.metric) {
                    return {
                        id: currentMetric.metric,
                        label: getNameMetricType(currentMetric),
                    }
                }
            });
        }
    }

    return {
        metricTypesOptions: getSortMetricOptions(),
        getNameMetricType,
        menuMetricOptions,
        getMetricType
    }
}

export const useFormatMetric = () => {
    const formatMetric = (metric) => {
        if (metric.id === METRIC_SPEED_LABEL) {
            return METRIC_SPEED_KEY;
        } else if (METRIC_SPEED_OVER_LABEL_LIST.includes(metric.id)) {
            return METRIC_SPEED_OVER_KEY;
        } else {
            return metric.id;
        }
    }

    return {
        formatMetric
    }
}

export const COLOR_EVT_OTHER = 'color_evnt_other'
export const COLOR_BLACK = 'color_black'
export const COLOR_BEHAVIOR_GREEN = 'color_behavior_green'
export const COLOR_BEHAVIOR_GREEN_LIGHT = 'color_behavior_green_light'
export const COLOR_BEHAVIOR_ORANGE_LIGHT = 'color_behavior_orange_light'
export const COLOR_BEHAVIOR_ORANGE = 'color_behavior_orange'
export const COLOR_BEHAVIOR_RED = 'color_behavior_red'

//COLORS SLIDERS SCORES BEHAVIOR
export const COLORS_SLIDERS_SCORE = [
    colors[COLOR_BEHAVIOR_GREEN],
    colors[COLOR_BEHAVIOR_GREEN_LIGHT],
    colors[COLOR_BEHAVIOR_ORANGE_LIGHT],
    colors[COLOR_BEHAVIOR_ORANGE],
    colors[COLOR_BEHAVIOR_RED]
];
