//View Keys
export default {
	locationsView_locations: 'Ubicaciones',
    locationsView_units_in_location: 'Unidades en la Ubicación',
    locationsView_vehicleLocation: 'Ubicación del vehículo',
    locationsView_units: 'Unidades',
    locationsView_delete: 'Eliminar',
    locationsView_cancel: 'Cancelar',
    locationsView_save: 'Guardar',
    locationsView_addLocation: 'Añadir',
    locationsView_createNewLocation: 'Crear una nueva Ubicación',
    locationsView_editLocation: 'Editar Ubicación',
    locationsView_successfullyCreated: 'La Ubicación fue creada con éxito',
    locationsView_successfullyEdited: 'La Ubicación fue editada con éxito',
    locationsView_messagesDelete: 'Está seguro de que quiere eliminar las ubicaciones',
    locationsView_locationName: 'Nombre de la Ubicación',
    locationsView_deleteLocation: 'Borrar Ubicación',
    locationsView_move: 'Mover a',
    locationsView_deleteLabel: 'Borrar (Se borran todas las ubicaciones de las unidades)',
    locationsView_deleteMessage: 'Qué quiere hacer con las Ubicaciones asignados a las unidades',
    locationsView_deleteSuccess: 'Ubicaciones eliminadas con éxito',
    locationsView_availableActions: 'Acciones disponibles',
    locationsView_searchByLocation: 'Buscar por Ubicación del vehículo',
    locationsView_activated: 'Activado',
    locationsView_deactivated: 'Desactivado',
    locationsView_ELD_150_miles_rule: 'Habilitar ELD 150 millas Norma',
    locationsView_title: 'Título',
    locationsView_address: 'Dirección',
    locationsView_tags: 'Etiquetas',
    locationsView_ELD150MilesRule: 'ELD 150 Millas Norma',
    locationsView_timezone: 'Zona horaria',
    locations_validate_deletes: '¿Está seguro de que desea eliminar las siguientes ubicaciones?',
    locations_validate_delete: '¿Está seguro de que desea eliminar la Ubicación?',
    locations_transfer: 'Trasladar las entidades asociadas a otra Ubicación',
    locations_delete_location: 'Eliminar Ubicación',
     locations_delete_locations: 'Eliminar Ubicaciones',
    locations_loading_units: 'Las unidades se están cargando',
    locations_to_receive: 'Ubicación libre para las Unidades',
    locations_transfer_units: 'Se han encontrado unidades asignadas en la(s) Ubicación(es) que se eliminan. Seleccione una nueva Ubicación para estas unidades.',
    locations_required_address_specify: 'La dirección debería ser más específica',
};
