import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {clientQuery} from '../../../../../../../core/data/GraphQLApi';
import {endpoints} from '../../../../../../../core/defaultValues';
import {ExceptionManager} from '../../../../../../../core/logManager';

import {resetALL} from '../../../../../../../.globals/redux/actions';
import {CONDITION_REPORT_DEFAULT} from "Modules/behavior/views/report/redux/helpers";

//STATE INITIAL
const INIT_STATE = {
    open: false,
};

const route = 'modules/behavior/views/report/modals/export/redux/slice.js'

export const exportReportBehaviors = createAsyncThunk('report/behavior/exportReportBehaviors', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    try {
        let query = `
        query getBehaviorReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
             getBehaviorReport (conditions: $conditions, limit: $limit, offset: $offset) {
                items {
                    recordInfo {
                        entityId
                        entityLabel
                        tagId
                        tagLabel
                        startDate
                        endDate
                        recordType
                        groupLabel
                        groupIndex
                        groupTotal
                        groupTagColor
                    }
                    recordDetail {
                        id
                        name
                        aggression
                        speed
                        speedOver
                        safetyEvents
                        score
                        risk
                        noData
                    }
                }
                total
                limit
                offset
                category
                reportInstanceId
            }
        }
        `;
        const addCustomColumns = []
        if (payload.customcolumns.includes('aggression')) {
            addCustomColumns.push({
                column: 'aggression',
                header: 'Aggression'
            })
        }
        if (payload.customcolumns.includes('driverMonitoringSystem')) {
            addCustomColumns.push({
                column: 'driverMonitoringSystem',
                header: 'Driver Monitoring System (DMS)'
            })
        }
        if (payload.customcolumns.includes('advancedDriverAssistanceSystem')) {
            addCustomColumns.push({
                column: 'advancedDriverAssistanceSystem',
                header: 'Advanced Driver Assistance System (ADAS)'
            })
        }
        if (payload.customcolumns.includes('vehicleSpeed')) {
            addCustomColumns.push({
                column: "speed",
                header: "Vehicle Speed"
            })
        }
        if (payload.customcolumns.includes('speedingOverPosted')) {
            addCustomColumns.push({
                column: "speedOver",
                header: "Speeding Over Posted"
            })
        }

        const addColumnDriver = (
            payload.export?.format === 'csv' ?
                [{
                    "column": "name",
                    "header": payload.filter.entity === 'Unit' ? "Vehicle" : "Driver"
                }]
                : [])
        const response = await clientQuery(
            query,
            {
                limit: 5000,
                offset: 1,
                conditions: {
                    ...CONDITION_REPORT_DEFAULT,
                    timeZone: payload.user.timeZone,
                    category: {
                        field: payload.filter.entity === 'Unit' ? 'deviceId' : 'driverId',
                        categoryName: payload.filter.entity === 'Unit' ? 'deviceId' : 'driverId',
                        entities: payload.filter.entities.length ? payload.filter.entities.map(res => {
                            return {
                                id: res.id,
                                type: res.name,
                            }
                        }) : [
                            {
                                id: -99,
                                type: payload.filter.entity
                            }
                        ],
                        includeInactiveDrivers: true
                    },
                    dateRange: {
                        fields: ["unitTime"],
                        GTE: payload.filter.dateRange.start,
                        LTE: payload.filter.dateRange.end,
                        timeframeName: "Custom",
                        reportTimeRanges: []
                    },
                    sessionId: sessionStorage.getItem("sessionId"),
                    stringValues: {
                        values: payload.filter.risk ? (payload.filter.risk !== 'All' ? [payload.filter.risk] : []) : []
                    },
                    filterValues: {
                        field: "Format",
                        entities: [
                            {
                                id: payload.export?.type,
                                type: "Format"
                            },
                        ],
                    },
                    sortField: {
                        field: payload.filter.entity === 'Unit' ? 'deviceId' : 'driverId',
                        order: "ASC"
                    },
                    exportOptions: {
                        sendByEmail: false,
                        emailsTo: [''],
                        format: {
                            fileFormat: payload.export?.format || 'pdf',
                            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: payload.user.durationFormat
                        },
                        culture: "en",
                        timeZone: payload.user.timeZone,
                        folderName: "px-customer-reports",
                        filePrefixName: "behaviorReport",
                        columns: [
                            ...addColumnDriver,
                            ...addCustomColumns,
                            {
                                column: "safetyEvents",
                                header: "Total Safety Events"
                            },
                            {
                                column: "score",
                                header: "Risk Factor"
                            }
                        ]
                    }
                },
            },
            endpoints.GRAPHQL_PX_REPORTING
        );

        return response?.data || [];
    } catch (exc) {
        ExceptionManager(exc, route, 'exportReportBehaviors');
        return rejectWithValue(exc)
    }
});

const reportExportBehaviorsRedux = createSlice({
    name: 'report/behavior',
    initialState: INIT_STATE,
    reducers: {
        openModalExportBehavior(state, action) {
            state.open = action?.payload.open;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(exportReportBehaviors.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(exportReportBehaviors.fulfilled, (state, action) => {
            state.loading = false;
            state.reportResult = action.payload
        });
        builder.addCase(exportReportBehaviors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = reportExportBehaviorsRedux;

// Extract and export each action creator by name
export const {openModalExportBehavior} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
