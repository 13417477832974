export default {
    settingsCameraManage_title: "Camera Management",
    settingsCameraManage_recordId: "Record Id",
    settingsCameraManage_imei: "IMEI",
    settingsCameraManage_esn: "ESN",
    settingsCameraManage_pairedUnit: "Paired Unit",
    settingsCameraManage_label: "Label",
    settingsCameraManage_unpaired: "Unpaired",
    settingsCameraManage_editCameraPairing: "Edit Camera Pairing",
    settingsCameraManage_showUnpairedOnly: "Show unpaired only",
    settingsCameraManage_editCamera: "Edit",
    settingsCameraManage_cameraSetup: "Camera Setup",
    settingsCameraManage_sdCardError: "SD card error",
    settingsCameraManage_warnings: "Warnings",
    settingsCameraManage_bulk_camera_config: "Bulk Camera Config",
    settingsCameraManage_device_model: "Device Model",
    settingsCameraManage_4K: "VidFleet AI+",
    settingsCameraManage_360: "VidFleet 1",
    settingsCameraManage_same_device_model: "The device model of the cameras must be the same",
    settingsCameraManage_cameras_must_be_vidfleet: "Cameras must be VidFleet",
    settingsCameraManage_camara_as_gps: "Camera as GPS",
};