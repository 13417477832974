import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  formatDatetimeNoTZ,
  getCategoryFilterType,
  useHandleExportReport,
  getEntityType
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {GetFormatForMoment} from "Core/data/Helpers";

import { useSidePanelMap, useTableReport } from 'Core/hooks';

import './fuelCardReport.scss';

import {
  reportsMainGenerateEvents
} from 'Redux/actions'
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";

const FuelCardReport = (props) => {
  const { messages, report } = props;
  const { loading, data, filters, events, summary, loadingSummary, subEvents, loadingSubEvents, timeZone } = report;
  const { filterEntity, filterArray, otherFilters, filterType, startRange, endRange } = filters;


  const reportRef = useRef();
  const dispatch = useDispatch()
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [titleSideMap,setTitleSideMap] = useState("");

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  const { user: { dateformat } } = useSelector((state) => state.securityRedux);
  const momentFormat = GetFormatForMoment(dateformat)

  const onChangeExpandable = (unit) => {
    if(!unit.noData){
      let events = {
        data: [],
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: unit,
          page: 1,
          rowsPerPage: 10,
          sortField: 'eventDate',
          otherFilters
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
    
  }




  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      data: summary?.items || [],
      totalItems: summary?.total || 0,
      hideHeader: true,
      serverSideTable: true,
      onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
        let events = {
          data: [],
          filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
        }
        dispatch(reportsMainGenerateEvents(report, events))
      },
      hideNumberOfRows: true,
      hiddenPagination: false,
      numberofRows: 10,
      centerPagination: true
    };
    return (
      <Table
        {...parametersSummaryTable}
        loading={loadingSummary}
        noData={row.noData}
      >
        <Column noSorting >
          {(element) => {
            let listData = [
              {
                ...element,
                card: true,
                dateTime: formatDatetimeNoTZ(element?.dateTime, "MMMM D, YYYY h:mm A"),
                location: `${element?.locationSiteName},${element?.locationSiteAddress}`
              }, {
                ...element,
                card: false,
                dateTime: `${messages['generatedReport_posted']}: ${formatDatetime(element?.postedDate, "MMMM D, YYYY h:mm A", timeZone)}`,
                fuel    : `${messages['generatedReport_quantity']}: ${element.quantity}G ${messages['generatedReport_price']}: $${element.price} `,
                location: `${messages['generatedReport_unitAddress']}:  ${element?.unitAddress}`
              }
            ];

            return (
              <div>
                <Table
                  data={listData || []}
                  noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                  hideNumberOfRows={true}
                  hiddenPagination={true}
                  classNameColumns={'report-sub-events-columns detail-day-timesheet-tr'}
                >
                  <Column label={messages['generatedReport_timeAndDate']} field="dateTime" noSorting>
                    {(data) => {
                      return (
                        formatDatetime(data?.dateTime, `${momentFormat} h:mm A`)
                      );
                    }}
                  </Column>
                  <Column label={messages['generatedReport_transactionId']} field="transactionId" noSorting>
                    {(data) => {
                      return (
                        data?.transactionId
                      );
                    }}
                  </Column>
                  <Column label={messages['generatedReport_fleet']} field="fleet" noSorting>
                    {(data) => {
                      return (
                        data?.fleet
                      );
                    }}
                  </Column>
                  <Column label={messages['reportsMainRT_Location']} field="locationSiteName" noSorting>
                    {(data) => {
                      if(data?.card){
                        return (
                          <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} isVisibleLandmark={false} element={{
                            event: data?.eventName,
                            address: data?.location,
                            latitude: data?.latitude,
                            longitude: data?.longitude,
                          }} iconStyle={{ fontSize: 18 }} classNameButtonAddress={'report-address-button'} />
                        );
                      }
                      
                      return (
                          <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{
                            event: data?.eventName,
                            address: data?.unitAddress,
                            latitude: data?.latitude,
                            longitude: data?.longitude,
                            landmarkName: data?.landmarkName,
                            landmarkId: data?.landmarkId,
                          }} iconStyle={{ fontSize: 18 }} classNameButtonAddress={'report-address-button'} />
                      );
                    }}
                  </Column>
                  <Column label={messages['reportsMainRT_card']} field="card" noSorting>
                    {(data) => {
                      return (
                        data?.card
                      );
                    }}
                  </Column>
                  <Column label={messages['reportsMainRT_fuel']} field="fuel" noSorting>
                    {(data) => {
                      return (
                        data?.fuel
                      );
                    }}
                  </Column>
                  <Column label={messages['generatedReport_odometer']} field="odometer" noSorting>
                    {(data) => {
                      return (
                        data?.odometer
                      );
                    }}
                  </Column>
                  <Column label={messages['generatedReport_mpg']} field="mpg" noSorting>
                    {(data) => {
                      return (
                        data?.mpg
                      );
                    }}
                  </Column>
                </Table>
              </div>
            );
          }}
        </Column>
        <Column noSorting>
          {() => { return '' }}
        </Column>

      </Table>
    )
  }


  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: 'start-stop-idle-main-table'

  });

  return (
    <>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="Day" field="label">
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>

          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label">
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
      <SideMapComponent title={titleSideMap} hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"} />
    </>
  );
}

export default FuelCardReport
