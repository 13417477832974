// Action types.
import { 
  SUB_USER_SEARCH,
  SUB_USER_SEARCH_RESPONSE, 
  SUB_USER_GET,
  SUB_USER_GET_RESPONSE,
  SUB_USER_CURRENT_GET,
  SUB_USER_CURRENT_GET_RESPONSE,
  SUB_USER_CREATE,
  SUB_USER_CREATE_RESPONSE, 
  SUB_USER_UPDATE,
  SUB_USER_UPDATE_RESPONSE, 
  SUB_USER_DELETE,
  SUB_USER_DELETE_RESPONSE,
  SUB_USER_RESET_COGNITO,
  SUB_USER_RESET_COGNITO_RESPONSE,
  SUB_USER_DELETE_BULK,
  SUB_USER_RESET_PASSWORD,
  SUB_USER_RESET_PASSWORD_RESPONSE
} from "Redux/actionTypes";

export const searchSubUser = (data) => {
  return {
    type: SUB_USER_SEARCH,
    payload: data,
  };
};

export const searchSubUserResponse = (data) => {
  return {
    type: SUB_USER_SEARCH_RESPONSE,
    payload: data,
  };
};

export const getSubUser = (data) => {
  return {
    type: SUB_USER_GET,
    payload: data,
  };
};


export const getSubUserResponse = (data) => {
  return {
    type: SUB_USER_GET_RESPONSE,
    payload: data,
  };
};

export const getCurrentSubUser = (data) => {
  return {
    type: SUB_USER_CURRENT_GET,
    payload: data,
  };
};


export const getCurrentSubUserResponse = (data) => {
  return {
    type: SUB_USER_CURRENT_GET_RESPONSE,
    payload: data,
  };
};

export const createSubUser = (data) => {
  return {
    type: SUB_USER_CREATE,
    payload: data,
  };
};

export const createSubUserResponse = (data) => {
  return {
    type: SUB_USER_CREATE_RESPONSE,
    payload: data,
  };
};

export const updateSubUser = (data) => {
  return {
    type: SUB_USER_UPDATE,
    payload: data,
  };
};

export const updateSubUserResponse = (data) => {
  return {
    type: SUB_USER_UPDATE_RESPONSE,
    payload: data,
  };
};

export const deleteSubUser = (data) => {
  return {
    type: SUB_USER_DELETE,
    payload: data,
  };
};

export const deleteSubUserResponse = (data) => {
  return {
    type: SUB_USER_DELETE_RESPONSE,
    payload: data,
  };
};

export const deleteSubUsers = (data) => {
  return {
    type: SUB_USER_DELETE_BULK,
    payload: data,
  };
};

export const resetCognitoSubUser = (data) => {
  return {
    type: SUB_USER_RESET_COGNITO,
    payload: data,
  };
};

export const resetCognitoSubUserResponse = (data) => {
  return {
    type: SUB_USER_RESET_COGNITO_RESPONSE,
    payload: data,
  };
};

export const resetPasswordCognito = (data) => {
  return {
    type: SUB_USER_RESET_PASSWORD,
    payload: data,
  };
};

export const resetPasswordCognitoResponse = (data) => {
  return {
    type: SUB_USER_RESET_PASSWORD_RESPONSE,
    payload: data,
  };
};
