
import React , {useState} from 'react';
import VirtualizedTable from 'Components/uiControls/VirtualizedTable/index.js'
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

// International
import { useIntl } from "react-intl";

//Components
import { Toolbar } from 'Components/Table';
import { Button } from 'Components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowSelect from './../arrowSelect'
//Styles
import './styles.scss'

const InfiniteScrollTable = ({
  refContainer,
  renderItems,
  loading,
  handleNewPageLoad,
  data,
  total,
  placeholderSelector,
  reportTitle,
  pinReport,
  editReport,
  generateExport,
  tableContainerClass,
  onCreate,
  onSchedule,
  // Selector
  itemsSelectorEntity,
  textFieldSelectorEntity,
  valueSelecteEntity,
  onChangeSelectEntity,
  itemsSelectDates,
  textFieldSelectorDates,
  onChangeSelectDates,
  dateSelected,
  type,
  resetCache,
  renderHeader,
  renderSubHeader,
  onStartIndex,
  scrollEvent,
  isActive
}) => {
  const {messages}  = useIntl();
  const [pageCount, setPageCount] = useState(1);
  const showReportSetup = useSelector(state => state.reportsMainRTRedux.showReportSetup)
  return (
    <Grid container className='table-scroll-reports'>
      <Grid container item sm={12} style={{height: "44px"}}>
        <div className='table-scroll-reports-space'>

        </div>

        <div className='table-scroll-reports-line'>

        </div>
      </Grid>
      <Grid container item sm={12} justifyContent="space-between">
        <Grid item sm={6}>
          <h1 className='table-scroll-reports-title'>
            {reportTitle}
          </h1>
        </Grid>
        <Grid container item sm={6} className="table-scroll-reports-toolbar" alignItems={"center"} justifyContent={"flex-end"}>
          <Toolbar
            disabledExport={loading}
            hideSearchForm
            hideVisibilityButton
            onExport={(e) => {
              generateExport(e);
            }}
            sizeLeftGrid={10}
            justifyContent={"flex-end"}
            alignItems={"center"}
            classNameContainer={"table-scroll-reports-toolbar-ctn"}
            fontSize={"24px"}
          >
            <Button type="icon" tooltip={messages['reportsMainRT_pinReport']} placement="bottom" icon="push_pin" onClick={pinReport}
                    className={loading ? `table-scroll-reports-toolbar-btns-disabled` : `table-scroll-reports-toolbar-btns`} fontSize={"24px"} disabled={loading}/>
            <Button type="icon" tooltip={messages['reportsMainRT_editReport']} placement="bottom" icon="create" onClick={editReport}
                    className={loading ? `table-scroll-reports-toolbar-btns-disabled` : `table-scroll-reports-toolbar-btns`} fontSize={"24px"} disabled={loading}/>
            <Button type="icon" tooltip={messages['reportsMainST_scheduleReport']} placement="bottom" icon="event" onClick={onSchedule}
                    className={loading ? `table-scroll-reports-toolbar-btns-disabled` : `table-scroll-reports-toolbar-btns`} fontSize={"24px"} disabled={loading}/>
            <Button type="icon" tooltip={messages['reportsMainRT_getNewReport']} placement="bottom" icon="add_circle" onClick={onCreate}
                    className={loading ? `table-scroll-reports-toolbar-btns-disabled` : `table-scroll-reports-toolbar-btns`} fontSize={"24px"} disabled={loading}/>
          </Toolbar>
        </Grid>
      </Grid>
      <Grid container item sm={12} spacing={1}>
        <Grid container item sm={6}>
          <Grid container item sm={12}>
            <span className='table-scroll-reports-title-filter'>
              {messages['generatedReport_jump_to']} {type}
            </span>
          </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid container item sm={12}>
            <ArrowSelect
              id="services"
              label={''}
              value={valueSelecteEntity}
              onChange={(value) => {
                setPageCount(1)
                onChangeSelectEntity(value)
              }}
              options={itemsSelectorEntity}
              textField={textFieldSelectorEntity}
              valueField="id"
              placeholder={placeholderSelector || 'Filter'}
              isClearable
              menuPlacement="top"
              showReportSetup={showReportSetup}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid container item sm={12}>
            <span className='table-scroll-reports-title-filter'>
              {messages['generatedReport_TIMEFRAME']}
            </span>
          </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid container item sm={12}>
            <ArrowSelect
                id="services"
                label={''}
                value={dateSelected}
                onChange={(value) => {
                  setPageCount(1)
                  onChangeSelectDates(value)
                }}
                options={itemsSelectDates}
                textField={textFieldSelectorDates}
                valueField="id"
                placeholder={placeholderSelector || 'Filter'}
                isClearable
                menuPlacement="top"
                showReportSetup={showReportSetup}
                loading={loading}
              />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} style={{overflowX: "auto"}}>
        <VirtualizedTable
          containerRef={refContainer}
          renderItems={renderItems}
          isNextPageLoading={loading}
          handleNewPageLoad={() => {
            const page = pageCount + 1
            handleNewPageLoad(page);
            setPageCount(page)
          }}
          data={data || []}
          total={total}
          containerClass={tableContainerClass}
          noDataMessage={messages['reportsMainRT_noDataMessage']}
          initialLoadingMessage={messages['reportsMainRT_loadingInitial']}
          showLoadingComponent={true}
          resetCache={resetCache}
          renderHeader={renderHeader}
          renderSubHeader={renderSubHeader}
          onStartIndex={onStartIndex}
          isActive={isActive}
          scrollEvent={scrollEvent}
        />
      </Grid>

    </Grid>
  );
}

export default InfiniteScrollTable;
