// dependencies
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// controls
import Table, { Column, Summary } from '../../table';
import { Icon } from '@mui/material';
import { Accordion } from 'Components';
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';

// helpers
import {
  formatDatetime,
  decimalHandler,
  getEntityType,
  getCategoryFilterType,
  getPlaceholderSelector,
  isJson,
  useHandleExportReport,
  getFilterNameByEntity, isKPH
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useSidePanelMap } from 'Core/hooks';
import moment from 'moment';
// actions
import {
  reportsMainGenerateReportUpdate,
  openFleetLandmarkModal,
  clearRedux,
  reportsMainGenerateSubEvents
} from 'Redux/actions'

import { milesToKPH } from "Core/data/Helpers";

// styles
import './stateMileageReport.scss';
import { formatOdometer } from '../../../../../../../.globals/helpers';

import NoData from '../../noData';
import { isKPHAcronym } from '../../../helper';

const StateMileageReport = (props) => {
  const { messages, report, user } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSubEvents, subEvents, timeZone } = report
  const { filterEntity, filterArray, filterType, startRange, endRange } = filters;
  const exportHandler = useHandleExportReport(report);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
  const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [stateCrossingDetails, setStateCrossingDetails] = useState([]);
  const [totalSummary, setTotalSummary] = useState([]);
  const [grandTotalSummary, setGrandTotalSummary] = useState([]);
  const [grandTotalCrossings, setGrandTotalCrossings] = useState(0);
  
  const [unitDriverSelected, setUnitDriverSelected] = useState([]);

  const reportRef = useRef();
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);
  const units = useSelector(state => state.catalogsRedux.unitsList);
  const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult);
  const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark);



  const validateOdometer = (edu, item) => {
    if (edu) {
      if (!item.ecuOdometer1 && !item.ecuOdometer2) { return true }
      else { return false }
    } else if (!item.odometer1 && !item.odometer2) {
      return true
    }
  }


  const handleEntity = () => {
    return filterEntity === "Unit" ? messages['generatedReport_unitsPerPage'] : messages['generatedReport_driversPerPage']
  }

  /***** Render methods for tables ****/
  const renderExpandableStateDetails = (selected) => {
    let currentSelected = totalSummary && totalSummary.find((u) => u.id === selected?.id);
    return (
      <Fragment>
        <Accordion
          customTitle={() => { return (<b>{messages['generatedReport_stateTotals']}</b>) }}
          className="AccordionContainer-root state-mileage-accordeon"
        >
          {currentSelected && (
            <div className="state-mileage-container">
              {currentSelected && currentSelected.summaryByState.map((item, key) => {
                let value = decimalHandler(item.mileage);
                return <Summary className="state-mileage-summary" summaryMessage={item.state || ''} value={`${value} ${messages[isKPHAcronym(item?.isKPH)]}`} key={key} />
              })}
              {<Summary summaryMessage={messages['generatedReport_stateCrossings']} value={currentSelected?.totalCrossings || 0} />}
            </div>
          )}
          {
            selected.noData && <NoData />
          }

        </Accordion>
        <Accordion
          customTitle={() => { return (<b>{messages['generatedReport_stateCrossingDetails']}</b>) }}
          className="AccordionContainer-root state-mileage-accordeon"
        >
          <div className="state-mileage-container">
            <Table
              data={stateCrossingDetails || []}
              totalItems={subEvents?.data?.total || 0}
              onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                let events = subEvents;
                events.filters = {
                  ...events.filters,
                  filterType: getCategoryFilterType(filterEntity),
                  filterArray: [selected.id],
                  startRange: selected.startDate,
                  endRange: selected.endDate,
                  page,
                  rowsPerPage: 5,
                  sortField,
                  sortOrder,
                }
                dispatch(reportsMainGenerateSubEvents(report, events))
              }}
              footerField="groupTotal"
              groupTotal={(item) => {
                let value = item.groupTotal === 1 ? 0 : item.groupTotal;
                let total = value && decimalHandler(value);
                let distance = `${total}  ${messages[isKPHAcronym(item?.isKPH)]}`
                let calculate = item.validateOdometer ? ` (*${messages['generatedReport_GPSCalculated']})` : ''
                return (
                  <Summary className="state-mileage-total-container" summaryMessage={messages["generatedReport_distance"]} value={distance + calculate || 0} />
                )
              }}
              defaultSortField="deviceId"
              defaultSortOrder="asc"
              hideNumberOfRows={true}
              numberofRows={5}
              serverSideTable={true}
              noData={selected.noData}
            >
              <Column label={messages["generatedReport_time"]} field="eventDate" noSorting>
                {(element) => {
                  return (
                    <div>
                      <span className="state-mileage-stacked">{element.unittime && formatDatetime(element.unittime, "h:mm A", timeZone)}</span>
                      <span className="state-mileage-stacked state-mileage-event-date">{element.unittime && formatDatetime(element.unittime, "MMM D", timeZone)}</span>
                    </div>
                  )
                }}
              </Column>
              <Column label={filterEntity === "Unit" ? messages['generatedReport_driver'] : messages['generatedReport_unit']} field="driverName" noSorting>
                {(element) => {
                  return <span>{filterEntity === "Unit" ? element.driverName || '' : element.deviceLabel || ''}</span>
                }}
              </Column>
              <Column label={messages["generatedReport_address"]} field="address" noSorting>
                {(element) => {
                  return (
                    <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element} />
                  )
                }}
              </Column>
              <Column label={messages["generatedReport_state"]} field="state" noSorting>
                {(element) => {
                  return <span>{element.state || ''}</span>
                }}
              </Column>
              <Column label={messages["generatedReport_odometer"]} field="validateOdometer" noSorting>
                {(element) => {
                  const odometer = formatOdometer(element);
                  return <span>{element.validateOdometer ? <span>{`${messages['reportsMainRT_notAvailable']}*`}</span> : milesToKPH(odometer, element.isKPH) || messages["reportsMainRT_notAvailable"]}</span>
                }}
              </Column>
            </Table>
          </div>
        </Accordion>
      </Fragment>
    )
  }

  const idStateDetails = (selected) => {
    if(!selected.noData){
      let currentReport = report;
      delete currentReport.subEvents
      let events = {
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: [selected.id],
          page: 1,
          rowsPerPage: 5,
          startRange: selected.startDate,
          endRange: selected.endDate
        },
        data: []
      }
      dispatch(reportsMainGenerateSubEvents(currentReport, events))
    }
  }

  useEffect(() => {
    if (subEvents?.data) {
      let list = [];
      subEvents?.data?.items && subEvents?.data?.items.map((item) => {
        let unit = units.find((u) => u.id === +item.deviceId);
        let validate = validateOdometer(unit?.useEcuOdometer, item);
        list.push({
          isKPH: item.isKPH,
          accountId: item.accountId,
          address: item.address1,
          deviceId: item.deviceId,
          deviceLabel: item.deviceLabel,
          distance: item.distance,
          driverId: item.driverId,
          driverName: item.driverName,
          ecuEngineOdometer: item.ecuOdometer1,
          landmarkName: item.landmark1Name,
          landmarkId: item.landmarkId1,
          latitude: item.latitude1,
          longitude: item.longitude1,
          engineOdometer: item.odometer1,
          state: item.state,
          stateCrossings: item.stateCrossings,
          stateTotalDistance: item.stateTotalDistance,
          unitId: item.unitId,
          unittime: item.unittime1,
          useEcuOdometer: unit?.useEcuOdometer || false,
          offsetEcuEngineOdometer: unit?.offsetEcuEngineOdometer || 0,
          offsetEngineOdometer: unit?.offsetEngineOdometer || 0,
          validateOdometer: validate
        }, {
          isKPH: item.isKPH,
          accountId: item.accountId,
          address: item.address2,
          deviceId: item.deviceId,
          deviceLabel: item.deviceLabel,
          distance: item.distance,
          driverId: item.driverId,
          driverName: item.driverName,
          ecuEngineOdometer: item.ecuOdometer2,
          landmarkName: item.landmark1Name,
          landmarkId: item.landmarkId2,
          latitude: item.latitude2,
          longitude: item.longitude2,
          engineOdometer: item.odometer2,
          state: item.state,
          stateCrossings: item.stateCrossings,
          stateTotalDistance: item.stateTotalDistance,
          unitId: item.unitId,
          unittime: item.unittime2,
          groupTotal: item.distance ? item.distance : 1,
          useEcuOdometer: unit?.useEcuOdometer || false,
          offsetEcuEngineOdometer: unit?.offsetEcuEngineOdometer || 0,
          offsetEngineOdometer: unit?.offsetEngineOdometer || 0,
          validateOdometer: validate
        })
      })
      setStateCrossingDetails(list);
    }
  }, [subEvents])

  useEffect(() => {
    if (data.summaryInfo) {
      setTotalSummary(data.summaryInfo);
    }
    if (data.grandSummaryInfo?.summaryByState) {
      setGrandTotalCrossings(data.grandSummaryInfo?.totalCrossings || 0)
      setGrandTotalSummary(data?.grandSummaryInfo.summaryByState)
    }
  }, [data])

  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
      dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
    }
  }, [loadingCreateLandMark, createLandMarkResult])

  const handleExport = (type) => {
    let currentReport = report
    currentReport.export = true;
    currentReport.exportType = type
    dispatch(reportsMainGenerateReportUpdate(currentReport, 1, 10))
  }


  return (
    <Fragment>
      <div>
        <Table
          loading={loading || loadingSubEvents}
          asyncLoading={report.asyncLoading}
          data={data.items || []}
          totalItems={data.total}
          renderExpandableRow={renderExpandableStateDetails}
          idRenderExpandableRow={idStateDetails}
          propertyToShowExpandable={'id'}
          onChangedPage={(page, rowsPerPage, newSortField, newSortOrder) => {
            let flag = true;
            dispatch(reportsMainGenerateReportUpdate(reportRef.current || report, page, rowsPerPage, newSortField, newSortOrder, flag));
            setCurrentPage(page);
            setCurrentRowsPerPage(rowsPerPage)
            setCurrentNewSortField(newSortField)
            setCurrentNewSortOrder(newSortOrder)
          }}
          defaultSortField="deviceId"
          defaultSortOrder="asc"
          footerField="groupTotal"
          noDataMessage={messages["reportsMainRT_noDataMessage"]}
          hideHeader
          classNameColumns="state-mileage-summary-expandible"
          onChangeSelector={(value) => {
            setUnitDriverSelected(value)
            let currentReport = report;
            currentReport.filters = { ...currentReport.filters, unitsSelected: value };
            dispatch(reportsMainGenerateReportUpdate(currentReport, 1, 10));
          }}
          valueSelector={unitDriverSelected}
          itemsSelector={filterArray}
          textFieldSelector={getFilterNameByEntity(filterType)}
          totalArea={() => {
            return (
              <Fragment>
                {data.items && (
                  <Accordion
                    customTitle={() => { return (<b>{messages['generatedReport_grandTotals']}</b>) }}
                    className="AccordionContainer-root state-mileage-accordeon-total"
                    defaultExpanded
                  >
                    <div className="state-mileage-container">
                      {grandTotalSummary && (
                        <Fragment>
                          {grandTotalSummary && grandTotalSummary.map((item, key) => {
                            let value = decimalHandler(item.mileage);
                            return <Summary summaryMessage={item.state || ''} value={`${value} ${messages[isKPHAcronym(item?.isKPH)]}`} key={key} />
                          })}
                        </Fragment>
                      )}
                      <Summary summaryMessage={messages['generatedReport_stateCrossings']} value={grandTotalCrossings || 0} />
                    </div>
                  </Accordion>
                )}
              </Fragment>
            )
          }}
          placeholderSelector={getPlaceholderSelector(filterType, messages)}
          {...exportHandler}
        >
          <Column field="idLabel" noSorting>
            {(element) => {
              const type = getEntityType(report, messages)
              return <span><strong>{type}: </strong> {element.idLabel || ''}</span>
            }}
          </Column>
          <Column field="eventDate" noSorting>
            {(element) => {
              const localTimeStart = moment.utc(startRange).local().format();
              const localTimeEnd = moment.utc(endRange).local().format();
              return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
              </div>);
            }}
          </Column>
        </Table>
      </div>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default StateMileageReport;
