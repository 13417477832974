export default {
    /*
     * Columnas
     */
    behavior_riskFactors: 'Factores de Riesgo',
    behavior_riskFactorsPoints: 'Puntos de Riesgo',
    behavior_category: 'Categoría',
    behavior_metric: 'Métrica',
    behavior_warningPoints: 'Advertencia (puntos)',
    behavior_violationPoints: 'Violación (puntos)',
    /*
     * Tipos de Métricas
     */

    behavior_aggression: 'Agresión',
    behavior_HardBrake: 'Frenazo Fuerte',
    behavior_HardTurn: 'Giro Fuerte',
    behavior_RapidAcceleration: 'Aceleración Rápida',
    behavior_PotentialIncident: 'Incidente Potencial',
    behavior_driverMonitoringSystem: 'Sistema de Monitoreo del Conductor (DMS)',
    behavior_UsingPhone: 'Uso del Teléfono',
    behavior_Smoking: 'Fumar',
    behavior_Drowsiness: 'Somnolencia',
    behavior_NoSeatbelt: 'Sin Cinturón de Seguridad',
    behavior_DayDreaming: 'Soñar Despierto',
    behavior_Yawn: 'Bostezo',
    behavior_Asleep: 'Dormido',
    behavior_Distracted: 'Distraído',
    behavior_CameraObstructed: 'Cámara Obstruida',
    behavior_IdleNoDriver: 'Motor en Marcha sin Conductor',
    behavior_PassengersDetection: 'Detección de Pasajeros',
    behavior_advancedDriverAssistanceSystem: 'Sistema Avanzado de Asistencia al Conductor (ADAS)',
    behavior_ForwardCollisionWarning: 'Advertencia de Colisión Frontal',
    behavior_CloseFollowing: 'Seguimiento Cercano',
    behavior_Tailgating: 'Conducir Muy Cerca',
    behavior_LaneDeparture: 'Salida de Carril',
    behavior_StopSignViolation: 'Violación de Señal de Alto',
    behavior_PedestrianDetection: 'Detección de Peatones',
    behavior_CyclistDetection: 'Detección de Ciclistas',
    behavior_vehicleSpeed: 'Velocidad del Vehículo',
    behavior_speedLabel: 'Velocidad > ',
    behavior_speedingOverPosted: 'Exceso de Velocidad sobre Límite',
    behavior_speedOverLow: '1-5 (Bajo)',
    behavior_speedOverMedium: '6-10 (Medio)',
    behavior_speedOverHigh: '11+ (Alto)',
    /*
     * Tooltip
     */
    behavior_tooltip_riskFactors: 'Mueva el control deslizante verde a continuación para establecer cuántos puntos por día un conductor o vehículo puede acumular para ser considerado "Bajo Riesgo". Use el control deslizante amarillo para establecer esto para "Riesgo Medio" y todos los demás que superen el máximo amarillo serán considerados "Alto Riesgo".',
    behavior_tooltip_TotalPointAllowance: "Este número debe establecerse en la cantidad total de puntos que se pueden acumular hoy sin afectar negativamente la puntuación del conductor.",
    behavior_tooltip_RapidAcceleration: "La Aceleración Rápida se activa cuando un vehículo acelera a más de 5.6 MPH/s durante 1.5s-3.0s.",
    behavior_tooltip_HardBrake: "El Frenazo Fuerte se detecta cuando un vehículo desacelera a más de 4 MPH/s durante 1.5s-3.0s.",
    behavior_tooltip_SuddenStop: "El Incidente Potencial se detecta cuando se ejercen 9 G’s de fuerza durante 1.5s-3.0s.",
    behavior_tooltip_HardTurn: "El Giro Fuerte se detecta cuando la dirección del viaje basada en el rumbo del vehículo se desvía más de 4 MPH/s durante 1.5-3.0 segundos.",
    behavior_tooltip_speedLabelFinal: 'Se activa si se registra una velocidad superior a 100 MPH.',
    behavior_tooltip_speedLabel: "Se activa si se registra una velocidad superior a",
    behavior_tooltip_speedLabelComplement: "esto no se registra si se supera",
    behavior_tooltip_UsingPhone: 'Se activa en casos donde un conductor está usando su teléfono móvil mientras opera el vehículo.',
    behavior_tooltip_Smoking: 'Se activa en casos donde un conductor está fumando mientras opera el vehículo.',
    behavior_tooltip_Drowsiness: 'Se activa en casos donde un conductor muestra signos de somnolencia o fatiga mientras opera el vehículo.',
    behavior_tooltip_NoSeatbelt: 'Se activa en casos donde un conductor o pasajero no usa el cinturón de seguridad mientras el vehículo está en movimiento.',
    behavior_tooltip_DayDreaming: 'Se activa en casos donde un conductor muestra signos de distracción o de estar soñando despierto mientras opera el vehículo.',
    behavior_tooltip_Yawn: 'Se activa en casos donde un conductor es detectado bostezando mientras opera el vehículo.',
    behavior_tooltip_Asleep: 'Se activa en casos donde un conductor es detectado dormido mientras opera el vehículo.',
    behavior_tooltip_Distracted: 'Se activa en casos donde un conductor muestra signos de distracción mientras opera el vehículo.',
    behavior_tooltip_CameraObstructed: 'Se activa en casos donde la cámara utilizada para el monitoreo del conductor o otras funciones de seguridad está obstruida.',
    behavior_tooltip_IdleNoDriver: 'Se activa en casos donde el motor del vehículo está en marcha y no hay conductor presente.',
    behavior_tooltip_PassengersDetection: 'Se activa en casos donde hay pasajeros en el vehículo, pero los cinturones de seguridad no están abrochados.',
    behavior_tooltip_ForwardCollisionWarning: 'Alerta al conductor de una posible colisión con otro vehículo u objeto adelante.',
    behavior_tooltip_CloseFollowing: 'Alerta al conductor cuando está siguiendo demasiado de cerca al vehículo de adelante.',
    behavior_tooltip_Tailgating: 'Detecta cuando un vehículo sigue demasiado de cerca a otro vehículo, un comportamiento conocido como “hacer cola”.',
    behavior_tooltip_LaneDeparture: 'Alerta al conductor cuando el vehículo se desvía de su carril sin la intervención del conductor.',
    behavior_tooltip_StopSignViolation: 'Detecta cuando un vehículo no se detiene en una señal de alto.',
    behavior_tooltip_PedestrianDetection: 'Detecta peatones en el camino del vehículo y alerta al conductor sobre posibles colisiones.',
    behavior_tooltip_CyclistDetection: 'Detecta ciclistas en el camino del vehículo y alerta al conductor sobre posibles colisiones.',
}
