import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  USER_ALERTS_GET_ALERTS
} from 'Redux/actionTypes';

import {
  searchSystemAlertLogsResponse
} from 'Redux/actions';

function* searchSystemAlertLogsRequest({ payload }) {
  try {
    const { data } = payload;

    // Suported entities: All, Unit, UnitGroup, Driver, DriverGroup
    const query = `
      query searchSystemAlertLogs (
        $alertTypes: [enumAlertType] 
        $unitTimeRange: AWSDateTimeRange
        $entities: [EntityInput]
        $severity: Severity
        $openSearch: String
        $limit: Int
        $offset: Int
      ) {
        Alerts: searchSystemAlertLogs (
          alertTypes: $alertTypes,
          unitTimeRange: $unitTimeRange,
          entities: $entities,
          severity: $severity,
          openSearch: $openSearch, 
          limit: $limit, 
          offset: $offset
        ) {
          items {
            id,
            systemAlertName,
            severity,
            unitId,
            unit {
              label
            }
            unitTime,
            driverId,
            driverName,
            latitude,
            longitude,
            address,
            landmarkId,
            landmarkName,
            read,
            deviceReadingId
          }
          total
        }
      }
    `;

    const response = yield call(
      clientQuery,
      query,
      {
        alertTypes: data?.alertTypes || [],
        unitTimeRange: data?.unitTimeRange || null,
        entities: data?.entities || [],
        severity: data?.severity && data?.severity != 'all' ? data?.severity : null,
        openSearch: data?.openSearch || "", 
        limit: data?.limit || 25, 
        offset: data?.offset || 0
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (response && response.Alerts) {
      yield put(searchSystemAlertLogsResponse({
        data: response?.Alerts?.items,
        total: response?.Alerts?.total,
        resetSearch: data?.resetSearch,
        error: false
      }));
    } else {
      throw 'Not system alert logs data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'src/core/views/userAlerts/redux/saga', 'searchSystemAlertLogsRequest');
    yield put(searchSystemAlertLogsResponse({ data: [], resetSearch: true, total: 0, error: true }));
  }
}


function* watchSearchSystemAlertLogsRequest() {
  yield takeEvery(USER_ALERTS_GET_ALERTS, searchSystemAlertLogsRequest);
}


export default function* rootSaga() {
  yield all([
    fork(watchSearchSystemAlertLogsRequest)
  ]);
}
