export default {
    fsmRoute_routesTracker_modalStopVisited_title: "Paradas Visitadas", 
    fsmRoute_routesTracker_modalStopVisited_close: "Cerrar", 
    fsmRoute_routesTracker_modalStopVisited_name: "Nombre", 
    fsmRoute_routesTracker_modalStopVisited_status: "Estado", 
    fsmRoute_routesTracker_modalStopVisited_departureTime: "Hora de Salida", 
    fsmRoute_routesTracker_modalStopVisited_arrivalTime: "Hora de Llegada", 
    fsmRoute_routesTracker_modalStopVisited_pending: "Pendiente", 
    fsmRoute_routesTracker_modalStopVisited_completed: "Completado", 
    fsmRoute_routesTracker_modalStopVisited_progress: "Progreso", 
    fsmRoute_routesTracker_modalStopVisited_unassigned: "Sin Asignar", 
};