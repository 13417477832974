export default {
    fsmRoute_routesTracker_modalAdd_title: "Programar una Ruta",   
    fsmRoute_routesTracker_modalAdd_close: "Cerrar",    
    fsmRoute_routesTracker_modalAdd_useTemplate: "Utilizar una plantilla de ruta",  
    fsmRoute_routesTracker_modalAdd_template: "Pantilla",  
    fsmRoute_routesTracker_modalAdd_select_template: "Seleccione una platilla",  
    fsmRoute_routesTracker_modalAdd_select_driver_select: "Seleccione un conductor", 
    fsmRoute_routesTracker_modalAdd_select_driver: "Conductor", 
    fsmRoute_routesTracker_modalAdd_select_date: "Seleccione el día", 
    fsmRoute_routesTracker_modalAdd_next: "Siguiente", 
};