export default {
    "cameraSetup_on_full": "Encendido",
    "cameraSetup_off_full": "Apagado",
    "cameraSetup_trigger": "Disparador",
    "cameraSetup_warning": "Advertencia",
    "cameraSetup_audio": "Audio",
    "cameraSetup_collision_warning": "Aviso de Colisión",
    "cameraSetup_tailgating": "Seguir de Cerca",
    "cameraSetup_lane_departure": "Salida de Carril",
    "cameraSetup_stop_sign_violation": "Violación de la Señal de Alto",
    "cameraSetup_using_phone": "Usando el Teléfono",
    "cameraSetup_smoking": "Fumando",
    "cameraSetup_drowsy": "Somnoliento",
    "cameraSetup_no_seatbelt": "Sin Cinturón de Seguridad",
    "cameraSetup_daydreaming": "Soñador",
    "cameraSetup_yawn": "Bostezo",
    "cameraSetup_asleep": "Dormido",
    "cameraSetup_distracted": "Distraído",
    "cameraSetup_no_driver": "Sin Conductor",
    "cameraSetup_idle_no_driver": "Inactivo sin Conductor",
    "cameraSetup_rapid_acceleration": "Aceleración Rápida",
    "cameraSetup_hard_brake": "Freno Duro",
    "cameraSetup_hard_turn": "Giro Brusco",
    "cameraSetup_power_off": "Apagado",
    "cameraSetup_heavy_duty": "Carga Pesada",
    "cameraSetup_light_truck": "Camión ligero (pickup, SUV)",
    "cameraSetup_sedan": "Sedán",
    "cameraSetup_normal": "Normal",
    "cameraSetup_upside_down": "Al Revés",
    "cameraSetup_rotate_right": "Girar a la Derecha",
    "cameraSetup_rotate_left": "Girar a la Izquierda",
    "cameraSetup_vehicle_class": "Clase de Vehículo",
    "cameraSetup_driver_facing_camera": "Cámara Orientada Hacia el Conductor",
    "cameraSetup_driver_id": "ID de Conductor",
    "cameraSetup_passenger_detection": "Detección de Pasajeros",
    "cameraSetup_camera_orientation": "Orientación de la Cámara",
    "cameraSetup_aux_one_orientation": "Orientación Aux 1",
    "cameraSetup_aux_two_orientation": "Orientación Aux 2",
    "cameraSetup_volume": "Volumen",
    "cameraSetup_on": "Enc",
    "cameraSetup_off": "Apag",
    "cameraSetup_are_you_sure_want_to_apply": "¿Estás seguro de querer aplicar estos cambios?",
    "cameraSetup_clicking_apply": "Al hacer clic en Aplicar se enviará una actualización de la configuración a la(s) cámara(s) seleccionada(s). Es posible que la cámara no acepte esta actualización hasta que se complete un viaje.",
    "cameraSetup_the_following_changes": "Se aplicarán los siguientes cambios",
    "cameraSetup_normal": "Normal",
    "cameraSetup_upsidedown": "Al Revés",
    "cameraSetup_rotate_right": "Girar a la Derecha",
    "cameraSetup_rotate_left": "Girar a la Izquierda",
    "cameraSetup_general": "General",
    "cameraSetup_events": "Eventos",
    "cameraSetup_adas": "ADAS",
    "cameraSetup_dms": "DMS",
    "cameraSetup_camera_setup": "Configuración de la Cámara",
    "cameraSetup_success_messages": "Se ha actualizado la configuración de la cámara",
    "cameraSetup_error_messages": "No se puede actualizar la configuración de la cámara, compruebe la configuración e inténtelo de nuevo",
    "cameraSetup_count": "Cantidad",
    "cameraSetup_trigger_description": "Activa o desactiva el registro de eventos",
    "cameraSetup_warning_description": "Activa o desactiva los avisos de la cámara, agrupados por el número de eventos registrados",
    "cameraSetup_audio_description": "Activa o desactiva los sonidos de eventos en la cabina",
    "cameraSetup_dms_off": "DMS desactivado, contacta con soporte",
    "cameraSetup_adas_off": "ADAS desactivado, contacta con soporte",
    "cameraSetup_bulk_settings": "Configuración Masiva",
    "cameraSetup_does_not_support": "No compatible",
    "cameraSetup_no_data": "No hay configuración disponible para esta cámara.",
    "cameraSetup_cameras": "Cámaras",
    "cameraSetup_all_cameras": "Todas las Cámaras",
    "cameraSetup_deviceModel": "Modelo de Dispositivo",
    "cameraSetup_config": "Configuración",
    "cameraSetup_summary": "Resumen",
    "cameraSetup_select_device_model": "Seleccione el modelo de los dispositivos que desea configurar",
    "cameraSetup_no_camera_available": "No hay cámaras disponibles para las etiquetas seleccionadas",
    "cameraSetup_tags": "Etiquetas",
    "cameraSetup_pedestrian_collision": "Colisión de Peatones",
    "cameraSetup_cyclist_collision": "Colisión con Ciclista",
    "cameraSetup_dms_lite": "DMS LIGERO",
    "cameraSetup_passengers_no_seatbelt": "Pasajeros sin Cinturón",
    "cameraSetup_requires_dms_camera": "Requiere cámara DMS+",
}