import routesManagedRedux from "../../views/templates/redux/slice";
import routesManagerDeleteRedux from "../../views/templates/components/deleteRoute/redux/slice";
import routesManagedDirectionRedux from '../../views/templates/components/modalDirections/redux/slice';
import routesManagedCreateEditRoutePointRedux from '../../views/templates/components/createEditRoutePoint/redux/slice';
import routesManagedCreateEditRouteModalMainRedux from  '../../views/templates/components/createEditRouteModalMain/redux/slice';
import routesManagerEditPanelRouteRedux from  '../../views/templates/components/editPanelRoute/redux/slice';
import routesManagerEditPanelRouteEtaRoutesRedux from '../../views/templates/components/etaRoutes/redux/slice';
import routesTemplatesAlertWatchers from '../../views/templates/components/alertWatchers/redux/slice';  
import routesManagerDeleteRoutePoint from '../../views/templates/components/deleteRoutePoint/redux/slice';
import routesManagerModalMarkerRouteRedux from '../../views/templates/components/modalMarkerRoute/redux/slice';
import routesTemplateModalCloneRoute from '../../views/templates/components/modalCloneRoute/redux/slice';

import routesTrackerRedux from '../../views/routesTracker/redux/slice'
import routesTrackerModalFilterRedux from '../../views/routesTracker/components/modalFilters/redux/slice'
import routesTrackerModalStopInformationRedux from '../../views/routesTracker/components/modalStopInformation/redux/slice'
import routesTrackerModalStopsVisitedRedux from '../../views/routesTracker/components/modalStopsVisited/redux/slice'
import routesTrackerModalAddRoutesTrackerRedux from '../../views/routesTracker/components/modalAddRoutesTracker/redux/slice';



export default {  
    routesManagedRedux,
    routesManagerDeleteRedux,
    routesManagedDirectionRedux,
    routesManagedCreateEditRoutePointRedux,
    routesManagedCreateEditRouteModalMainRedux,
    routesManagerEditPanelRouteRedux,
    routesManagerEditPanelRouteEtaRoutesRedux,
    routesTemplatesAlertWatchers,
    routesManagerDeleteRoutePoint,
    routesManagerModalMarkerRouteRedux,
    routesTemplateModalCloneRoute,

    routesTrackerRedux,
    routesTrackerModalFilterRedux,
    routesTrackerModalStopInformationRedux,
    routesTrackerModalStopsVisitedRedux,
    routesTrackerModalAddRoutesTrackerRedux
};