export default {
    /*
     * Columns
     */
    behavior_riskFactors: 'Risk Factors',
    behavior_riskFactorsPoints: 'Risk Points',
    behavior_category: 'Category',
    behavior_metric: 'Metric',
    behavior_warningPoints: 'Warning (points)',
    behavior_violationPoints: 'Violation (points)',
    /*
     * Metric Types
     */

    behavior_aggression: 'Aggression',
    behavior_HardBrake: 'Hard Brake',
    behavior_HardTurn: 'Hard Turn',
    behavior_RapidAcceleration: 'Rapid Acceleration',
    behavior_PotentialIncident: 'Potential Incident',
    behavior_driverMonitoringSystem: 'Driver Monitoring System (DMS)',
    behavior_UsingPhone: 'Using Phone',
    behavior_Smoking: 'Smoking',
    behavior_Drowsiness: 'Drowsiness',
    behavior_NoSeatbelt: 'No Seatbelt',
    behavior_DayDreaming: 'Day Dreaming',
    behavior_Yawn: 'Yawn',
    behavior_Asleep: 'Asleep',
    behavior_Distracted: 'Distracted',
    behavior_CameraObstructed: 'Camera Obstructed',
    behavior_IdleNoDriver: 'Idle No Driver',
    behavior_PassengersDetection: 'Passengers Detection',
    behavior_advancedDriverAssistanceSystem: 'Advanced Driver Assistance System (ADAS)',
    behavior_ForwardCollisionWarning: 'Forward Collision Warning',
    behavior_CloseFollowing: 'Close Following',
    behavior_Tailgating: 'Tailgating',
    behavior_LaneDeparture: 'Lane Departure',
    behavior_StopSignViolation: 'Stop Sign Violation',
    behavior_PedestrianDetection: 'Pedestrian Detection',
    behavior_CyclistDetection: 'Cyclist Detection',
    behavior_vehicleSpeed: 'Vehicle Speed',
    behavior_speedLabel: 'Speed > ',
    behavior_speedingOverPosted: 'Speeding Over Posted',
    behavior_speedOverLow: '1-5 (Low)',
    behavior_speedOverMedium: '6-10 (Medium)',
    behavior_speedOverHigh: '11+ (High)',
    /*
     * Tooltip
     */
    behavior_tooltip_riskFactors: 'Move the green slider below to set how many points per-day a driver or vehicle can accrue to be considered “Low Risk”. Use the yellow slider to set this for “Medium Risk” and all others that exceed the yellow maximum will be considered "High Risk".',
    behavior_tooltip_TotalPointAllowance:"This number should be set to the total amount of points that can be accumulated today without negatively impacting the driver's score.",
    behavior_tooltip_RapidAcceleration:"Rapid Acceleration triggers when a vehicle is accelerating in excess of a threshold of 5.6 MPH/s for 1.5s- 3.0s.",
    behavior_tooltip_HardBrake:"Hard braking is detected based on a vehicle slowing in excess of 4 MPH/s for 1.5s - 3.0s.",
    behavior_tooltip_SuddenStop:"Potential Incident is detected when 9 G’s of force are exerted over 1.5s-3.0s.",
    behavior_tooltip_HardTurn:"A hard turn is detected when the direction of travel based on the vehicles heading deviates in by 4 MPH/s for 1.5-3.0 seconds.",
    behavior_tooltip_speedLabelFinal:"Triggers if a speed of over 100 MPH is recorded.",
    behavior_tooltip_speedLabel:"Triggers if a speed of over",
    behavior_tooltip_speedLabelComplement:"is recorded, this is not recorded if exceeding",
    behavior_tooltip_UsingPhone: 'Activates in  instances where a driver is using their mobile phone while operating a vehicle',
    behavior_tooltip_Smoking: 'Activate in  instances where a driver is detected smoking while operating a vehicle',
    behavior_tooltip_Drowsiness: 'Activate in  instances where a driver is exhibiting signs of drowsiness or fatigue while operating a vehicle',
    behavior_tooltip_NoSeatbelt: 'Activate in  instances where a driver or passenger is not wearing a seatbelt while the vehicle is in motion.',
    behavior_tooltip_DayDreaming: 'Activate in  instances where a driver is exhibiting signs of daydreaming or inattention while operating a vehicle.',
    behavior_tooltip_Yawn: 'Activate in  instances where a driver is detected yawning while operating a vehicle.',
    behavior_tooltip_Asleep: 'Activate in  instances where a driver is detected asleep while operating a vehicle.',
    behavior_tooltip_Distracted: 'Activate in  instances where a driver is exhibiting signs of distraction while operating a vehicle.',
    behavior_tooltip_CameraObstructed: 'Activate in  instances where the camera used for driver monitoring or other safety features is obstructe',
    behavior_tooltip_IdleNoDriver: `Activate in  instances where the vehicle's engine is idling while there is no driver present`,
    behavior_tooltip_PassengersDetection: 'Activate in  instances where there are passengers in the vehicle, but the seatbelts are not buckled',
    behavior_tooltip_ForwardCollisionWarning: 'Alerts the driver of an impending collision with another vehicle or object ahead.',
    behavior_tooltip_CloseFollowing: 'Alerts the driver when they are following too closely to the vehicle ahead.',
    behavior_tooltip_Tailgating: 'Detects when a vehicle is following too closely behind another vehicle, a behavior known as tailgating.',
    behavior_tooltip_LaneDeparture: `Alerts the driver when the vehicle is drifting out of its lane without the driver's input.`,
    behavior_tooltip_StopSignViolation: 'Detects when a vehicle fails to stop at a stop sign.',
    behavior_tooltip_PedestrianDetection: 'Detects pedestrians in the path of the vehicle and alerts the driver to potential collisions.',
    behavior_tooltip_CyclistDetection: 'Detects cyclists in the path of the vehicle and alerts the driver to potential collisions.',
}
