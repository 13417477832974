export default {
    settingsCameraManage_title: "Administración de Camaras",
    settingsCameraManage_recordId: "Id del Registro",
    settingsCameraManage_imei: "IMEI",
    settingsCameraManage_esn: "ESN",
    settingsCameraManage_pairedUnit: "Unidad Emparejada",
    settingsCameraManage_label: "Etiqueta",
    settingsCameraManage_unpaired: "No emparejado",
    settingsCameraManage_editCameraPairing: "Editar emparejamiento de la cámara",
    settingsCameraManage_showUnpairedOnly: "Mostrar sólo no emparejados",
    settingsCameraManage_editCamera: "Editar",
    settingsCameraManage_cameraSetup: "Configuración de la Cámara",
    settingsCameraManage_sdCardError: "Error de tarjeta SD",
    settingsCameraManage_warnings: "Advertencias",
    settingsCameraManage_bulk_camera_config: "Configuración masiva de las cámaras",
    settingsCameraManage_device_model: "Modelo del Dispositivo",
    settingsCameraManage_4K: "VidFleet AI+",
    settingsCameraManage_360: "VidFleet 1",
    settingsCameraManage_same_device_model: "El modelo de dispositivo de las cámaras debe ser el mismo",
    settingsCameraManage_cameras_must_be_vidfleet: "Las cámaras deben ser VidFleet",
    settingsCameraManage_camara_as_gps: "Cámara como GPS",
};