export const CATALOG_LOAD = 'CATALOG_LOAD';
export const CATALOG_LOAD_RESULT = 'CATALOG_LOAD_RESULT';

export const CATALOG_INITIAL = 'CATALOG_INITIAL';
export const CATALOG_LOADINGS = 'CATALOG_LOADINGS';
export const CATALOG_INITIAL_RESULT = 'CATALOG_INITIAL_RESULT';

export const CATALOG_PROCESS_ITEM = 'CATALOG_PROCESS_ITEM';
export const CATALOG_PROCESS_ITEMS = 'CATALOG_PROCESS_ITEMS';

export const CATALOG_INITIAL_REFRESH = 'CATALOG_INITIAL_REFRESH';
export const CATALOG_INITIAL_REFRESH_RESULT = 'CATALOG_INITIAL_REFRESH_RESULT';

export const CATALOG_SYNC_PUSH_NOTIFICATIONS = 'CATALOG_SYNC_PUSH_NOTIFICATIONS';
export const CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT = 'CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT';

export const CREATE_ASYNC_REPORTS = 'CREATE_ASYNC_REPORTS';
export const CATALOG_UPDATE_ITEM = 'CATALOG_UPDATE_ITEM'

export const CATALOG_INITIAL_GET_UNIT = 'CATALOG_INITIAL_GET_UNIT';
export const CATALOG_INITIAL_GET_UNIT_RESULT = 'CATALOG_INITIAL_GET_UNIT_RESULT';
export const CATALOG_ADD_ITEM = 'CATALOG_ADD_ITEM';
export const CATALOG_REMOVE_ITEM = 'CATALOG_REMOVE_ITEM';
export const CATALOG_SET_URLS_DOWNLOAD = 'CATALOG_SET_URLS_DOWNLOAD';
