export default {
    fsmRoute_alertWatchers_route_recipients: "Destinatarios",
    fsmRoute_alertWatchers_route_recipientscopy: "Copiar destinatarios ETA",
    fsmRoute_alertWatchers_route_contactMethod: "Elegir método de contacto:",
    fsmRoute_alertWatchers_route_copy: "Copiar",
    fsmRoute_alertWatchers_route_info: "Permite notificar a los destinatarios sobre eventos de la ruta, tales como desvíos de ruta, visitas a paradas y otras diferencias configuradas en el módulo de alertas.",
    fsmRoute_alertWatchers_route_alert: "Activar Alertas",
    fsmRoute_alertWatchers_route_alert_info: "Al activar esta función, las alertas se enviarán de acuerdo al tipo de evento configurado.",
    fsmRoute_alertWatchers_route_events: "Eventos",
    fsmRoute_alertWatchers_route_events_select: "Seleccionar Eventos",
    fsmRoute_alertWatchers_route_events_RouteDeviation: "Desvío de Ruta",
    fsmRoute_alertWatchers_route_events_StartRoute: "Inicio de Ruta",
    fsmRoute_alertWatchers_route_events_EndRoute: "Finalizar Ruta",
    fsmRoute_alertWatchers_route_events_noevent: "Evento no identificado",
};