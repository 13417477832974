// Dependencies
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Components
import { Pill } from "Components";

// Styles
import colors from 'Assets/sass/core/vars.scss';
// International
import { useIntl } from "react-intl";

const SeverityOptions = (props) => {
  const {
    label,
    onChange,
    selectedButtons,
    multiple,
    all,
    customStyle
  } = props;

  const { messages, locale } = useIntl();

  const configButtons = [
    {
      id: 'Low',
      name: messages['alertsNewAlert_LowTitle'],
      color: 'green',
    },
    {
      id: 'Medium',
      name: messages['alertsNewAlert_MediumTitle'],
      color: 'orange',
    },
    {
      id: 'High',
      name: messages['alertsNewAlert_HighTitle'],
      color: 'red',
    }
  ];

  const [buttons, setButtons] = useState(configButtons);

  useEffect(() => {
    if (all) {
      setButtons([{
        id: 'All',
        name: messages['alertsNewAlert_AllTitle'],
        color: 'black',
      }, ...buttons])
    }
  }, [])

  useEffect(()=> {
    setButtons(configButtons)
  },[locale])

  return (
    <Grid container direction={'row'} style={{...customStyle, padding: "10px 0px" }}>
      <Grid item sm={12} style={{ paddingBottom: 6 }}>
        <label className='label-gtc-rc'>{label}</label>
      </Grid>
      {buttons.map((item, key) => {
        const { name, color, id } = item;

        return (
          <Grid item sm={all?3:4} style={{ padding: 2 }} key={key}>
            <Pill
              style={{ width: '100%' }}
              item={{ name, color: colors[color], id }}
              outlined={!selectedButtons.find((selectedButton) => selectedButton.id == id)} // true if selected, false if not selected.
              onClick={(item) => {
                if (multiple) {
                  const buttonIndex = selectedButtons.findIndex((selectedButton) => selectedButton.id == item.id);
                  if (buttonIndex === -1) {
                    onChange([...selectedButtons, item]);
                  } else {
                    selectedButtons.splice(buttonIndex, 1);
                    onChange(selectedButtons);
                  }
                } else {
                  onChange(item.id);
                }
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
};

SeverityOptions.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedButtons: PropTypes.array,
  multiple: PropTypes.bool,
  all: PropTypes.bool,
};

SeverityOptions.defaultProps = {
  label: '',
  multiple: false,
  selectedButtons: [],
  all: false
};

export default SeverityOptions;
