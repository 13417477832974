import {convertToKPH} from "Core/data/Helpers";
import colors from 'Assets/sass/core/vars.scss';

export const BEHAVIOR_DATE_TODAY = 'today'
export const CONDITION_REPORT_DEFAULT = {
    reportName: "Behavior Report",
    excludeEmptyTables: true,
    UIParams: "{\"lang\":\"en\",\"id\":1733250039560.816,\"type\":\"behaviorReport\",\"loading\":false,\"data\":[],\"timeZone\":\"America/Bogota\",\"filters\":{\"filterEntity\":\"Driver\",\"filterType\":\"driverId\",\"filterArray\":[{\"id\":-99,\"type\":\"Driver\"}],\"startRange\":\"2024-07-01T05:00:00Z\",\"endRange\":\"2024-10-01T04:59:59Z\",\"otherFilters\":{\"advancedFilter\":0,\"speed\":0,\"speedLimitDelta\":0,\"minute\":0,\"checkIdleProductive\":false,\"excludeIdle\":false,\"form\":1,\"formView\":\"all\",\"event\":\"all\",\"geofenceBaseView\":\"all\",\"landmarkBaseView\":\"all\",\"risk\":\"\"},\"includeInactiveDrivers\":true},\"export\":true,\"exportType\":\"pdf\",\"asyncLoading\":true,\"loadSync\":false,\"sendByEmail\":false,\"emailsTo\":[\"\"],\"name\":\"Behavior Report\",\"categoryName\":\"driverId\",\"timeframeName\":\"Last Quarter\",\"delayed\":\"2024-12-03T18:20:39.548Z\",\"excludeEmptyTables\":true,\"dataResultPath\":null,\"createdOn\":null,\"userId\":null,\"isAll\":true,\"reportTimeRanges\":[],\"allEntities\":[],\"durationFormat\":\"DDhhmmss\"}",
    delayed: null,
    dataResultPath: null,
    timeZone: "America/Bogota",
    action: "getDataAsync",
    category: {
        field: 'deviceId',
        categoryName: 'deviceId',
        entities: [
            {
                id: -99,
                type: 'Unit'
            }
        ],
        includeInactiveDrivers: true
    },
    dateRange: {
        fields: ["unitTime"],
        GTE: "2023-10-19T05:00:00Z",
        LTE: "2023-10-20T04:59:59Z",
        timeframeName: "Today",
        reportTimeRanges: [],
    },
    sessionId: sessionStorage.getItem("sessionId"),
    stringValues: {
        values: []
    },
    sortField: {
        field: 'deviceId',
        order: "ASC"
    },
    exportOptions: {
        sendByEmail: false,
        emailsTo: [""],
        format: {
            fileFormat: 'pdf',
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
            durationFormat: "DDhhmmss"
        },
        culture: "en",
        timeZone: "America/Bogota",
        folderName: "px-customer-reports",
        filePrefixName: "behaviorReport",
        columns: []
    }
};

export const getOptionsSelectorDate = (messages) => {
    return [
        {
            id: BEHAVIOR_DATE_TODAY,
            label: messages['behavior_dateToday']
        },
        {
            id: 'yesterday',
            label: messages['behavior_dateYesterday']
        },
        {
            id: 'last7days',
            label: messages['behavior_dateLast7days']
        },
        {
            id: 'last15days',
            label: messages['behavior_dateLast15days']
        },
        {
            id: 'last30Days',
            label: messages['behavior_dateLast30Days']
        },
        {
            id: 'custom',
            label: messages['behavior_dateCustom']
        }
    ]
}

export const refactorDataMetrics = (items, isKph) => {
    const newItems = []
    const separateCamelCase = (input) => {
        return input.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    const sortByMetric = (list) => {
        return list.sort((a, b) => {
            const getNumericValue = (metric) => {
                const match = metric.match(/(\d+)/);
                return match ? parseInt(match[0], 10) : 0;
            };

            const valueA = getNumericValue(a.metric);
            const valueB = getNumericValue(b.metric);

            return valueA - valueB;
        });
    }

    for (const row of items) {
        let newItem;
        if (row.metrics?.length) {
            newItem = {}
            const result = [];

            // Process aggression metrics
            const aggressionMetrics = sortByMetric(row.metrics.filter(item => item.category === 'aggression'));
            const driverMonitoringSystemMetrics = sortByMetric(row.metrics.filter(item => item.category === 'driverMonitoringSystem'));
            const advancedDriverAssistanceSystemMetrics = sortByMetric(row.metrics.filter(item => item.category === 'advancedDriverAssistanceSystem'));

            // Process speed metrics
            const speedMetrics = sortByMetric(row.metrics.filter(item => item.category === "vehicleSpeed").map(item => {
                const metric = `Speed > ${isKph ? convertToKPH(item.metricValue) : item.metricValue}: `
                return {...item, metric, isEcu: item.metric.includes('ECU')};
            }));

            // Process speedOver metrics
            const speedOverMetrics = sortByMetric(row.metrics.filter(item => item.category === "speedingOverPosted").map(item => {
                const value = isKph ? convertToKPH(item.metricValue) : item.metricValue;
                let metric = item.metric;
                if (value <= 5) {
                    metric = `1-5 (Low):`
                } else if (value <= 10) {
                    metric = `6-10 (Medium):`
                } else if (value > 11) {
                    metric = `11+ (High):`
                }
                return {...item, metric, isEcu: item.metric.includes('ECU')};
            }));

            // Determine the maximum length
            const maxLength = Math.max(aggressionMetrics.length, driverMonitoringSystemMetrics.length, advancedDriverAssistanceSystemMetrics.length, speedMetrics.length, speedOverMetrics.length);

            // Combine results
            for (let i = 0; i < maxLength; i++) {
                const aggression = aggressionMetrics[i];
                const driverMonitoringSystem = driverMonitoringSystemMetrics[i];
                const advancedDriverAssistanceSystem = advancedDriverAssistanceSystemMetrics[i];
                const speed = speedMetrics[i];
                const speedOver = speedOverMetrics[i];

                result.push({
                    aggression: aggression ? `${separateCamelCase(aggression.metric)}: ${aggression.events}` : '',
                    driverMonitoringSystem: driverMonitoringSystem ? `${separateCamelCase(driverMonitoringSystem.metric)}: ${driverMonitoringSystem.events}` : '',
                    advancedDriverAssistanceSystem: advancedDriverAssistanceSystem ? `${separateCamelCase(advancedDriverAssistanceSystem.metric)}: ${advancedDriverAssistanceSystem.events}` : '',
                    speed: speed ? `${speed.metric} ${speed.events}` : '',
                    speedIsEcu: speed?.isEcu,
                    speedOver: speedOver ? `${speedOver.metric} ${speedOver.events}` : '',
                    speedOverIsEcu: speedOver?.isEcu
                });
            }

            newItem = {
                metrics: result,
            };
        }
        const dataTotal = row.metricTotal.reduce((acc, {category, total}) => {
            acc[category] = total;
            return acc;
        }, {});
        newItems.push({...row, ...newItem, ...dataTotal});
    }

    return newItems;
}


//COLORS SLIDERS SCORES BEHAVIOR
export const COLOR_BEHAVIOR_GREEN = 'color_behavior_green'
export const COLOR_BEHAVIOR_GREEN_LIGHT = 'color_behavior_green_light'
export const COLOR_BEHAVIOR_ORANGE_LIGHT = 'color_behavior_orange_light'
export const COLOR_BEHAVIOR_ORANGE = 'color_behavior_orange'
export const COLOR_BEHAVIOR_RED = 'color_behavior_red'
export const COLORS_SLIDERS_SCORE = [
    colors[COLOR_BEHAVIOR_GREEN],
    colors[COLOR_BEHAVIOR_GREEN_LIGHT],
    colors[COLOR_BEHAVIOR_ORANGE_LIGHT],
    colors[COLOR_BEHAVIOR_ORANGE],
    colors[COLOR_BEHAVIOR_RED]
];

export const getGradeColor = (value, grades) => {
    let color = COLORS_SLIDERS_SCORE[0];
    if (value <= grades[0]) {
        color = COLORS_SLIDERS_SCORE[0];
    } else if (value >= grades[0] && value <= grades[1]) {
        color = COLORS_SLIDERS_SCORE[2];
    } else if (value > grades[1]) {
        color = COLORS_SLIDERS_SCORE[4];
    }
    return color;
}

export const formatEntities = (selectedItems) => {
    return selectedItems.map((item) => {
        const {id, name, label} = item;

        return {id, name, label};
    });
};
