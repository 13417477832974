export default {
    settings_crew: "Tripulación",
    settings_unit_settings: "Configuración de la Unidad",
    settings_driver_groups: "Grupos de Conductores",
    settings_unit_groups: "Grupos de Unidades",
    settings_input_labels: "Etiquetas de Entradas",
    settings_vehicle_status: "Estado del Vehículo",
    settings_vehicle_equipment: "Equipamientos",
    settings_drivers: "Conductores",
    settings_vehicle_key_fobs: "Llaveros",
    settings_vehicle_temperature: "Temperatura",
    settings_fuel_card: "Tarjeta de Combustible",
    settings_drivers_status: "Estados de Conductor",
    settings_behavior: "Comportamiento",
};
