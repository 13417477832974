import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {clientMutation} from '../../../../../../../core/data/GraphQLApi';
import {endpoints} from '../../../../../../../core/defaultValues';
import {ExceptionManager} from '../../../../../../../core/logManager';
import {resetALL} from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    error: null,
    deleteMetricResult: null,
    metrics: []
};

const route = 'modules/settings/views/behavior/modals/delete/redux/slice.js'

export const deleteMetricService = createAsyncThunk('manage/behavior/deleteMetricService', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    let mutations = [];
    try {
        const {id, metrics} = payload;
        if (id) {
            let query = `
            mutation deletePointMetric ($id: Int!) {
                deletePointMetric (id: $id)
            }`;
            return await clientMutation(
                query,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL_V2
            );
        }
        if (metrics.length) {
            metrics.forEach((metric, index) => {
                mutations.push(`
                deletePointMetric${index}: deletePointMetric(
                        id: ${metric.id},
                    )
              `);
            })
            let mutation = `mutation { ${mutations.join(' ')} }`;
            return await clientMutation(
                mutation,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL_V2,
            );
        }
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteMetricService');
        return rejectWithValue(exc)
    }
});


const manageDeleteMetricServiceRedux = createSlice({
    name: 'manage/behavior',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? {...state, ...action.payload} : {...INIT_STATE}
        },
        openModalDeleteMetricService(state, action) {
            state.open = true;
            state.metrics = action?.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(deleteMetricService.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteMetricService.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteMetricResult = action.payload;
        });
        builder.addCase(deleteMetricService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = manageDeleteMetricServiceRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalDeleteMetricService,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
