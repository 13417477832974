export default {
	alertsTabView_Select: "Seleccionar",
	alertsTabView_FilterBy: "Filtrar por",
	alertsTabView_FilterLabel: "Filtrar",
	alertsTabView_Unit: "Unidad",
	alertsTabView_Driver: "Conductor",
	alertsTabView_label: "Nombre",
	alertsTabView_addButtonLabel: "Nueva Alerta",
	alertsTabView_action: "Acción",
	alertsTabView_subscribers: "Suscriptores",
	alertsTabView_summary: "Resumen",
	alertsTabView_severity: "Prioridad",
	alertsTabView_deleteAlertTitle: "Eliminar Alertas",
	alertsTabView_deleteAlertContent: "Desea eliminar las alertas?",
	alertsTabView_deleteAlertDelete: "Eliminar",
	alertsTabView_deleteAlertCancel: "Cancel",
	alertsTabView_deleteAlertSubscriberTitle: "Advertencia!",
	alertsTabView_deleteAlertSubscriberContent: "¿Esta seguro que desea remover el subscriptor de la alerta?",
	alertsTabView_deleteAlertSubscriberDelete: "Eliminar",
	alertsTabView_deleteAlertSubscriberCancel: "Cancelar",
	alertsSideMenu_buttonEdit: "Editar",
	alertsSideMenu_buttonCancel: "Cancelar",
	alertsSideMenu_subscribersTab: "Suscriptores",
	alertsTabView_Edit_Title: "Editar",
	alertsFiltersMenu_menuTitle: "Filtros avanzados",
	alertsFiltersMenu_typeAlertFilter: "Tipo de alerta",
	alertsFiltersMenu_filterAll: "Todos",
	alertsFiltersMenu_clearFilters: "Limpiar filtros",
	alertsSideMenu_buttonClose: "Cerrar",
	alertsTabView_stoppedReporting: "Dejó de informar",
	alertsTabView_vehicleCongregation: "Congregación de vehículos",
	alertsTabView_driverBehavior: "Comportamiento del conductor",
	alertsTabView_consistentSpeed: "Velocidad consistente",
	alertsTabView_lightDutyDTC: "DTC servicio liviano",
	alertsTabView_offHour: "fuera de hora",
	alertsTabView_heavyDutyDTC: "DTC servicio pesado",
	alertsTabView_fuelCard: "Tarjeta de combustible",
	alertsTabView_onEvent: "en evento",
	alertsTabView_idle: "Ralentí",
	alertsTabView_speed: "Velocidad",
	alertsTabView_unassignedDriver: "Conductor no asignado",
	alertsTabView_smartOneCLowBattery: "Batería baja de SmartOneC",
	alertsTabView_OBDII: "Trabajo liviano (OBDII)",
	alertsTabView_totalDriveTime: "Tiempo total de conducción",
	alertsTabView_ConsecutiveDriving: "Conducción consecutiva",
	alertsTabView_successSubscriberDeletion: "El suscriptor ha sido eliminado",
	alertsTabView_failedSubscriberDeletion: "Ha ocurrido un error eliminando el suscriptor",
	alertsTabView_successAlertDeletion: "Las alertas han sido eliminadas con exito",
	alertsTabView_failedAlertDeletion: "Ha ocurrido un error eliminando la(s) alerta(s)",
	alertsTabView_cameraEvent: "Evento de la cámara",
    alertsTabView_lowBattery: "Batería baja",
    alertsTabView_installSensor: "Instalar sensor",
    alertsTabView_userUpdate: "Actualización del usuario",
	alertsTabView_tamperSensor: "Sensor de manipulación",
    alertsTabView_lightSensor: "Sensor de luz",
	alertsTabView_people : "persona",
	alertsTabView_search : "Buscar",
	alertsTabView_assigned: "Asignado",
	alertsTabView_assignedUnits: "Unidades Asignadas",
	alertsTabView_assignedDrivers: "Conductores Asignadas",
	alertsTabView_driver: "conductor",
    alertsTabView_drivers: "conductores",
	alertsTabView_unit: "unidad",
    alertsTabView_units: "unidades",
	alertsTabView_tags: "etiquetas",
    alertsTabView_tag: "etiqueta",
	alertsTabView_noSubscribersFound: "No hay subscriptores",
	alertsTabView_subscriber: "subscriptores",
	alertsTabView_allUnitsSelected: "Todas las unidades",
	alertsTabView_all: "Todos",
	alertsTabView_allDriversSelected: "Todos los conductores",
	alertsTabView_allContacts: "Todos los contactos",
    alertsTabView_currentDriver: "Conductor actual",
	alertsTabView_contacts: "contactos",
    alertsTabView_contact: "contacto",
	alertsTabView_noname: "Sin nombre",
	alertsTabView_fsmMealBreak: "Descanso de almuerzo",
	alertsTabView_route_watchers: "Observadores de Ruta"
};
