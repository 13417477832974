import { validateJsonObject } from "../../../../data/Helpers";

export class REFRESH {
  execute = (payload, state) => {
    const data = payload?.data;
    switch (data?.key) {
      case "CLIP_REQUEST":
        if (data?.status === "uploading" || data?.status === "finished") return
          return [true, { ...state, refreshClipRequest: data }];
      case "REFRESH_USER_PROFILE":
        return [true, { ...state, getUserInfoRefresh: true, isNavigate: false }]; 
      case 'INCIDENT':
        const currentIncidentsList = state?.notificationAlertList
        currentIncidentsList?.push(data?.incident)
        return [true, { ...state, notificationAlert: state?.notificationAlert + 1, notificationAlertList: currentIncidentsList}]; 
      case 'INCIDENT_END':
          const getData = data?.incident
          if(!getData) return
          const parseData = JSON.parse(getData) ? JSON.parse(getData) : []
          return [true, {...state, endIncidentNotification: parseData}]; 
      case 'REFRESH_LIVE_REQUEST':
          const getClip = data?.clip
          if(!getClip) return
          const parseClip = JSON.parse(getClip) ? JSON.parse(getClip) : []
          return [true, {...state, liveClipUpdated: parseClip}]; 
      case 'VIDEO_DETAIL':
          const rowIdUpdated = data?.rowId
          const jsonValue = data?.jsonValue
          let notes = ''
          if(jsonValue){
            notes = validateJsonObject(jsonValue)
            notes = notes?.description
          }
          return [true, {...state, videoDetailUpdated: rowIdUpdated, videoDetailUpdatedNotes: notes}]; 
      }
  };
}

