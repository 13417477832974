//imports
import React from 'react';
import { useDispatch , useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';

import './main.scss'

import {
  reportsOpenModalDownloadFiles , clearRedux
} from 'Redux/actions';

import {
  Button , Modal ,
} from '@gpstrackit/gtc-react-controls';

import { Accordion , AccordionSummary , AccordionDetails , Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ModalDownloadFiles = ( { error , submitExtraTimeReport } ) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  
  const open = useSelector( state => state.reportsModalDownloadFiles.open );
  const loading = useSelector( state => state.reportsModalDownloadFiles.loading );
  const urlsDownload = useSelector( state => state.catalogsRedux.urlsDownload )
  
  const handleClose = () => {
    dispatch( reportsOpenModalDownloadFiles( false ) );
    dispatch( clearRedux( "CATALOG_REDUX" , { urlsDownload : [] } ) )
  }
  
  const downloadAll = () => {
    urlsDownload?.forEach( ( url , index ) => {
      setTimeout( () => {
        const a = document.createElement( 'a' );
        a.href = url.url;
        a.download = url.name;
        document.body.appendChild( a );
        a.click();
        document.body.removeChild( a );
      } , index * 1000 );
    } );
  }
  
  return ( <Modal
    title={ messages['generatedReport_download'] }
    open={ open }
    position={ 'center' }
    style={ {
      height : '20rem' ,
    } }
    handleClose={ handleClose }
    size="medium"
    footer={ ( <div style={ {
      width : '100%' ,
      
      display : 'flex' , justifyContent : 'flex-end' , gap : '1rem' , alignItems : 'center' ,
    } }>
      <Button
        isDisabled={ loading }
        onClick={ handleClose }
        variant='secondary'
      >
        { messages['cancel'] }
      </Button>
      <Button onClick={ () => {
        handleClose();
        downloadAll();
      } } variant={ 'primary' }>
        { messages['reportsMainRT_downloadAll'] }
      </Button>
    </div> ) }
  >
    <div
      style={ {
        padding : '1rem 2rem' ,
      } }
    >
      <div>
        <Typography>{ messages['reportsMainRT_downloadAllMessage'] }</Typography>
      </div>
      <div style={ {
        padding : '1rem 0' ,
      } }>
        <InputLabel style={ { marginBottom : '10px' } }>
          { messages['reportMainRT_NumberOfDownload'] } : { urlsDownload.length }
        </InputLabel>
        <Accordion>
          <AccordionSummary
            expandIcon={ <ExpandMoreIcon/> }
            aria-controls={ `panel${ 1 }-content` }
            id={ `panel${ 1 }-header` }
          >
            <Typography>{ messages['reportsMainRT_downloadList'] }</Typography>
          </AccordionSummary>
          { urlsDownload?.length > 0 && urlsDownload.map( ( url , index ) => ( <AccordionDetails key={ index }>
            <div key={ index } className="download-item">
              <div>
                <p>
                  { url.name.replaceAll( "_" , " " ) }
                </p>
              </div>
              <Button onClick={ () => {
                const a = document.createElement( 'a' );
                a.href = url.url;
                a.download = url.name;
                document.body.appendChild( a );
                a.click();
                document.body.removeChild( a );
              } } className="btn-download">
                { messages['generatedReport_download'] }
              </Button>
            </div>
          </AccordionDetails> ) ) }
        </Accordion>
      </div>
    </div>
  </Modal> )
  
}

export default ModalDownloadFiles
