//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/behavior/views/main/lan/en';
import reportView from 'Modules/behavior/views/report/lan/en';
import reportFilterView from 'Modules/behavior/views/report/modals/filter/lan/en';
import reportExportView from 'Modules/behavior/views/report/modals/export/lan/en';

locales = {
    ...locales,
    ...mainView,
    ...reportView,
    ...reportFilterView,
    ...reportExportView,
};

export default locales;
