import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { NotificationManager } from 'Components/Notifications';
import {
    Grid
} from "@mui/material";

import {
    Loading
} from 'Components';

import Dropdown from './components/dropdown';
import Inline from './components/inline';

// Styles
import './styles.scss';
// Actions
import {
    entityTagPickerGetTags,
    clearRedux
} from 'Redux/actions';

const TagPicker = (props) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const {
        entity,
        label,
        placeholder,
        onChange,
        disabled,
        values,
        inline,
        maxHeight,
        height,
        required,
        showUntagged = true,
        showSelectAll = true,
        multiple = true,
        errorMessage,
        hideLoader = false,
        hideComponent = false,
        loading
    } = props;

    const tags = useSelector(state => state.newEntityTagPickerRedux.tags);
    const tree = useSelector(state => state.newEntityTagPickerRedux.tree);
    const loadingTags = useSelector(state => state.newEntityTagPickerRedux.loadingTags);
    const error = useSelector(state => state.newEntityTagPickerRedux.error);

    const rowsPerPage = 100;
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = React.useState(0);

    loading && loading(loadingTags)

    useEffect(() => {
      dispatch(entityTagPickerGetTags(entity));
        return () => {
            dispatch(clearRedux('NEW_ENTITY_TAG_PICKER'));
        }
    }, []);

    useEffect(() => {
        if (error) {
            NotificationManager.error(
                messages['errorMessage'],
                messages['error'],
                3000,
                null,
                null,
                'danger',
            );
        }
    }, [error]);

    const [data, total] = useMemo(() => {
        let viewData = [...tree];

        if (searchText && typeof searchText !== 'undefined' && searchText.length > 0) {
            viewData = [...tags].filter(x => {
                let result = false;
                let label = x?.label;
                if (label && label?.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                    result = true;
                }

                return result;
            });
        }

        if (!showUntagged) {
            viewData = viewData?.filter(v => v?.id != -1);
        }

        let total = viewData.length;

        return [viewData, total];
    });

    const dataPaginated = useMemo(() => {
        let valuesPaginate = data.slice(0, (page * rowsPerPage));
        return valuesPaginate;
    });

    return (
        <div>
            {
                loadingTags
                    ? <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            justifyContent: "center"
                        }}
                    >
                        {
                            !hideLoader ?
                                <Grid item>
                                    <Loading />
                                </Grid>
                                : <></>
                        }
                    </Grid>
                    : <div>
                        { !hideComponent &&
                            <>
                                {
                                    inline
                                        ? <Inline
                                            label={label}
                                            placeholder={placeholder}
                                            searchText={searchText}
                                            setSearchText={setSearchText}
                                            tags={dataPaginated}
                                            values={values}
                                            onChange={onChange}
                                            disabled={disabled}
                                            height={height}
                                            maxHeight={maxHeight}
                                            showSelectAll={showSelectAll}
                                            multiple={multiple}
                                            required={required}
                                            page={page}
                                            setPage={setPage}
                                            total={total}
                                        />
                                        : <Dropdown
                                            label={label}
                                            placeholder={placeholder}
                                            searchText={searchText}
                                            setSearchText={setSearchText}
                                            tags={dataPaginated}
                                            values={values}
                                            onChange={onChange}
                                            disabled={disabled}
                                            showSelectAll={showSelectAll}
                                            multiple={multiple}
                                            required={required}
                                            page={page}
                                            setPage={setPage}
                                            total={total}
                                        />
                                }

                                {
                                    required && errorMessage && (
                                        <label className="input-normal-label text-danger">{errorMessage}</label>
                                    )
                                }
                            </>
                        }
                    </div>
            }
        </div>
    )
};

export default TagPicker;
