import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import {
    MAPS_ETA_LINK_GET
} from 'Redux/actionTypes';

import {
    mapsEtaLinkPublicGetResponse
} from 'Redux/actions';

import axios from 'axios';

function* searchPublicEtaLink({ payload }) {
    try {
        let hasVideo = false
        const { code, first } = payload;
        const markerDetail = yield call(
            clientQuery,
            `
            query getPublicEtaLink ($code: String!) {
                etaLinkData: getPublicEtaLink (code: $code) {
                    id
                    code
                    unit {
                        label
                        driverName
                        lastReading {
                            latitude
                            longitude
                            eventCode
                            eventName
                            heading
                        }
                    }
                    unitId
                    status
                    address
                    latitude
                    longitude
                    isKph
                    scheduleSnapshot   

                    entityName
                    routePoints
                    driverName
                    detailedRoute
                    titleRoute
                }
            }`,
            { code: code },
            endpoints.GRAPHQL_GENERAL,
            {
                'x-api-key': endpoints.PUBLIC_API_KEY
            }
        );
        let unit = null;
        if (markerDetail?.etaLinkData?.unitId) {
            let unitDetail = yield call(
                clientQuery,
                `
                    query getPublicMarkerDetail($id: Int!) {
                        result: getPublicMarkerDetail(id: $id)
                    }
                `,
                {
                    id: parseInt(markerDetail?.etaLinkData?.unitId)
                },
                endpoints.GRAPHQL_GENERAL_V2,
                {
                    'x-api-key': process.env.COGNITO_API_KEY_V2
                }
            );
            if (unitDetail?.result) {
                unit = JSON.parse(unitDetail?.result)
                if (unit?.markerDetail?.linkedDevice?.hasVideo) {
                    hasVideo = true
                }
            }
        }
        let dataRoute = null;
        //only download the file if it is the first time
        if(markerDetail?.etaLinkData?.detailedRoute && first && unit?.markerDetail?.unit?.companyId){
            const responseFile = yield call(
                clientQuery,
                `query (
                  $id: Int!
                ){
                  downloadAttachmentUrl: downloadAttachmentUrl(
                    id: $id
                  )
                }`,
                {
                    id: parseInt(markerDetail?.etaLinkData?.detailedRoute)
                },
                endpoints.GRAPHQL_GENERAL_V2,
                {
                    'x-api-key': process.env.COGNITO_API_KEY_V2,
                    'app-id': 'cloud-react-public',
                    'account-id': unit?.markerDetail?.unit?.companyId
                }
            );

            //download the file data
            if(responseFile){
                const url = responseFile?.downloadAttachmentUrl;
                dataRoute = yield call(fetchDataRoute, url);
            }
        }

        /********************************************* 
         * Return types of error in eta
         * *******************************************
            OK: No error
            NO_DATA: No find information
            LOAD_FAILED: failed to send petition
        **********************************************/

        if (markerDetail && markerDetail.etaLinkData) {
            if (unit)
                yield put(mapsEtaLinkPublicGetResponse({ ...markerDetail.etaLinkData, hasVideo, unitData: unit , drawLineRoute: dataRoute || null }, "OK"));
        } else {
            yield put(mapsEtaLinkPublicGetResponse(null, "NO_DATA"));
            //throw 'Error while getting public eta link';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'Core/views/eta/redux', 'searchPublicEtaLink');
        yield put(mapsEtaLinkPublicGetResponse(null, "LOAD_FAILED"));
    }
}

async function fetchDataRoute(url) {
    try {
        const dataMap = await axios.get(url);
        if (dataMap?.data) {
            return dataMap.data;
        }
        return null;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}


function* watchMaspEtaLinkRequests() {
    yield takeEvery(MAPS_ETA_LINK_GET, searchPublicEtaLink);
}

export default function* rootSaga() {
    yield all([
        fork(watchMaspEtaLinkRequests)
    ]);
}