import {
  ALERTS_WIZARD_ALERT_OPEN,
  ALERTS_WIZARD_ALERT_OPEN_RESULT,
  ALERTS_WIZARD_ALERT_CLOSE,
  ALERTS_WIZARD_ALERT_SAVE,
  ALERTS_WIZARD_ALERT_SAVE_RESULT,
  ALERTS_WIZARD_ALERT_TYPES_SUPPORTED,
  ALERTS_WIZARD_ALERT_TYPES_SUPPORTED_RESULT,
  ALERTS_WIZARD_CLEAN_FIELDS,
  ALERTS_WIZARD_GET_ALERT,
  ALERTS_WIZARD_GET_ALERT_RESULT,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  isOpenModal: false,
  loading: false,
  cleanFields:false,
  alertLoad: null,
  response: null,
  loadingAlertTypesSupported: false,
  alertTypesSupported: []
};



export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case ALERTS_WIZARD_ALERT_OPEN:
      return { ...state, isOpenModal: true, loading: true, alertLoad: action.payload };

    case ALERTS_WIZARD_ALERT_OPEN_RESULT:
      return { ...state, loading: false };

    case ALERTS_WIZARD_ALERT_CLOSE:
      return { ...INIT_STATE };

    case ALERTS_WIZARD_ALERT_SAVE:
      return { ...state, loading: true };

    case ALERTS_WIZARD_ALERT_SAVE_RESULT:
      return { ...state, loading: false, response: action.payload };

    case ALERTS_WIZARD_ALERT_TYPES_SUPPORTED:
      return { ...state, loadingAlertTypesSupported: true };

    case ALERTS_WIZARD_ALERT_TYPES_SUPPORTED_RESULT:
      return { ...state, loadingAlertTypesSupported: false, alertTypesSupported: action.payload || [] };

    case ALERTS_WIZARD_GET_ALERT:
      return { ...state, loadingAlertTypesSupported: true };
  
    case ALERTS_WIZARD_GET_ALERT_RESULT:
      return { ...state, loadingAlertTypesSupported: false, alertTypesSupported: action.payload || [] };

    case ALERTS_WIZARD_CLEAN_FIELDS:
        return { ...state, cleanFields: action.payload };
      
    /** Clear Redux */
    case CLEAR_REDUX:
      return ["", "ALERTS_WIZARD"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};