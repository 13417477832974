class MarkerOverlay {
  constructor(position, content) {
    this.overlay_ = null;
    this.containerDiv = null;
    this.listeners = [];
    this.content = content;
    this.display = true;
    this.createOverlay(position, content);
  }

  createOverlay(position, content) {
    try{
      this.overlay_ = new google.maps.OverlayView(); // eslint-disable-line no-undef
      this.overlay_.onAdd = this.onAdd.bind(this);
      this.overlay_.draw = this.draw.bind(this);
      this.overlay_.onRemove = this.onRemove.bind(this);
      this.overlay_.onMove = this.onMove.bind(this);
      this.overlay_.onChangeLabel = this.onChangeLabel.bind(this);
      this.overlay_.onDisplayObject = this.onDisplayObject.bind(this);
  
      this.overlay_.position = position;
      content.classList.add("popup-bubble");
  
      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("popup-container");
      this.containerDiv.appendChild(content);
  
      // Optionally stop clicks, etc., from bubbling up to the map.
      //google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);// eslint-disable-line no-undef
    }catch(e){
      //console.log('error to load Marker Overlay', e)
    }
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    try{
      if(this.containerDiv){
        const panes = this.overlay_.getPanes();
        panes.overlayMouseTarget.appendChild(this.containerDiv);
        /* Add the element to the pane.
          MapPanes.mapPane (Level 0)
          MapPanes.overlayLayer (Level 1)
          MapPanes.markerLayer (Level 2)
          MapPanes.overlayMouseTarget (Level 3)
          MapPanes.floatPane (Level 4)
        */
        this.cancelEvents();
      }
    }catch(e){
      //console.log('error to load Marker Overlay', e)
    }
  }

  /** Called when the popup is removed from the map. */
   onRemove() {
    if (this.containerDiv && this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
      this.containerDiv = null;
    }

    for (let i = 0; i < this.listeners.length; i++) {
      // remove our event listeners
      google.maps.event.removeListener(this.listeners[i]); // eslint-disable-line no-undef
    }
  }

  onMove(position, isNewClass = false){

    if(this.overlay_){
      this.overlay_.position = position;
      this.draw()

      if(isNewClass){
        this.content.classList.remove("popup-bubble");
        this.content.classList.add("popup-bubble-moved");
      }else{
        this.content.classList.add("popup-bubble");
        this.content.classList.remove("popup-bubble-moved");
      }
    }
  }

    onChangeLabel(label, idsGroupLabel = null, callbackEvent = null, innerText = "View Detail..."){
        if(this.containerDiv){
            this.containerDiv.children[0].innerHTML = label;
            if(idsGroupLabel){
                let button = document.createElement("a");
                button.innerText = innerText;
                button.classList.add("buttonModalGroupsText");
                button.addEventListener("click", () => {
                    callbackEvent(idsGroupLabel)
                });
                this.containerDiv.children[0].appendChild(button);
            }
        }
    }

    onDisplayObject(valueDisplay, map){
        if(this.display == valueDisplay) return;

        this.display = valueDisplay;
        //this.containerDiv.style.display = valueDisplay;
        this.overlay_.setMap(valueDisplay ? map : null);
        if(valueDisplay){
            this.containerDiv = document.createElement("div");
            this.containerDiv.classList.add("popup-container");
            this.containerDiv.appendChild(this.content);
        }
        this.draw();
    }

  draw() {
    
    if(!this.display) return;

    const overlayProjection = this.overlay_.getProjection();
    if(!overlayProjection) return
      const divPosition = overlayProjection?.fromLatLngToDivPixel(new google.maps.LatLng(this.overlay_.position.lat(), this.overlay_.position.lng())); // eslint-disable-line no-undef
    
    const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? "block"
          : "none";

    if (display === "block" && this.containerDiv) {
      this.containerDiv.style.left = (divPosition.x) + "px";
      this.containerDiv.style.top = (divPosition.y) + "px";
    }

    if (this.containerDiv && this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }

  setMap(map) {
    if(this.overlay_){
      this.overlay_.setMap(map);
    }
  }

  cancelEvents() {
    const events = ['mousemove', 'mouseover',
      'mouseout', 'mouseup',
      'touchstart', 'touchend',
      'touchmove', 'dblclick', 'contextmenu'];
    // Note, don't disable 'click' if you want to be able to click links in the dom. Some things we're disabling here will can effect how your user might interact with the popup (double click to select text etc)
    for (let i = 0; i < events.length; i++) {
      let event = events[i];
      this.listeners.push(
        google.maps.event.addListener( // eslint-disable-line no-undef
          this.containerDiv,
          event,
          function (e) {
            e.cancelBubble = true;
            if (e.stopPropagation) {
              e.stopPropagation();
            }
          }
        )
      );
    }
  }
}

export default MarkerOverlay