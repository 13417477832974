import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useIntl } from 'react-intl';
import _ from 'lodash';

//Componnents
import { Timezone } from '../../../../../components/uiControls';
import { Grid } from '@mui/material';
import { Button } from 'Components'

//Helpers
import { copyToClipboard, getFormattedDateByTimeZone, GetFormatForMoment } from 'Core/data/Helpers';

//Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'

//Actions
import { getCameraOnlineStatus } from 'Redux/actions'

const getStatus = (state) => state.vidFleetVideoRedux.cameraStatusResponse
const getStatusLoading = (state) => state.vidFleetVideoRedux.loadingCameraStatus

const cameraOnlineStatusServer = (id) => {
    return createSelector(
        [getStatus, getStatusLoading],
        (status, loading) => {
            return [loading[id], status[id]]
        }
    )
}
const DeviceInfo = (props) => {
    const dispatch = useDispatch();
    const { data } = props;
    const { messages, locale } = useIntl();

    const getHDopLabel = (hdopRaw) => {
        if (!isNaN(parseFloat(hdopRaw)) && isFinite(hdopRaw)) {
            let hdop = hdopRaw > 0 ? hdopRaw / 100 : 0;
            if (hdop < 1) {
                return { label: messages['infoBubble_hdop_ideal'], value: hdop };
            } else if (hdop >= 1 && hdop < 2) {
                return { label: messages['infoBubble_hdop_excellent'], value: hdop };
            } else if (hdop >= 2 && hdop < 5) {
                return { label: messages['infoBubble_hdop_good'], value: hdop };
            } else if (hdop >= 5 && hdop < 10) {
                return { label: messages['infoBubble_hdop_moderate'], value: hdop };
            } else if (hdop >= 10 && hdop < 20) {
                return { label: messages['infoBubble_hdop_fair'], value: hdop };
            } else if (hdop >= 20) {
                return { label: messages['infoBubble_hdop_poor'], value: hdop };
            }
        }
        return null
    }

    //Redux States
    const [loading, cameraStatus] = useSelector(cameraOnlineStatusServer(data?.markerDetail?.linkedDevice?.esn), (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps)
    });
    const user = useSelector(state => state.securityRedux.user);
    const lang = useSelector(state => state.commomRedux.language);

    //Local States
    const [currentCameraStatus, setCurrentCameraStatus] = useState(null)

    const formatDateUser = () => {
        const momentFormat = GetFormatForMoment(user.dateformat);
        return `h:mm:ss A, ${momentFormat}`;
    }

    useEffect(() => {
        if (data?.markerDetail?.linkedDevice?.manufacturerName === "VidFleet" && !currentCameraStatus) {
            dispatch(getCameraOnlineStatus({ esn: data?.markerDetail?.linkedDevice?.esn, unitId: data?.markerDetail?.linkedDevice?.id }));
        }
    }, [])

    useEffect(() => {
        if (cameraStatus && !currentCameraStatus) {
            setCurrentCameraStatus(cameraStatus);
        }
    }, [cameraStatus])

    const signal = getHDopLabel(data?.markerDetail?.unit?.lastReading?.hDop)

    return (
        <div className='info-window-tab'>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                style={{
                    padding: '16px 45px 16px 16px'
                }}
            >
                <Grid item xs>
                    <h2 style={{ margin: 0 }}>
                        {messages['infoBubble_deviceInfo']}
                    </h2>
                </Grid>
            </Grid>

            <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

            <div>
                <table className="info-bubble-table">
                    <tbody>
                        <tr>
                            <td>
                                ESN
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {data?.markerDetail?.unit?.esn || "N/A"}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(data?.markerDetail?.unit?.esn || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>
                        {
                            data?.markerDetail?.linkedDevice && data?.id !== data?.markerDetail?.linkedDevice?.id &&
                            <tr>
                                <td>
                                    {messages['infoBubble_pairedCamera']}
                                </td>
                                <td>
                                    <div className="hover-container">
                                        <span style={{ paddingRight: 8 }}>
                                            {data?.id !== data?.markerDetail?.linkedDevice?.id ? data?.markerDetail?.linkedDevice?.label || "N/A" : "N/A"}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="content_copy"
                                            onClick={() => copyToClipboard(data?.id !== data?.markerDetail?.linkedDevice?.id ? data?.markerDetail?.linkedDevice?.label || "N/A" : "N/A")}
                                            fontSize="inherit"
                                            className="btn-no-padding hover-item"
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td style={{ width: 160 }}>
                                {messages['infoBubble_lastMessageTime']}
                            </td>
                            <td>
                                <div className="hover-container" style={{ display: 'flex' }}>
                                    <span style={{ paddingRight: 8 }}>
                                        {
                                            data?.markerDetail?.unit?.lastReading?.LastMessageTime
                                                ?
                                                <Timezone
                                                    onlyHour
                                                    date={data?.markerDetail?.unit?.lastReading?.LastMessageTime}
                                                    dateFormat={""}
                                                    timeFormat={"h:mm:ss A, MM/DD/YYYY"}
                                                    timezone={data?.markerDetail?.unit?.timeZone?.name}
                                                    className="infoBubbleTimeZoneClassName"
                                                    fromNow={true}
                                                    lang={locale}
                                                />
                                                : "N/A"
                                        }
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(getFormattedDateByTimeZone(data?.markerDetail?.unit?.lastReading?.LastMessageTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, lang))}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>
                        {

                            data?.id !== data?.markerDetail?.linkedDevice?.id &&
                            <tr>
                                <td>
                                    {messages['infoBubble_signalStrength']}
                                </td>
                                <td>
                                    <div className="hover-container">
                                        <span style={{ paddingRight: 8 }}>
                                            {signal?.label ? `${signal?.label} (${signal.value})` : "N/A"}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="content_copy"
                                            onClick={() => copyToClipboard(signal?.label ? `${signal?.label} (${signal.value})` : "N/A")}
                                            fontSize="inherit"
                                            className="btn-no-padding hover-item"
                                        />
                                    </div>
                                </td>
                            </tr>
                        }

                        {
                            data?.markerDetail?.linkedDevice &&
                            <tr>
                                <td>
                                    {messages['infoBubble_cameraSignalStrength']}
                                </td>
                                <td>
                                    <div className="hover-container">
                                        <span style={{ paddingRight: 8 }}>
                                            {currentCameraStatus?.signal ? `${messages[currentCameraStatus?.signal] || currentCameraStatus?.signal} ${currentCameraStatus?.RSRP ? `(${Math.round(currentCameraStatus?.RSRP)})` : ""}` : 'N/A'}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="content_copy"
                                            onClick={() => copyToClipboard(currentCameraStatus?.signal ? `${messages[currentCameraStatus?.signal] || currentCameraStatus?.signal} ${currentCameraStatus?.RSRP ? `(${Math.round(currentCameraStatus?.RSRP)})` : ""}` : 'N/A')}
                                            fontSize="inherit"
                                            className="btn-no-padding hover-item"
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            data?.id !== data?.markerDetail?.linkedDevice?.id &&
                            <tr>
                                <td>
                                    {messages['infoBubble_GPSDeviceType']}
                                </td>
                                <td>
                                    <div className="hover-container">
                                        <span style={{ paddingRight: 8 }}>
                                            {data?.id !== data?.markerDetail?.linkedDevice?.id ? data?.markerDetail?.unit?.unitVersionProduct || "N/A" : "N/A"}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="content_copy"
                                            onClick={() => copyToClipboard(data?.markerDetail?.unit?.unitVersionProduct || "N/A")}
                                            fontSize="inherit"
                                            className="btn-no-padding hover-item"
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            data?.markerDetail?.linkedDevice &&
                            <tr>
                                <td>
                                    {messages['infoBubble_cameraDeviceType']}
                                </td>
                                <td>
                                    <div className="hover-container">
                                        <span style={{ paddingRight: 8 }}>
                                            {messages[`settingsCameraManage_${data?.markerDetail?.linkedDevice?.deviceModelName}`] || data?.markerDetail?.linkedDevice?.deviceModelName || 'N/A'}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="content_copy"
                                            onClick={() => copyToClipboard(messages[`settingsCameraManage_${data?.markerDetail?.linkedDevice?.deviceModelName}`] || data?.markerDetail?.linkedDevice?.deviceModelName || 'N/A')}
                                            fontSize="inherit"
                                            className="btn-no-padding hover-item"
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DeviceInfo;