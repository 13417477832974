import { call, put } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import { reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate } from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance,
    getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

import {
    getAbbvTimezone
} from "./../../../../../../../../../components/uiControls/Timezone/tools";

function* getStopSummaryReportV2(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
    const { filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery
            },
            limit: rowsPerPage,
            offset: page
        };
        let addColumnDriver = [];
        let addColumnDriverUnit = {
            column: "DriverUnit",
            header: getCategoryFilter(filterType) === "driverId" ? "Driver" : "Unit"
        }
        if(report?.exportType === 'csv') {
          addColumnDriver.push(addColumnDriverUnit);
        }

        let columns = [
          ...addColumnDriver,
            {
                column: "dateGroup",
                header: `Date`
            },
            {
                column: "dateTime",
                header: `Time ${report?.exportType === 'csv' ? `(${getAbbvTimezone(timeZone)})`: ''}`
            },
            {
                column: "addressExport",
                header: "Address"
            },
            {
                column: "name",
                header: "Title"
            },
            {
                column: "status",
                header: "Status"
            },
            {
                column: "tripDuration",
                header: "Trip Duration"
            }
        ]
      
        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "SummaryStopReportV2",
                    columns
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getStopSummaryReportV2 ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getStopSummaryReportV2 (conditions: $conditions, limit: $limit, offset: $offset) {
                    ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            typePoint
                            stopId
                            createdBy
                            status
                            dateTime
                            address
                            name
                            driverName
                            tripDuration
                            totalTrip
                            landmarkName
                            landmarkId
                            latitude
                            longitude
                    }
                    }
                    total
                    limit
                    offset
                    category
                    `
            }
            }
            
            }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/summaryStopReportV2', 'getStopSummaryReportV2');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}


export default getStopSummaryReportV2;
