//View Keys
export default {
	locationsView_locations: 'Locations',
    locationsView_units_in_location: 'Units in Location',
    locationsView_vehicleLocation: 'Vehicle Location',
    locationsView_units: 'Units',
    locationsView_delete: 'Delete',
    locationsView_cancel: 'Cancel',
    locationsView_save: 'Save',
    locationsView_addLocation: 'Add',
    locationsView_createNewLocation: 'Create New Location',
    locationsView_editLocation: 'Edit Location',
    locationsView_successfullyCreated: 'The Location was successfully created',
    locationsView_successfullyEdited: 'The Location was successfully edited',
    locationsView_messagesDelete: 'Are you sure you want to delete the Locations',
    locationsView_locationName: 'Location Name',
    locationsView_deleteLocation: 'Delete Location',
    locationsView_move: 'Move To',
    locationsView_deleteLabel: 'Delete (All Locations of the units are deleted)',
    locationsView_deleteMessage: 'What do you want to do with the Locations assigned to the units',
    locationsView_deleteSuccess: 'Locations deleted successfully',
    locationsView_availableActions: 'Available Actions',
    locationsView_searchByLocation: 'Search by Vehicle Location',
    locationsView_activated: 'Activated',
    locationsView_deactivated: 'Deactivated',
     locationsView_ELD_150_miles_rule: 'Enable ELD 150 miles Rule',
    locationsView_title: 'Title',
    locationsView_address: 'Address',
    locationsView_tags: 'Tags',
    locationsView_ELD150MilesRule: 'ELD 150 Miles Rule',
    locationsView_timezone: 'Time zone',
    locations_validate_deletes: 'Are you sure you want delete the following Locations?',
    locations_validate_delete: 'Are you sure you want to delete the following Location?',
    locations_transfer: 'Move the partner entities to another Location',
    locations_delete_location: 'Delete Location',
    locations_delete_locations: 'Delete Locations',
    locations_loading_units: 'Units are loading',
    locations_to_receive: 'Clear Location for Units',
    locations_transfer_units: 'Assigned units have been found in the Location(s) being removed. Please select a new Location for these units.',
    locations_required_address_specify: 'The Address should be more specific',
};
