import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';

// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

// Helpers
import {
    baseReportConditions,
    generateReportInstance,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {ExceptionManager} from "Core/logManager";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

const driverID = (filterEntity, negative) => {
  const obj = {
    "column" : "driverExternalId" ,
    "header" : "DriverId"
  }
  if ( filterEntity === 'Unit' ) {
    return negative ? [obj] : []
  } else {
    return negative ? [] : [obj]
  }
}

function* getSpeedCoachingReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
   

    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {
        filterType,
        filterArray,
        startRange,
        endRange,
        unitsSelected,
        filterEntity,
        otherFilters,
        accumulateData = true,
        dates
    } = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                integerRanges: [
                    {
                        fields: [
                            "speed"
                        ],
                        LTE: 300,
                        GTE: +otherFilters?.speed || 0
                    },
                    {
                        fields: [
                            "speedLimitDelta"
                        ],
                        LTE: +otherFilters?.speedLimitDelta || 0,
                        GTE: 0
                    }
                ],
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };
        const addColumnDriver = (
            report?.exportType === 'csv' ?
                [
                  {
                    column : "tagsLabels",
                    header: filterEntity === 'Unit' ? "Unit Tags" : "Driver Tags"
                  },
                  {
                    column: filterEntity === 'Unit' ? "deviceLabel" : "driverName",
                    header: filterEntity === 'Unit' ? "Unit" : "Driver"
                }
                , ...driverID(filterEntity, false)
                , {
                    "column": filterEntity === 'Unit' ? "driverName" : "deviceLabel",
                    "header": filterEntity === 'Unit' ? "Driver" : "Unit"
                }
                , ...driverID(filterEntity,true)
                ]
                : [{
                    "column": filterEntity === 'Unit' ? "driverName" : "deviceLabel",
                    "header": filterEntity === 'Unit' ? "Driver" : "Unit"
                } ,
                ...driverID(filterEntity,true)
                ])

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "speedReport",
                    columns: [
                       ...addColumnDriver,
                        {
                            column: "unitTime",
                            header: `${report?.exportType === "csv" ? `Date / Time (${getAbbvTimezone(timeZone)})` : `Date / Time`}`
                        },
                        {
                            column: "address",
                            header: "Address"
                        },
                        {
                            column: "speed",
                            header: "Speed"
                        },
                        {
                            column: "speedLimit",
                            header: "Posted Speed"
                        },
                        {
                            column: "signal",
                            header: "Signal"
                        }
                    ]
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getSpeedCoachingReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getSpeedCoachingReport (conditions: $conditions, limit: $limit, offset: $offset) {
                ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            deviceId
                            deviceLabel
                            driverExternalId
                            tags {
                                id
                                companyId
                                parentId
                                label
                                color
                                path
                                projectId
                                dealerId
                                unitgroupname
                                unitgroupcolor
                            }
                            driverId
                            driverName
                            unitId
                            unitTime
                            speed
                            speedLimit
                            signal
                            address
                            landmarkId
                            landmarkName
                            latitude
                            longitude
                            isKPH
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                `
            }
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/speedReport', 'getSpeedReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}


export default getSpeedCoachingReport;
