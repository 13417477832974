import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {clientQuery} from '../../../../../core/data/GraphQLApi';
import {endpoints} from '../../../../../core/defaultValues';
import {ExceptionManager} from '../../../../../core/logManager';

import {resetALL} from '../../../../../.globals/redux/actions';
import {
    METRIC_SPEED_LABEL,
    METRIC_SPEED_LIST,
    METRIC_SPEED_OVER_LABEL_HIGH,
    METRIC_SPEED_OVER_LABEL_LOW,
    METRIC_SPEED_OVER_LABEL_MEDIUM,
    METRIC_SPEED_OVER_LIST,
    SEARCH_BEHAVIOR_SORT
} from "Modules/settings/views/behavior/redux/helpers";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    items: [],
    total: 0,
    services: [],
    error: null
};

const route = 'modules/settings/views/behavior/redux/slice.js'

export const searchBehaviorPoints = createAsyncThunk('manage/behavior/searchBehaviorPoints', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    try {
        const sort = SEARCH_BEHAVIOR_SORT.includes(payload?.sort?.field) ? [payload.sort] : [
            {field: 'behaviorMetric_category', order: 'ASC'},
            {field: 'metric', order: 'ASC'}
        ]
        sort.push({field: 'metricValue', order: sort[0].order})
        let query = `
        query searchBehaviorPoints ($conditions: [behaviorPointSearchInput], $sort: [behaviorPointSortInput], $limit: Int, $offset: Int) {
            points: searchBehaviorPoints (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                items {
                    metric
                    metricValue
                    points
                    behaviorMetric{
                        category
                    }
                    id
                }
                total
            }
        }`;
        const response = await clientQuery(
           query,
            {
                limit: payload.limit,
                offset: payload.offset,
                conditions: payload.conditions,
                sort,
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        const data = response?.points.items.map(res => {
                let metric;
                let metricValue;
                if (METRIC_SPEED_LIST.includes(res.metric)){
                    metric = METRIC_SPEED_LABEL
                    metricValue = res.metricValue
                } else if (METRIC_SPEED_OVER_LIST.includes(res.metric)){
                    if (res.metricValue === 5){
                        metric = METRIC_SPEED_OVER_LABEL_LOW
                    } else if (res.metricValue === 10){
                        metric = METRIC_SPEED_OVER_LABEL_MEDIUM
                    } else if (res.metricValue === 15){
                        metric = METRIC_SPEED_OVER_LABEL_HIGH
                    }
                } else {
                    metric = res.metric
                }
                return {
                    metric,
                    metricValue,
                    category: res?.behaviorMetric?.category,
                    id: res.id,
                    points: res.points
                }
            })
        const filterTypes = data.filter(metric => metric?.category)
        return {items: filterTypes || [], total: response?.points.total};
    } catch (exc) {
        ExceptionManager(exc, route, 'searchBehaviorPoints');
        return rejectWithValue(exc)
    }
});

const manageSearchBehaviorPointsRedux = createSlice({
    name: 'manage/behavior',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? {...state, ...action.payload} : INIT_STATE
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(searchBehaviorPoints.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(searchBehaviorPoints.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload?.items || []
            state.total = action.payload?.total || 0
        });
        builder.addCase(searchBehaviorPoints.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const {actions, reducer} = manageSearchBehaviorPointsRedux;

// Extract and export each action creator by name
export const {resetBehavior, changeFilters} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
