import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useComputeData } from "Core/hooks";
import { getData } from "../../utils";
import { IconButton, Loading, Tooltip } from "@gpstrackit/gtc-react-controls";
import { GetFormatForMoment } from "Core/data/Helpers";
import { speedFormatMPH } from "Core/data/Helpers";
import { getMethodToFormatDuration } from "Modules/reports/views/main/components/reportsTab/helper";
import { getEventColor } from "Components/Map/utils";
import { getUnit } from "../../../../../../.globals/utils";

const DEFAULT_TIMEZONE = 'US/Arizona'

export const DeviceInfo = ({ readingSelected, numberSelected, viewTrails }) => {
  const { messages, locale } = useIntl();

  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, durationFormat, isKph } = user;

  const { isOpenOverViewUnit } = useSelector((state) => state.incidentsRedux);
  const [id, setId] = useState(null);
  const [hasVideo, setHasVideo] = useState(false);

  const [loading, setLoading] = useState(true);
  const [readingInformation, setReadingInformation] = useState({
    lastEvent: "...",
    currentTime: "...",
    unitTime: "...",
    address: "...",
    eventName: "...",
    speed: "...",
    latLng: "...",
  });

  const [data, changeIdsCallback] = useComputeData(
    getData,
    "id",
    ["label", "lat", "lng", "eventName", "changeLatLng", "ecuSpeed"],
    true,
    (itemsChanged) => {}
  );

  useEffect(() => {
    if (isOpenOverViewUnit?.data?.unit) {
      setId(isOpenOverViewUnit?.data?.unit?.id);
      setHasVideo(isOpenOverViewUnit?.data?.unit?.hasVideo);
    }
  }, [isOpenOverViewUnit]);

  useEffect(() => {
    const unit = getUnit(id, data);
    if (unit) {
      if (viewTrails && readingSelected?.id) {
        const newSpeed = unit?.useEcuSpeed
          ? readingSelected?.ecuSpeed
            ? readingSelected?.ecuSpeed
            : readingSelected?.speed
          : readingSelected?.speed;
        const speedFormat = speedFormatMPH(newSpeed, unit?.type, isKph);

        const unixTime = Number(readingSelected?.unitTime);
        const duration = moment().diff(moment(unixTime), "s");
        const durationFormatTime = getMethodToFormatDuration(durationFormat);
        const durationResult = duration
          ? durationFormatTime(duration, messages)
          : "0 min, 0 sec";

        let durationLabel = `${moment(readingSelected?.unitTime)
          .tz(unit?.timezone)
          .format("hh:mm a")} (${durationResult} ago),`;
        if (locale === "es") {
          durationLabel = `${moment(readingSelected?.unitTime)
            .tz(unit?.timezone)
            .format("hh:mm a")} (hace ${durationResult} ),`;
        }

        let address = readingSelected?.formattedAddress
          ? readingSelected?.formattedAddress
          : "...";
        if (address?.length > 45) {
          address = (
            <Tooltip title={address}>{address.slice(0, 45) + "..."}</Tooltip>
          );
        }

        let abbTimezone = moment.tz(unit?.timezone).zoneAbbr();
        if (abbTimezone === "-05") {
          abbTimezone = "COT";
        }

        setReadingInformation({
          ...readingInformation,
          eventName: readingSelected?.eventName
            ? readingSelected?.eventName
            : "...",
          latLng: `${
            readingSelected?.latitude ? readingSelected?.latitude : "..."
          } , ${
            readingSelected?.longitude ? readingSelected?.longitude : "..."
          }`,
          address: address,
          lastEvent: (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight: "5px" }}>
                <p style={{ margin: 0, marginRight: "5px" }}>
                  {moment(readingSelected?.unitTime)
                    .tz(unit?.timezone)
                    .format("hh:mm")}
                </p>
                <Tooltip title={unit?.timezone}>{abbTimezone}</Tooltip>
              </div>
              <p style={{ margin: 0 }}>
                {moment(readingSelected?.unitTime)
                  .tz(unit?.timezone)
                  .format(GetFormatForMoment(dateformat))}
              </p>
            </div>
          ),
          speed: speedFormat,
          unitTime: (
            <div style={{ display: "flex" }}>
              <p style={{ margin: 0, marginRight: "5px" }}>{durationLabel}</p>
              <Tooltip title={unit?.timezone}>{abbTimezone}</Tooltip>
            </div>
          ),
        });
      } else {
        const newSpeed = unit?.useEcuSpeed
          ? unit?.ecuSpeed
            ? unit?.ecuSpeed
            : unit?.speed
          : unit?.speed;
        const speedFormat = speedFormatMPH(newSpeed, unit?.unitType, isKph);

        const unixTime = Number(unit?.unitTime);
        const duration = moment().diff(moment(unixTime), "s");
        const durationFormatTime = getMethodToFormatDuration(durationFormat);
        const durationResult = duration
          ? durationFormatTime(duration, messages)
          : "0 min, 0 sec";

        let durationLabel = `${moment(unit?.unitTime)
          .tz(unit?.timezone ?? DEFAULT_TIMEZONE)
          .format("hh:mm a")} (${durationResult} ago),`;
        if (locale === "es") {
          durationLabel = `${moment(unit?.unitTime)
            .tz(unit?.timezone)
            .format("hh:mm a")} (hace ${durationResult} ),`;
        }

        let address = unit?.address?.formatted
          ? unit?.address?.formatted
          : "...";
        if (address?.length > 40) {
          address = (
            <Tooltip title={address}>{address.slice(0, 40) + "..."}</Tooltip>
          );
        }

        let abbTimezone = moment.tz(unit?.timezone).zoneAbbr();
        if (abbTimezone === "-05") {
          abbTimezone = "COT";
        }

        setReadingInformation({
          ...readingInformation,
          eventName: unit?.eventName ? unit?.eventName : "...",
          latLng: `${unit?.lat ? unit?.lat : "..."} , ${
            unit?.lng ? unit?.lng : "..."
          }`,
          address: address,
          lastEvent: (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight: "5px" }}>
                <p style={{ margin: 0, marginRight: "5px" }}>
                  {moment(unit?.unitTime).tz(unit?.timezone ?? DEFAULT_TIMEZONE).format("hh:mm")}
                </p>
                <Tooltip title={unit?.timezone}>{abbTimezone}</Tooltip>
              </div>
              <p style={{ margin: 0 }}>
                {moment(unit?.unitTime)
                  .tz(unit?.timezone ?? DEFAULT_TIMEZONE)
                  .format(GetFormatForMoment(dateformat))}
              </p>
            </div>
          ),
          speed: speedFormat,
          currentTime: moment().format(
            `hh:mm a ${GetFormatForMoment(dateformat)}`
          ),
          unitTime: (
            <div style={{ display: "flex" }}>
              <p style={{ margin: 0, marginRight: "5px" }}>{durationLabel}</p>
              <Tooltip title={unit?.timezone}>{abbTimezone}</Tooltip>
            </div>
          ),
        });
      }

      setLoading(false);
    }
  }, [data, readingSelected?.id, viewTrails]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Error al copiar el texto: ", err);
      });
  };

  const copyField = (field = "") => {
    const unit = getUnit(id, data);
    let text = "";
    switch (field) {
      case "lastEvent":
        text = `${moment(unit?.unitTime).format("hh:mm")} ${
          unit?.timezone
        } ${moment(unit?.unitTime).format(GetFormatForMoment(dateformat))}`;
        break;
      case "currentTime":
        text = `${moment().format(
          `hh:mm a ${GetFormatForMoment(dateformat)}`
        )}`;
        break;
      case "unitTime":
        const unixTime = Number(unit?.unitTime);
        const duration = moment().diff(moment(unixTime), "s");
        const durationFormatTime = getMethodToFormatDuration(durationFormat);
        const durationResult = duration
          ? durationFormatTime(duration, messages)
          : "0 min, 0 sec";

        let durationLabel = `${moment(unit?.unitTime).format(
          "hh:mm a"
        )} (${durationResult} ago), ${unit?.timezone}`;
        text = durationLabel;
        break;
      case "address":
        text = unit?.address?.formatted;
        break;
      case "eventName":
        text = unit?.eventName;
        break;
      case "speed":
        const newSpeed = unit?.useEcuSpeed
          ? unit?.ecuSpeed
            ? unit?.ecuSpeed
            : unit?.speed
          : unit?.speed;
        const speedFormat = speedFormatMPH(newSpeed, unit?.unitType, isKph);
        text = speedFormat;
        break;
      case "latLng":
        text = `${unit?.lat} / ${unit?.lng}`;
        break;
      default:
        text = "";
    }

    return copyToClipboard(text);
  };

  const renderIcon = (copyTo = "") => {
    return (
      <IconButton
        onClick={() => copyField(copyTo)}
        style={{ marginTop: "-5px" }}
        className="icon"
        icon="content_copy"
        size="xsmall"
      />
    );
  };

  if (!id) return <></>;

  return (
    <div
      className="incident_deviceInfo"
      // style={{width: hasVideo ? '40%' : '50%'}}
      style={{ width: "100%", height: "215px" }}
    >
      <p className="incident_deviceInfo_title">
        {
          viewTrails ?
            numberSelected && `${numberSelected} - ${readingInformation?.eventName}`
            :
          messages["incident_event_deviceInfo"]
        }
      </p>

      {loading ? (
        <div className="incident_deviceInfo_loading">
          <Loading />
        </div>
      ) : (
        <div className="incident_deviceInfo_container">
          <div
            className="incident_deviceInfo_lastEvent"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_lastEvent"]}
            </p>
            {readingInformation?.lastEvent}
            {renderIcon("lastEvent")}
          </div>
          <div
            className="incident_deviceInfo_currentTime"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_currentTime"]}
            </p>
            <p>{readingInformation?.currentTime}</p>
            {renderIcon("currentTime")}
          </div>
          <div
            className="incident_deviceInfo_unitTime"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_unitTime"]}
            </p>
            <p>{readingInformation?.unitTime}</p>
            {renderIcon("unitTime")}
          </div>
          <div
            className="incident_deviceInfo_address"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_address"]}
            </p>
            <p>{readingInformation?.address}</p>
            {renderIcon("address")}
          </div>
          <div
            className="incident_deviceInfo_eventName"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_event"]}
            </p>
            <p
              style={{
                color:
                  readingInformation?.eventName !== "..."
                    ? getEventColor(readingInformation?.eventName)
                    : "black",
              }}
            >
              {readingInformation?.eventName}
            </p>
            {renderIcon("eventName")}
          </div>
          <div
            className="incident_deviceInfo_speed"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_speed"]}
            </p>
            <p>{readingInformation?.speed}</p>
            {renderIcon("speed")}
          </div>
          <div
            className="incident_deviceInfo_latLng"
            style={{ display: "flex" }}
          >
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_deviceInfo_lat_long"]}
            </p>
            <p>{readingInformation?.latLng}</p>
            {renderIcon("latLng")}
          </div>
        </div>
      )}
    </div>
  );
};
